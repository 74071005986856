import Axios from 'axios';
import * as React from 'react';
import { Menu, MenuItem, Token, Typeahead, TypeaheadBsSizes } from 'react-bootstrap-typeahead';
import { LoadableStatuses } from '../../../shared/RequestStatuses';
import User from '../../../shared/User';

interface IProps {
  selected: User[];
  onChange: (users: User[]) => void;
  multiple: boolean;
  accountId: number | string;
  placeholder?: string;
  size?: TypeaheadBsSizes;
}

interface UserPosition {
  user: User;
  position: number;
}

type Status = LoadableStatuses<User[], string>;

export const UserTypeahead = (props: IProps) => {
  const [status, setStatus] = React.useState<Status>({ state: 'loading' });

  React.useEffect(() => {
    getUsers('');

  },              []);

  const getUsers = async (query: string) => {
    setStatus({ state: 'loading' });
    const u = await Axios.get(`/api/users?account_id=${props.accountId}&keyword=${query}&include_deleted=1`);
    const users = u.data.data.map((u: any) => User.fromApi(u));
    if (props.selected.length) {
      const s = [... props.selected].map((us) => {
        const user = users.find((nu: User) => Number(nu.id) === Number(us.id));
        return user;
      });
      props.onChange(s);
    }
    setStatus({ content: users, state: 'success' });

  };

  const renderToken = (selected: User, tokenProps: any, index: number) => {

    return (
      <Token
        key={index}
        onRemove={tokenProps.onRemove}
        style={{
          width: '100%',
          padding: 10,
          backgroundColor: '#f2f8fc',
          borderRadius: 4,
        }}
      >
        <div className="flex-container">
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              marginLeft: 10,
              textDecoration: 'none !important',
            }}
          >
            <span className="text-primary">{selected.fullName}</span>
            <span className="text-muted small">{selected.email}</span>

          </div>

        </div>

      </Token>
    );

  };

  const renderItem = (position: UserPosition, index: number) => {

    return <MenuItem
      className="user-typeahead-item"
      key={index}
      position={position.position}
      option={position.user}
    >

      <div className="flex-container">
        <div
          className="user-typeahead-list"
          style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', marginLeft: 10 }}
        >
          <span className="text-primary">
            {position.user.fullName}
          </span>
          <span className="text-muted small">{position.user.email}</span>

        </div>

      </div>

    </MenuItem>;
  };

  const options = status.state === 'success' ? status.content : [];
  const isLoading = status.state === 'loading';

  return (
    <Typeahead
      id={`user-typeahead-${props.accountId}`}
      placeholder={props.placeholder ? props.placeholder : 'Select Users'}
      options={options}
      selected={props.selected}
      onChange={props.onChange}
      multiple={props.multiple}
      bsSize={props.size ? props.size : undefined}
      paginate={false}
      isLoading={isLoading}
      maxResults={options.length}
      minLength={0}
      labelKey={(item: User) => item.fullName}
      renderToken={renderToken}
      renderMenu={(users, menuProps) => {
        const mappedUsers = users.map((u, i) => {
          return {
            user: u,
            position: i,
          };
        });
        const current = mappedUsers.filter(u => !u.user.isArchived && !u.user.isDeleted).map(renderItem);
        const archived = mappedUsers.filter(u => u.user.isArchived || u.user.isDeleted).map(renderItem);
        return (
          <Menu {...menuProps}>
            {current.length ? <Menu.Header>
              Current Users
            </Menu.Header> : null}
            {current}
            {archived.length ? <Menu.Divider /> : null}
            {archived.length ? <Menu.Header>
              Archived Users
            </Menu.Header> : null}
            {archived}

          </Menu>

        );
      }}

    />

  );

};

import Axios from 'axios';
import { filter, groupBy, map } from 'lodash';
import * as React from 'react';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { AffinityClient } from '../../../shared';
import { LoadableStatuses } from '../../../shared/RequestStatuses';

interface IProps {
  selected: AffinityClient[];
  onChange: (accounts: AffinityClient[]) => void;
  multiple: boolean;
  licensor?: number;
  filter?: (client: AffinityClient) => boolean;
}

type Status = LoadableStatuses<AffinityClient[], string>;

export const LicensorTypeahead = (props: IProps) => {
  const [status, setStatus] = React.useState<Status>({ state: 'loading' });
  const ref = React.useRef<any>();

  React.useEffect(() => {
    getAccounts('');

  },              []);

  const getAccounts = async (query: string) => {
    setStatus({ state: 'loading' });

    const l = await Axios.get('/api/licensors?includes=profile');
    const licensors = l.data.data.map((u: any) => new AffinityClient(u));
    const selected = [... props.selected];
    let shouldUpdate = false;
    selected.forEach((c, i) => {
      if (c.shortName === 'Loading') {
        shouldUpdate = true;
        const foundLic = licensors.find((l: AffinityClient) => Number(l.id) === Number(c.id));
        if (foundLic) {
          selected[i].shortName = foundLic.shortName;
        }
      }
    });
    if (shouldUpdate) {
      props.onChange(selected);
    }
    setStatus({ content: licensors, state: 'success' });

  };
  const renderMenu = (licensors: AffinityClient[], menuProps: any) => {
    let index = 0;
    const grouped = groupBy(licensors.filter(l => l.market), l => l.market.name);
    const items = Object.keys(grouped).sort().map(market => [
      !!index && <Menu.Divider key={`${market}-divider`} />,
      <Menu.Header key={`${market}-header`}>
        {market}
      </Menu.Header>,
      map(grouped[market], (licensor) => {
        const item =
          <MenuItem key={index} option={licensor} position={index}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 25, height: 25, marginRight: 10 }}>
                  {
                    licensor.affinityImage.isUsingDefault ? null : (
                      <img className="img-responsive" alt="" src={licensor.affinityImage.getSize('th')} />
                    )}
                </div>
                <span>{licensor.name}</span>
              </div>
          </MenuItem>;

        index++;
        return item;
      }),
    ]);

    return <Menu {...menuProps}>{items}</Menu>;

  };

  let options = status.state === 'success' ? status.content : [];
  if (props.filter) {
    options = options.filter(props.filter);
  }
  const isLoading = status.state === 'loading';

  return (
    <Typeahead
      ref={ref}
      id={'licensor-typeahead'}
      placeholder={`Select Licensor${props.multiple ? 's' :''}`}
      options={options}
      selected={props.selected}
      onChange={(v) => {
        props.onChange(v);
        if (!v.length) {
          ref.current.focus();
          ref.current._showMenu();
        }
      }}
      multiple={props.multiple}
      paginate={false}
      isLoading={isLoading}
      maxResults={options.length}
      labelKey={(item: AffinityClient) => item.shortName}
      renderMenu={renderMenu}
      clearButton={true}

    />

  );

};

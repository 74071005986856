import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { EnforcementTemplate } from '../../../shared/EnforcementTemplate';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { EnforcementTemplateForm, EnforcmentTemplateFormType } from './EnforcementTemplateForm';

export const EnforcementTemplateDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const form = useForm<EnforcmentTemplateFormType>({
    defaultValues : {
      name: '',
      description: '',
      content: '',
      platform: 0,
      dmcaType: 0,
      subject: '',
    },
  });
  const [enforcementTemplate, setEnforcementTemplate] = React.useState<EnforcementTemplate>();
  const [updating, setUpdating] = React.useState(false);

  React.useEffect(() => {
    getTemplate();

  },              []);

  const getTemplate = async () => {
    const res = await fetch(`/api/enforcement-templates/${id}`);
    const data = await res.json();
    const t = EnforcementTemplate.fromApi(data.data);
    setEnforcementTemplate(t);
    form.reset({
      name: t.name,
      description: t.description,
      content: t.content,
      platform: t.platformId,
      dmcaType: t.dmcaType,
      subject: t.subject,
    });
  };

  const submit = async (values: EnforcmentTemplateFormType) => {
    if (!updating) {
      setUpdating(true);
      const data = {
        name: values.name,
        description: values.description,
        content: values.content,
        platform_id: values.platform,
        dmca_type: values.dmcaType,
        subject: values.subject,
      };
      await fetch(`/api/enforcement-templates/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      history.push('/enforce/templates');
    }

  };

  const deleteTemplate = async () => {
    const c = confirm('Delete Template?');
    if (c) {
      await fetch(`/api/enforcement-templates/${id}`, { method: 'DELETE' });
      history.push('/enforce/templates');
    }
  };

  if (!enforcementTemplate) {
    return <LoadingSpinner />;
  }

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Templates')} />
      <div className="panel panel-portal">
        <div className="panel-body">
          <form onSubmit={form.handleSubmit(submit)}>
            <EnforcementTemplateForm {... form} />
            <button type="button" onClick={deleteTemplate} className="btn btn-danger pull-left">Delete Template</button>
            <button className="btn btn-primary pull-right">{updating ? 'Saving...' : 'Save'}</button>
          </form>
        </div>
      </div>
    </FullContent>
  );
};

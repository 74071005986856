import axios, { AxiosError, AxiosPromise, AxiosResponse, CancelTokenSource } from 'axios';
import { mapKeys, snakeCase } from 'lodash';
import { StandardRoyaltyParams } from './components/ClientRoyaltySchedules/StandardScheduleModal';
import { MarketplacePayoutAccount, PayoutAccountStatus } from './components/Marketplace/Settings';
import { VerificationStatus } from './components/Marketplace/Settings/VerificationSettings';
import {
  ClientStats,
  DistributionChannelStats,
  ProductCategoryStats,
  QuarterStats,
  VendorStats,
} from './components/Royalties/RoyaltyAnalytics/models';
import { ShareStats } from './components/Royalties/RoyaltyAnalytics/models/ShareStats';
import { IMinimumEntry } from './components/Vendors/VendorRoyaltyMinimumsPage';
import {
  DistributionChannel,
  Filters,
  MarketplaceProduct,
  MarketplaceSettings,
  NoteThread,
  ProductCategory,
  ProductCategoryApiResponse,
  ProductCategoryParentMapping,
  RoyaltyReport,
  Vendor,
} from './shared';
import { Insignia } from './shared/Insignia';
import { MarketplaceOrder } from './shared/MarketplaceOrder';
import { PayInvoiceRequest } from './shared/Payments/PayInvoiceRequest';
import User from './shared/User';

export function logIfCancelled(error: AxiosError, methodName: string): void {
  if (axios.isCancel(error)) {
    console.log(`Cancelled ${methodName} request`, error);
  }
}

export interface ICreateRoyaltyReportParams {
  vendorId: number;
  quarter: number;
  year: number;
}

export const setStandardSchedule = (
  licensorId: number,
  params: StandardRoyaltyParams,
  cancelTokenSource: CancelTokenSource,
): AxiosPromise => {
  const url = `/api/licensors/${licensorId}/standard-schedule`;
  const numericRate = Number(params.royaltyRate) / 100;

  const requestParams = {
    royalty_rate: numericRate,
    minimum_royalty: params.minimumRoyalty,
  };

  return axios.post(url, requestParams, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'setStandardSchedule');
      return error;
    });
};

export const deleteSchedule = (scheduleId: number, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  const url = `/api/royaltySchedules/${scheduleId}`;
  return axios.delete(url, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'deleteSchedule');
      return error;
    });
};

export const updateScheduleVendors = (
  scheduleId: number,
  vendorIds: number[],
  cancelTokenSource: CancelTokenSource,
): AxiosPromise => {
  const url = `/api/royaltySchedules/${scheduleId}/vendors`;
  return axios.put(url, { exclusive_vendors: vendorIds }, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'deleteSchedule');
      return error;
    });
};

export const createNewRoyaltyReport = (
  params: ICreateRoyaltyReportParams,
  cancelTokenSource: CancelTokenSource,
): AxiosPromise => {
  const url = '/api/royalty-reports';
  const postParams = {
    year: params.year,
    quarter: params.quarter,
    vendor_id: params.vendorId,
  };
  return axios.post(url, postParams, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'createNewRoyaltyReport');
      return error;
    });
};

export const getRoyaltyReport = (id: number): AxiosPromise => {
  return axios.get(`/api/royalty-reports/${id}`)
    .catch((error) => {
      logIfCancelled(error, 'getRoyaltyReport');
      throw error;
    });
};

export const setRoyaltyReportAdmin = (
  royaltyId: number,
  adminUserId: number|string,
  cancelTokenSource: CancelTokenSource,
): AxiosPromise => {
  const url = `/api/royalty-reports/${royaltyId}/admin`;
  const postParams = {
    user_id: adminUserId,
  };
  return axios.post(url, postParams, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'setRoyaltyReportAdmin');
      throw error;
    });
};

export const deleteRoyaltyReport = (id: number, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  return axios.delete(`/api/royalty-reports/${id}`, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'deleteRoyaltyReport');
      throw error;
    });
};

export const deleteSalesDataLineItems = (id: number, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  return axios.delete(`/api/royalty-reports/${id}/line-items?type=sales-data`, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'deleteSalesDataLineItems');
      throw error;
    });
};

export const setRoyaltyReportPhase = (
  royaltyId: number,
  phase: string,
  cancelTokenSource: CancelTokenSource,
  message?: string,
): AxiosPromise => {
  const url = `/api/royalty-reports/${royaltyId}/phase`;
  const postParams = {
    phase,
  };
  if (message) {
    postParams['message'] = message;
  }
  return axios.put(url, postParams)
    .catch((error) => {
      logIfCancelled(error, 'setRoyaltyReportPhase');
      throw error;
    });
};

export const getProductCategories = (): Promise<ProductCategoryParentMapping[]> => {
  return axios.get('/api/product_categories?include_parents=1')
    .catch((error) => {
      logIfCancelled(error, 'getProductCategories');
      throw error;
    })
    .then((response) => {
      const categories: ProductCategory[] = response.data.data
         .map((c: ProductCategoryApiResponse) => ProductCategory.fromApi(c));
      let baseParents: ProductCategoryParentMapping[] = [];
      let parents: ProductCategoryParentMapping[] = [];
      let children: ProductCategory[] = [];
      categories.forEach(c => c.isParent ? c.parent ?
        parents.push({ category: c, children: [] }) : baseParents.push({ category: c, children: [] }) : children.push(c));

      children = children.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      children.forEach((c) => {
        const parent = c.parent;
        if (parent) {
          const parentIndex = parents.findIndex(p => p.category.id === parent.id);
          if (parentIndex !== -1) {
            parents[parentIndex].children.push({ category: c, children: [] });
          } else {
            const baseParentIndex = baseParents.findIndex(p => p.category.id === parent.id);
            if (baseParentIndex !== -1) {
              baseParents[baseParentIndex].children.push({ category: c, children: [] });
            }
          }

        }
      });

      parents = parents.sort((a, b) => {
        if (a.category.name < b.category.name) {
          return -1;
        }
        if (a.category.name > b.category.name) {
          return 1;
        }
        return 0;
      });

      // Sort parents into themselves first
      parents.forEach((c) => {
        const parent = c.category.parent;

        if (parent) {
          const index = parents.findIndex(p => p.category.id === parent.id);
          if (index !== -1) {
            parents[index].children.push(c);
          }
        }
      });
      parents.forEach((c, index) => {
        parents[index].children = c.children.sort((a, b) => {
          if (a.category.name < b.category.name) {
            return -1;
          }
          if (a.category.name > b.category.name) {
            return 1;
          }
          return 0;
        });
      });
      // sort main parents into base

      parents.forEach((c) => {
        const parent = c.category.parent;
        if (parent) {
          const baseIndex = baseParents.findIndex(p => p.category.id === parent.id);
          if (baseIndex !== -1) {
            baseParents[baseIndex].children.push(c);
          }
        }
      });
      baseParents = baseParents.sort((a, b) => {
        if (a.category.name < b.category.name) {
          return -1;
        }
        if (a.category.name > b.category.name) {
          return 1;
        }
        return 0;
      });
      baseParents.forEach((p, index) => {
        baseParents[index].children = p.children.sort((a, b) => {
          if (a.category.name < b.category.name) {
            return -1;
          }
          if (a.category.name > b.category.name) {
            return 1;
          }
          return 0;
        });
      });

      return baseParents;
    });
};

interface IReportingAdminResponse {
  assignable: User[];
  unassignable: User[];
}

export const getReportingAdmins = (cancelTokenSource: CancelTokenSource): Promise<IReportingAdminResponse> => {
  return axios.get('/api/royalty-reports/admins', { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'createNewRoyaltyReport');
      throw error;
    })
    .then((response: AxiosResponse<any>) => {
      const data = response.data.data;

      return {
        assignable: data.assignable.map((item: any) => new User(item)),
        unassignable: data.unassignable.map((item: any) => new User(item)),
      };
    });
};

export const getLicensors = (): AxiosPromise => {
  return axios.get('/api/licensors')
    .catch((error) => {
      logIfCancelled(error, 'getLicensors');
      throw error;
    });
};

export const getAggregateLicensors = (): AxiosPromise => {
  return axios.get('/api/licensors?aggregate_only=1')
    .catch((error) => {
      logIfCancelled(error, 'getAggregateLicensors');
      throw error;
    });
};

export const getDistributionChannels = (): AxiosPromise => {
  return axios.get('/api/distributionChannels')
    .catch((error) => {
      logIfCancelled(error, 'getDistributionChannels');
      throw error;
    });
};

export const getInsignia = (): Promise<Insignia[]> => {
  return axios.get('/api/brandmarks')
    .catch((error) => {
      logIfCancelled(error, 'getDistributionChannels');
      throw error;
    })
    .then((response: AxiosResponse<any>) => {
      const data = response.data.data;

      return data.map((item: any): Insignia => Insignia.fromApi(item));
    });
};

export const getAggregateLineItems = (report: RoyaltyReport): AxiosPromise => {
  return axios.get(`/api/royalty-reports/${report.id}` + '/aggregates')
    .catch((error) => {
      logIfCancelled(error, 'getAggregateLineItems');
      throw error;
    });
};

export const getAggregateFileUploads = (report: RoyaltyReport): AxiosPromise => {
  return axios.get(`/api/royalty-reports/${report.id}` + '/aggregates/uploads')
    .catch((error) => {
      logIfCancelled(error, 'getAggregateFileUploads');
      throw error;
    });
};

export const submitRoyaltyReport = (
  reportId: number,
  payInvoice: boolean,
  cancelTokenSource: CancelTokenSource,
): AxiosPromise => {
  const data = {
    submitted: true,
    pay_invoice: payInvoice,
  };
  return axios.patch(`/api/royalty-reports/${reportId}`, data, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'submitRoyaltyReport');
      throw error;
    });
};

export const getLicensorSchedules = (licensorId: number, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  return axios.get(`/api/royaltySchedules?client_id=${licensorId}`, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getLicensorSchedules');
      throw error;
    });
};

export const getRollups = (report: RoyaltyReport, type: 'licensor' | 'schedule'): AxiosPromise => {
  return axios.get(`/api/royalty-reports/${report.id}/rollups?type=${type}`)
    .catch((error) => {
      logIfCancelled(error, 'getRollups');
      throw error;
    });
};

export const getPaymentMethod = (accountId: number|string, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  const params = {
    account_id: accountId,
    is_verified: 1,
    is_primary: 1,
  };

  return axios.get('/api/payment-methods', { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getPaymentMethod');
      throw error;
    });
};

export const getAccountProfile = (id: number, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  return axios.get(`/api/v2/accounts/${id}`, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getAccountProfile');
      throw error;
    });
};

interface IRoyaltyAnalyticsData {
  isCurrent: boolean;
  quarters: QuarterStats[];
  share: ShareStats;
  productCategories: ProductCategoryStats[];
  distributionChannels: DistributionChannelStats[];
  isPartialSales: boolean;
  isSalesData: boolean;
  filterLabels: {
    category: string | null;
    channel: string | null;
    vendor: string | null;
    client: string | null;
  };
  filterRange: {
    start: {
      year: number;
      quarter: number;
    };
    end: {
      year: number;
      quarter: number;
    };
  };
}

export const getRoyaltyAnalytics = (filters: any, cancelTokenSource: CancelTokenSource): Promise<IRoyaltyAnalyticsData> => {
  return axios.get('/api/royalty-analytics', { params: filters, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getRoyaltyAnalytics');
      throw error;
    })
    .then((response: AxiosResponse<any>) => {

      const data = response.data;

      const quarterItems = data.quarters.map((quarterItem: any) => {
        return {
          name: quarterItem.name,
          quarter: quarterItem.quarter,
          year: quarterItem.year,
          totalRoyalties: quarterItem.total,
        };
      });

      const shareStats = data.share;

      const categories = data.product_categories.map((categoryItem: any) => {
        return {
          category: {
            id: categoryItem.category.id,
            name: categoryItem.category.name,
          },
          averageRPU: categoryItem.rpu,
          units: categoryItem.units,
          royalties: categoryItem.total,
        };
      });

      const channels = data.distribution_channels.map((channelItem: any) => {
        return {
          channel: new DistributionChannel(channelItem.channel),
          units: channelItem.units,
          royalties: channelItem.royalties,
        };
      });

      return {
        isCurrent: data.is_current,
        quarters: quarterItems,
        share: shareStats,
        productCategories: categories,
        distributionChannels: channels,
        filterLabels: data.filter_labels,
        filterRange: data.filter_range,
        isPartialSales: data.is_partial_sales,
        isSalesData: data.is_sales_data,
      };
    });
};

interface PaginatedVendorStats {
  vendors: VendorStats[];
  pagination: {
    page: number;
    total: number;
    hasNext: boolean;
  };
}

export const getRoyaltyVendors = (
  filters: any,
  page: number,
  cancelTokenSource: CancelTokenSource,
) : Promise<PaginatedVendorStats> => {
  const params = Object.assign({}, filters);
  params.page = page;
  return axios.get('/api/royalty-analytics/vendors', { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getRoyaltyVendors');
      throw error;
    })
    .then((response: AxiosResponse<any>) => {
      const data = response.data;

      const vendorItems = data.accounts.map((vendorItem: any): VendorStats => {

        const comparisonObject: VendorStats['comparison'] = (vendorItem.comparison) ? {
          direction:  (vendorItem.comparison > 0) ? 'up' : 'down',
          difference: vendorItem.comparison,
          previous: vendorItem.previous_royalties,
          percent: Math.round(Math.abs(vendorItem.comparison_percent) * 100),
        } : {
          direction: 'up',
          difference: null,
          previous: null,
          percent: null,
        };

        return {
          vendor: new Vendor(vendorItem.account),
          units: vendorItem.units,
          royalties: vendorItem.royalties,
          comparison: comparisonObject,
          rank: vendorItem.rank,
        };
      });

      return {
        vendors: vendorItems,
        pagination: {
          page: data.meta.pagination.page,
          total: data.meta.pagination.total_count,
          hasNext: data.meta.pagination.has_next,
        },
      };
    });
};

interface PaginatedClientStats {
  clients: ClientStats[];
  pagination: {
    page: number;
    total: number;
    hasNext: boolean;
  };
}

export const getRoyaltyClients = (
  filters: any,
  page: number,
  cancelTokenSource: CancelTokenSource,
) : Promise<PaginatedClientStats> => {
  const params = Object.assign({}, filters);
  params.page = page;
  return axios.get('/api/royalty-analytics/clients', { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getRoyaltyClients');
      throw error;
    })
    .then((response: AxiosResponse<any>) => {
      const data = response.data;

      const clientItems = data.accounts.map((clientItem: any): ClientStats => {

        const comparisonObject: ClientStats['comparison'] = (clientItem.comparison) ? {
          direction: (clientItem.comparison > 0) ? 'up' : 'down',
          difference: clientItem.comparison,
          previous: clientItem.previous_royalties,
          percent: Math.round(Math.abs(clientItem.comparison_percent) * 100),
        } : {
          direction: 'up',
          difference: null,
          previous: null,
          percent: null,
        };

        return {
          client: new Vendor(clientItem.account),
          units: clientItem.units,
          royalties: clientItem.royalties,
          comparison: comparisonObject,
          rank: clientItem.rank,
        };
      });

      return {
        clients: clientItems,
        pagination: {
          page: data.meta.pagination.page,
          total: data.meta.pagination.total_count,
          hasNext: data.meta.pagination.has_next,
        },
      };
    });
};

interface IVendorRoyaltyMinimumResponse {
  minimumEntries: IMinimumEntry[];
  year: number;
}

export const getVendorRoyaltyMinimumProgress = (vendorId: string) : Promise<IVendorRoyaltyMinimumResponse> => {
  return axios.get(`/api/vendors/${vendorId}/minimum-royalties`)
    .catch((error) => {
      logIfCancelled(error, 'getVendorRoyaltyMinimumProgress');
      throw error;
    })
    .then((response: AxiosResponse<any>) => {
      const data = response.data.data;

      const minimumEntries = data.map((item: any): IMinimumEntry => ({
        licensorName: item.licensor_name,
        licensorImage: item.licensor_image,
        minimumGuarantee: item.minimum_guarantee,
        royaltiesReported: item.royalties_reported,
        minimumRemaining: item.minimum_remaining,
      }));

      return {
        minimumEntries,
        year: response.data.year,
      };
    });
};

interface IMarketplaceOrdersResponse {
  orders: MarketplaceOrder[];
  total: number;
  totalPages: number;
}

export const getMarketplaceOrdersParams = (filters: Filters) => {
  let status;
  if (Number(filters.status) === 1) {
    status = 'pending';
  } else if (Number(filters.status) === 2) {
    status = 'shipped';
  } else if (Number(filters.status) === 3) {
    status = 'refunded';
  }

  return {
    status,
    page: filters.page,
    limit: filters.perPage,
    search: filters.search,
    vendor_id: filters.vendorId,
    start_date: filters.startDate,
    end_date: filters.endDate,
  };
};

export const getMarketplaceOrders = (
  filters: Filters,
  cancelTokenSource: CancelTokenSource,
): Promise<IMarketplaceOrdersResponse> => {
  const params = getMarketplaceOrdersParams(filters);
  return axios.get('/api/marketplace/marketplaceOrders', { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getMarketplaceOrders');
      throw error;
    })
    .then((response: AxiosResponse<any>) => {
      return {
        orders: response.data.data.map(MarketplaceOrder.fromApi),
        total: response.data.meta.pagination.total,
        totalPages: response.data.meta.pagination.total_pages,
      };
    });
};

export const getMarketplaceOrder = (
  id: number | string,
  params: any,
  cancelTokenSource: CancelTokenSource,
): Promise<MarketplaceOrder> => {
  return axios.get(`/api/marketplace/marketplaceOrders/${id}`, { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getMarketplaceOrder');
      throw error;
    })
    .then((response: AxiosResponse<any>) => {
      return MarketplaceOrder.fromApi(response.data.data);
    });
};

export interface IShippingCarrier {
  id: number;
  name: string;
}

export const getShippingCarriers = (cancelTokenSource: CancelTokenSource): Promise<IShippingCarrier[]> => {
  return axios.get('/api/marketplace/shippingCarriers', { cancelToken: cancelTokenSource.token })
    .then((response: AxiosResponse<any>) => {
      return response.data.data.map((entry: any) => ({
        id: Number(entry.id),
        name: entry.name,
      }));
    });
};

export const markMarketplaceLineItemAsShipped = (
  orderId: number | string,
  shippingDetails: {
    lineItemId: number | string,
    shippingCarrierId: number,
    trackingCode: string,
  },
  cancelTokenSource: CancelTokenSource,
) => {
  return axios.post(
    `/api/marketplace/marketplaceOrders/${orderId}/shipments`,
    {
      line_item_id: shippingDetails.lineItemId,
      shipping_carrier_id: shippingDetails.shippingCarrierId,
      tracking_code: shippingDetails.trackingCode,
    },
    { cancelToken: cancelTokenSource.token },
  )
    .catch((error) => {
      logIfCancelled(error, 'markMarketplaceLineItemAsShipped');
      throw error;
    });
};
export const refundMarketplaceLineItem = (
  orderId: number | string,
  itemId: number | string,
  quantity: number,
  shippingAmount: number,
  cancelTokenSource?: CancelTokenSource,
) => {
  const token = cancelTokenSource ? cancelTokenSource.token : undefined;
  return axios.post(
    `/api/marketplace/orderLineItems/${itemId}/refunds`,
    {
      quantity,
      shipping_amount: shippingAmount,
    },
    { cancelToken: token },
  )
    .catch((error) => {
      logIfCancelled(error, 'refundMarketplaceLineItem');
      throw error;
    });
};

export const getMessageThreads = (params: any, cancelTokenSource: CancelTokenSource) => {
  return axios.get('/api/marketplace/threads', { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getMessageThreads');
      throw error;
    });
};

export const getMessageThread = (id: number, params: any, cancelTokenSource: CancelTokenSource) => {
  return axios.get(`/api/marketplace/threads/${id}`, { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getMessageThread');
      throw error;
    });
};

export const createThread = (userId: number, message: string, cancelTokenSource?: CancelTokenSource) => {
  const params = {
    message,
    as_vendor: 1,
    threadUsers: userId,
  };
  const token =  cancelTokenSource ? cancelTokenSource.token : undefined;
  return axios.post('/api/marketplace/threads/create', params, { cancelToken: token })
    .catch((error) => {
      logIfCancelled(error, 'createThread');
      throw error;
    });
};

export const sendMessage = (id: number, params: any, cancelTokenSource: CancelTokenSource) => {
  return axios.post(`/api/marketplace/threads/${id}/add_message`, params, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'sendMessage');
      throw error;
    });
};

export const getOlderMessages = (id: number, params: any, cancelTokenSource: CancelTokenSource) => {
  return axios.post(`/api/marketplace/threads/${id}/older`, params, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getOlderMessages');
      throw error;
    });
};

interface IPayoutAccountResponse {
  verificationStatus: VerificationStatus;
  payoutAccountStatus: PayoutAccountStatus;
  payoutAccount: MarketplacePayoutAccount | null;
}

const getVerificationStatusFromApiString = (apiString: string): VerificationStatus => {
  if (apiString === 'verified') {
    return VerificationStatus.Verified;
  }

  if (apiString === 'rejected') {
    return VerificationStatus.Rejected;
  }

  if (apiString === 'pending') {
    return VerificationStatus.Submitted;
  }

  return VerificationStatus.NotSubmitted;
};

export const updateAccount = (
  accountId: number,
  accountUpdates: FormData,
  cancelTokenSource: CancelTokenSource,
): Promise<any> => {
  // const hasImages = (!!accountUpdates.image);
  // const headers = hasImages ? { 'Content-Type': 'multipart/form-data' } : {};
  const headers = { 'Content-Type': 'multipart/form-data' };

  return axios.post(`/api/accounts/${accountId}`, accountUpdates, { headers, cancelToken: cancelTokenSource.token })
    .then((response: AxiosResponse<any>) => {
      return true;
    });
};

export const getPayoutAccount = (
  vendorId: number | null,
  cancelTokenSource: CancelTokenSource,
): Promise<any> => {
  const params = vendorId === null ? {} : { vendor_id: vendorId };
  return axios.get('/api/marketplace/payout_account', { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getPayoutAccount');
      throw error;
    }).then((response: AxiosResponse<any>) => {
      const data = response.data.data;

      if (data === []) {
        return {
          verificationStatus: VerificationStatus.NotSubmitted,
          payoutAccountStatus: PayoutAccountStatus.NotSubmitted,
          payoutAccount: null,
        };
      }

      const payoutAccount = data.payout_last_four ? {
        bank: data.bank_account_name,
        lastFour: Number(data.payout_last_four),
      } : null;

      return {
        payoutAccount,
        verificationStatus: getVerificationStatusFromApiString(data.identity_verification_status),
        payoutAccountStatus: payoutAccount !== null ? PayoutAccountStatus.Submitted : PayoutAccountStatus.NotSubmitted,
      };
    });
};

export const submitPayoutAccount = (
  body: any,
  vendorId: number | null,
  cancelTokenSource: CancelTokenSource,
): Promise<IPayoutAccountResponse> => {
  const params = vendorId === null ? {} : { vendor_id: vendorId };

  const requestBody = {
    business_type: body.businessType,
    business_name: body.businessName,
    business_tax_id: body.businessTaxId,

    city: body.businessAddress.city,
    address_line1: body.businessAddress.address1,
    address_line2: body.businessAddress.address2,
    state: body.businessAddress.state,
    zip: body.businessAddress.zip,

    date_of_birth_day: parseInt(body.birthday.day, 10),
    date_of_birth_month: parseInt(body.birthday.month, 10),
    date_of_birth_year: parseInt(body.birthday.year, 10),

    first_name: body.firstName,
    last_name: body.lastName,
    ssn_last_4: body.ssn,
    url: body.url,
    external_account: body.stripeToken,
  };

  return axios.post('/api/marketplace/payout_account', requestBody, { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getPayoutAccount');
      throw error;
    }).then((response: AxiosResponse<any>) => {
      const data = response.data.data;

      if (data === []) {
        return {
          verificationStatus: VerificationStatus.NotSubmitted,
          payoutAccountStatus: PayoutAccountStatus.NotSubmitted,
          payoutAccount: null,
        };
      }

      const payoutAccount = data.payout_last_four ? {
        bank: data.bank_account_name,
        lastFour: Number(data.payout_last_four),
      } : null;

      return {
        payoutAccount,
        verificationStatus: getVerificationStatusFromApiString(data.identity_verification_status),
        payoutAccountStatus: payoutAccount !== null ? PayoutAccountStatus.Submitted : PayoutAccountStatus.NotSubmitted,
      };
    });
};

export const updatePayoutAccount = (data: any, cancelTokenSource: CancelTokenSource) => {
  return axios.put('/api/marketplace/payout_account', data, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'updatePayoutAccount');
      throw error;
    });
};

export const getMarketplaceProducts = (filters: any, cancelTokenSource: CancelTokenSource): Promise<any> => {
  return axios.get('/api/marketplace/products', { params: filters, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getMarketplaceProuducts');
      throw error;
    });
};

export const getMarketplaceProduct =
  (id: number | string, filters: any, cancelTokenSource: CancelTokenSource): Promise<any> => {
    return axios.get(`/api/marketplace/products/${id}`, { params: filters, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getMarketplaceProduct');
      throw error;
    });
  };

export const getMarketplaceCategories =
  (client: number | null, vendor: number | null, cancelTokenSource: CancelTokenSource): Promise<any> => {
    let params = '?';
    if (client) {
      params += `&client_id=${client}`;
    }
    if (vendor) {
      params += `&vendor_id=${vendor}`;
    }
    return axios.get(`api/productCategories/licensed${params}`, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getMarketplaceCategories');
      throw error;
    });
  };

export const requestShopifyProductSync = (id: number | string, cancelTokenSource: CancelTokenSource): Promise<any> => {
  return axios.post(`api/marketplace/products/${id}/shopify-sync`, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'requestShopifyProductSync');
      throw error;
    });
};

export const updateMarketplaceProduct =
  (id: number | string, data: FormData, cancelTokenSource: CancelTokenSource): Promise<MarketplaceProduct> => {
    const config = {
      cancelToken: cancelTokenSource.token,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return axios.post(`/api/marketplace/products/${id}`, data, config)
      .then((response) => {
        return MarketplaceProduct.fromApi(response.data.data);
      })
      .catch((error) => {
        logIfCancelled(error, 'updateMarketplaceProduct');
        throw error;
      });
  };
export const getMarketplaceTags = (search: string, cancelTokenSource: CancelTokenSource) => {
  return axios.get(`/api/marketplace/tags?keyword=${search}`, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getMarketplaceTags');
      throw error;
    });
};

export const getMarketplaceSettings = (vendorId: number | null, cancelTokenSource: CancelTokenSource) => {
  let queryParam = '';
  if (vendorId) {
    queryParam = `?vendor_id=${vendorId}`;
  }
  return axios.get(`/api/marketplace/settings${queryParam}`, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getMarketplaceSettings');
      throw error;
    });
};

export const updateMarketplaceSettings =
  (vendorId: number | null, settings: MarketplaceSettings, cancelTokenSource: CancelTokenSource) => {
    const data = {
      default_returns: settings.defaultReturnText,
      default_delivery: settings.defaultShippingText,
      default_duration_quantity: settings.defaultDurationQuantity,
      default_duration_term_id: settings.defaultDurationTermId,
      default_shipping_price_first: settings.defaultShippingPriceFirst,
      default_shipping_price_additional: settings.defaultShippingPriceAdditional,
      vendor_id: vendorId,
    };
    return axios.post('/api/marketplace/settings', data, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'updateMarketplaceSettings');
      throw error;
    });
  };

export const getLicensedClientsForVendor = (vendorId: number) => {
  return axios.get(`/api/vendors/${vendorId}/licensed-with`)
    .catch((error) => {
      logIfCancelled(error, 'getLicensedClientsForVendor');
      throw error;
    }).then((response) => {
      return response.data.data.slice().sort((a: any, b: any) => {
        return a.short_name.localeCompare(b.short_name, 'en');
      });
    });
};

export const payInvoice = (payRequest: PayInvoiceRequest) => {
  const path = `/api/invoices/${payRequest.invoiceId}/payments`;
  const body = mapKeys(payRequest, (v, k) => snakeCase(k));
  delete body.invoice_id;
  return axios.post(path, body)
    .then((response) => {
      // TODO: format response properly
      return response.data.data;
    })
    .catch((error) => {
      logIfCancelled(error, 'payInvoice');
      throw error;
    });
};

export interface IShopifyStatus {
  id: number;
  isConnected: boolean;
  store: string;
  productsSynced: number;
}

export const getShopifyStatus =
  (vendorId: number | undefined, cancelTokenSource: CancelTokenSource): Promise<IShopifyStatus[]> => {
    const params = vendorId ? { vendor_id: vendorId } : {};
    return axios.get(
      '/api/marketplace/shopify-status',
      { params, cancelToken: cancelTokenSource.token },
      ).then((response) => {
        return response.data.data.map((credential: any) => ({
          id: credential.id,
          isConnected: credential.is_connected,
          store: credential.store,
          productsSynced: credential.products_synced,
        }));
      });
  };

export interface INoteCategory {
  id: number;
  name: string;
}
export const getNoteCategories = (cancelTokenSource: CancelTokenSource): Promise<INoteCategory[]> => {
  return axios.get(
    '/api/note-categories',
    { cancelToken : cancelTokenSource.token },
  ).then((response) => {
    return response.data.data.map((entry: any) => ({
      id: entry.id,
      name: entry.name,
    }));
  });
};
export interface INoteMethod {
  id: number;
  name: string;
}
export const getNoteMethods = (cancelTokenSource: CancelTokenSource): Promise<INoteMethod[]> => {
  return axios.get(
    '/api/note-methods',
    { cancelToken : cancelTokenSource.token },
  ).then((response) => {
    return response.data.data.map((entry: any) => ({
      id: entry.id,
      name: entry.name,
    }));
  });
};

export interface CreateThreadRequest {
  accountId: number;
  caseId?: number;
  subject: string;
  noteCode: string|null;
  isPinned?: boolean;
  noteWarn?: boolean;
  noteBody: string;
  noteMethodId: number|null;
  noteAttachments?: File[];
  userIds?: number[];
  categoryIds: number[];
  noteDate: string;
}
export const createNoteThread = (req: CreateThreadRequest): Promise<NoteThread> => {
  const body = {
    account_id: req.accountId,
    subject: req.subject,
    note_code: req.noteCode,
    is_pinned: req.isPinned ? 1 : 0,
    note_warn: req.noteWarn ? 1 : 0,
    note_body: req.noteBody,
    note_method_id: req.noteMethodId,
    note_attachments: req.noteAttachments ? req.noteAttachments : [],
    userIds: req.userIds ? req.userIds : [],
    category_ids: req.categoryIds,
    note_occurred_at: req.noteDate,
  };

  const formData = new FormData();

  if (req.caseId) {
    formData.append('case_id', `${req.caseId}`);
  }

  for (const bodyKey in body) {
    if (body.hasOwnProperty(bodyKey)) {
      if (bodyKey === 'note_attachments') {
        Array.from(body['note_attachments']).forEach(file => formData.append('note_attachments[]', file, file.name));
      } else if (bodyKey === 'userIds') {
        body['userIds'].forEach(u => formData.append('userIds[]', `${u}`));
      } else if (bodyKey === 'category_ids') {
        body['category_ids'].forEach(u => formData.append('category_ids[]', `${u}`));

      } else if (bodyKey === 'note_occurred_at') {
        if (body['note_occurred_at']) {
          formData.append(bodyKey, body['note_occurred_at']);

        }

      } else {
        formData.append(bodyKey, body[bodyKey]);
      }
    }
  }

  return axios.post('api/note-threads', formData)
    .then(response => NoteThread.fromApi(response.data.data));
};
export enum CancellationStatusId {
  Renewing = 0,
  DoNotRenew = 1,
  LicensorNonRenewal = 2,
}

interface ILicenseRenewalStatus {
  vendorId: number;
  clientId: number;
  cancellationStatus: CancellationStatusId;
  client: {
    name: string;
    imageUrl: string;
  };
  renewalFee: {
    isWaived: boolean;
    amount: string;
  };
  minimumRoyalty: string | null;
  areLicenseRenewalsApplicable: boolean;
}

const mapResponseToStatusItem = (statusItem: any): ILicenseRenewalStatus => ({
  vendorId: statusItem.vendor_id,
  clientId: statusItem.client_id,
  cancellationStatus: statusItem.cancellation_status,
  client: {
    name: statusItem.client.short_name,
    imageUrl: statusItem.client.image.urls.th,
  },
  renewalFee: {
    isWaived: statusItem.renewal_fee.is_waived,
    amount: statusItem.renewal_fee.amount,
  },
  minimumRoyalty: statusItem.minimum_royalty,
  areLicenseRenewalsApplicable: statusItem.are_license_renewals_applicable,
});

export const getRenewalStatuses = (
  vendorId : number | undefined,
  cancelTokenSource: CancelTokenSource,
): Promise<ILicenseRenewalStatus[]> => {
  const params = vendorId ? { vendor_id : vendorId } : {};
  return axios.get(
    '/api/license-renewals',
    { params, cancelToken: cancelTokenSource.token },
  ).then((response) => {
    return response.data.data.map(mapResponseToStatusItem);
  });
};

interface IRenewalRequest {
  vendorId: number | undefined;
  updateItems: {
    clientId: number;
    cancellationStatus: CancellationStatusId;
  }[];
}
export const updateRenewalStatuses = (
  renewalRequest: IRenewalRequest,
  cancelTokenSource: CancelTokenSource,
): Promise<ILicenseRenewalStatus[]> => {
  const params = renewalRequest.vendorId ? { vendor_id : renewalRequest.vendorId } : {};
  const postBody = renewalRequest.updateItems.map(item => ({
    client_id: item.clientId,
    cancellation_status: item.cancellationStatus,
  }));
  return axios.post(
    '/api/license-renewals',
    postBody,
    { params, cancelToken: cancelTokenSource.token },
  ).then((response) => {
    return response.data.data.map(mapResponseToStatusItem);
  });
};

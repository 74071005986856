import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, FieldArray, FormikProps } from 'formik';
import * as React from 'react';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { File } from '../../../shared/File';
import User from '../../../shared/User';
import { UserTypeahead } from '../../shared';
import { NoteMethodsSelect } from '../NoteMethodsSelect';

export interface NoteFormValues {
  note: string;
  users: User[];
  accountUsers: User[];
  contactMethod: any;
  attachments: any[];
  currentAttachments?: File[];
  noteDate: string;

}

export const NoteFormValidation = Yup.object().shape({
  note: Yup.string().required('A note is required'),
  contactMethod: Yup.mixed().required('A contact method is required'),
  users: Yup.array().min(1, 'An admin user is required'),
});

export const NoteForm = (props: FormikProps<NoteFormValues>) => {
  const params = useParams<{accountId: string | undefined}>();
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
    ],
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Contact Method</label>
            <NoteMethodsSelect
              value={props.values.contactMethod ? props.values.contactMethod.id : undefined}
              onSelectFn={v => props.setFieldValue('contactMethod', v)}
            />
            <p className="text-danger">
              <ErrorMessage name="contactMethod" />
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <label>Note Date</label>
          <input
            value={props.values.noteDate}
            name="noteDate"
            id="noteDate"
            type="date"
            className="form-control"
            onChange={e => props.setFieldValue('noteDate', e.target.value)}
          />

        </div>
      </div>

      <div className="form-group notes-quill">
        <label >Note</label>
        <ReactQuill

          style={{ background: 'white' }}
          value={props.values.note}
          onChange={body => props.setFieldValue('note', body)}
          placeholder="Enter Message"
          modules={modules}
        />
        <p className="text-danger">
          <ErrorMessage name="note" />
        </p>
      </div>
      <div className="form-group">
        <label>Admin Users</label>
        <UserTypeahead
          accountId={1}
          multiple={true}
          selected={props.values.users}
          onChange={u => props.setFieldValue('users', u)}
        />
        <p className="text-danger">
          <ErrorMessage name="users" />
        </p>
      </div>
      {params.accountId ? <div className="form-group">
        <label>Account Users</label>
        <UserTypeahead
          accountId={params.accountId}
          multiple={true}
          selected={props.values.accountUsers}
          onChange={u => props.setFieldValue('accountUsers', u)}
        />
      </div> : null}

      {props.values.currentAttachments ?
        <FieldArray name="currentAttachments" render={helpers => (
          <div>
            <label>Current Attachments</label>

            <div style={{ display: 'flex', flexDirection: 'column' }}>

              {props.values.currentAttachments ? props.values.currentAttachments
                .map((a, index) => (<div>
                  <FontAwesomeIcon
                    onClick={() => helpers.remove(index)}
                    style={{ cursor: 'pointer', marginRight: 5 }}
                    className="text-danger"
                    icon={faTimes}
                  /> {a.previousFileName}</div>)) : null}
            </div>
          </div>
      )}

      /> : null}
      <div className="form-group">
        <label>Attachments</label>
        <input
          name="attachments"
          id="attachments"
          type="file"
          multiple={true}
          onChange={e => props.setFieldValue('attachments', e.target.files)}
        />
      </div>

    </div>
  );

};

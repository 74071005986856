import { faBadgeDollar, faHashtag, faSackDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { isNil, omitBy } from 'lodash';
import * as mapboxgl from 'mapbox-gl';
import * as queryString from 'query-string';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import { UserContext } from '../../contexts';
import { FullContent } from '../ContentFrame';
import { AffinityMap, LoadingSpinner } from '../shared';
import { LayerGroup, LayerInfo } from './AudienceMapViewer';

export const AudiencePerCapitaPage = () => {
  const [loading, setLoading] = useState(true);
  const [loadingMap, setLoadingMap] = useState(true);
  const [stateAudiences, setStateAudiences] = useState([]);
  const [cityAudiences, setCityAudiences] = useState([]);
  const [audienceList, setAudienceList] = useState([]);
  const [stateNetAvg, setStateNetAvg] = useState(0);
  const [stateRoyaltyAvg, setStateRoyaltyAvg] = useState(0);
  const [showAllStates, setShowAllStates] = useState(false);
  const [audienceLayerNames, setAudienceLayerNames] = useState<LayerGroup[]>([]);
  const [mapData, setMapData] = React.useState<any>(null);
  const routeParams = useParams();
  const history = useHistory();
  const user = useContext(UserContext);
  const location = useLocation();
  const [audienceId, setAudienceId] = routeParams['id'] ? useState(routeParams['id']) : useState(null);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [audienceCount, setAudienceCount] = useState(0);
  const map = React.useRef<any>(null);
  const query = queryString.parse(location.search);

  useEffect(() => {
    getAudiencePerCapita();
    if (map.current && map.current.map) {
      getAudienceMap();
    }
  },        [audienceId]);

  useEffect(() => {
    if (mapData) {
      addMapLayers();
    }

  },        [mapData]);

  const getAudienceMap = async () => {
    setLoadingMap(true);
    const p = {
      account_id: getAccountID(),
      year: query.year ? query.year : null,
      audience_ids: audienceId ? audienceId : null,
      withAudience: 1,
      dashboard: audienceId ? null : 1,
    };
    const params = omitBy(p, isNil);
    const m = await Axios.get('/api/audience/map', { params });
    await setMapData(m.data);
  };

  const addHeatmapLayer = (info: LayerInfo) => {
    const colorOptions = {
      blue: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(189, 72%, 77%)',
        0.67,
        'hsl(194, 37%, 56%)',
        1,
        'hsl(197, 47%, 36%)',
      ],
      gray: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(0, 0%, 100%)',
        0.67,
        'hsl(0, 0%, 56%)',
        1,
        'hsl(0, 0%, 18%)',
      ],
      green: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(100, 82%, 85%)',
        0.67,
        'hsl(102, 43%, 52%)',
        1,
        'hsl(100, 98%, 24%)',
      ],
      orange: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(38, 84%, 83%)',
        0.67,
        'hsl(25, 79%, 65%)',
        1,
        'hsl(23, 96%, 42%)',
      ],
      pink: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(295, 78%, 73%)',
        0.67,
        'hsl(280, 83%, 68%)',
        1,
        'hsl(273, 89%, 60%)',
      ],
      purple: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(283, 64%, 81%)',
        0.67,
        'hsl(275, 84%, 69%)',
        1,
        'hsl(273, 90%, 51%)',
      ],
      red: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(29, 100%, 74%)',
        0.67,
        'hsl(11, 91%, 49%)',
        1,
        'hsl(0, 100%, 24%)',
      ],
      yellow: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(56, 77%, 83%)',
        0.67,
        'hsl(54, 63%, 64%)',
        1,
        'hsl(56, 100%, 38%)',
      ],
    };
    const defaultColor = 'gray';
    let heatmapColor = info.mapColor ? info.mapColor : defaultColor;

    if (! colorOptions[heatmapColor]) {
      heatmapColor = defaultColor;
    }
    map.current.map.addLayer(
      {
        id: info.layerName,
        type: 'heatmap',
        source: info.sourceName,
        minzoom: info.minZoom,
        maxzoom: info.maxZoom,
        visibility: 'visible',
        paint: {
          // increase weight as diameter breast height increases
          'heatmap-weight': {
            property: 'value',
            type: 'exponential',
            stops: [
              [1, 0],
              [info.max, 1],
            ],
          },
          // increase intensity as zoom level increases
          'heatmap-intensity': {
            stops: [
              [5, .5],
              [11, 2],
              [15, 3],
            ],
          },
          // assign color values be applied to points depending on their density
          'heatmap-color': colorOptions[heatmapColor],
          // increase radius as zoom increases
          'heatmap-radius': {
            stops: [
              [7, 15],
              [11, 20],
            ],
          },
          'heatmap-opacity': {
            default: .8,
            stops: [
              [14, .8],
              [15, .6],
            ],
          },
        },
      },
      'waterway-label',
    );
  };

  const addMarkerLayer = (info: LayerInfo) => {
    const colorOptions = ['blue', 'gray', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'];
    let markerColor = info.mapColor;

    if (info.mapColor && !colorOptions.includes(info.mapColor)) {
      markerColor = 'gray';
    }
    map.current.map.loadImage(
      `/assets/images/marker-icons/mapbox-marker-icon-20px-${markerColor}.png`,
      (error: any, image: any) => {
        if (error) throw error;
        map.current.map.addImage(`mapbox-marker-${markerColor}`, image);

        map.current.map.addLayer({
          id: info.layerName,
          type: 'symbol',
          source: info.sourceName,
          minzoom: info.minZoom,
          maxzoom: info.maxZoom,
          visibility: 'visible',
          layout: {
            'icon-image': `mapbox-marker-${markerColor}`,
            'icon-allow-overlap': true,
          },
        });
      });
  };

  const addMapLayers = () => {
    setLoadingMap(true);
    const toRemove: LayerInfo[] = [];

    audienceLayerNames.forEach((g) => {
      g.layers.forEach(l => toRemove.push(l));
    });

    if (toRemove.length > 0) {
      map.current.removeLayers(toRemove);
    }

    const audienceLayersGroup = [];
    const audiencePopup = new mapboxgl.Popup();

    if (mapData.audience) {
      for (const data of mapData.audience.data) {
        const audienceLayersInfo = [];

        const info: LayerInfo = {
          id: data.id,
          name: data.name,
          max: mapData.audience.metadata.max,
          sourceName: `audience-${data.id}`,
          layerName: `audience-layer-${data.id}`,
          isChecked: true,
          minZoom: 0,
          maxZoom: 23,
          isMapHeatmap: Number(data.isMapHeatmap),
          isMapLocationPin: Number(data.isMapLocationPin),
          mapColor: data.mapColor,
        };

        map.current.map.addSource(info.sourceName, {
          type: 'geojson',
          data: data.audienceJson,
        });
        // audiences
        if (info.isMapHeatmap) {
          addHeatmapLayer(info);
          audienceLayersInfo.push(info);
          map.current.map.on('click', info.layerName, (e: any) => {
            const description = `<strong>${e.features[0].properties.label}</strong>
                                    <br>Audience Total: ${e.features[0].properties.value}`;
            audiencePopup
              .setLngLat(e.lngLat)
              .setHTML(description)
              .addTo(map.current.map);
          });

          map.current.map.on('mouseenter', info.layerName, () => {
            map.current.map.getCanvas().style.cursor = 'pointer';
          });
          map.current.map.on('mouseleave', info.layerName, () => {
            map.current.map.getCanvas().style.cursor = '';
          });
        }
        if (info.isMapLocationPin) {
          const pinInfo = { ...info };
          pinInfo.layerName = `${pinInfo.layerName}-marker`;
          pinInfo.id = `${pinInfo.id}-marker`;
          addMarkerLayer(pinInfo);
          audienceLayersInfo.push(pinInfo);
          map.current.map.on('click', pinInfo.layerName, (e: any) => {
            const description = `<strong>${e.features[0].properties.label}</strong>
                                    <br>Audience Total: ${e.features[0].properties.value}`;
            audiencePopup
              .setLngLat(e.lngLat)
              .setHTML(description)
              .addTo(map.current.map);
          });

          map.current.map.on('mouseenter', pinInfo.layerName, () => {
            map.current.map.getCanvas().style.cursor = 'pointer';
          });
          map.current.map.on('mouseleave', pinInfo.layerName, () => {
            map.current.map.getCanvas().style.cursor = '';
          });
          map.current.map.moveLayer(pinInfo.layerName);
        }
        const audienceGroup: LayerGroup = {
          id: `${data.id}-group`,
          name: data.name,
          isChecked: true,
          mapColor: data.mapColor,
          layers: audienceLayersInfo,
        };
        audienceLayersGroup.push(audienceGroup);
      } // END audiences for loop
      setAudienceLayerNames(audienceLayersGroup);

    }

    setLoadingMap(false);
  };

  function getAudiencePerCapita() {
    setLoading(true);
    const params = {
      account_id: getAccountID(),
      year: query.year ? query.year : null,
      type: 'audience',
      isDesc: true,
    };

    const url = audienceId ? `/api/audience/percapita/${audienceId}` : '/api/audience/percapita';

    Axios.get(url, { params })
      .then((response) => {
        if (response.data.audiences.length > 0) {
          setStateAudiences(response.data.states);
          setStateNetAvg(response.data.avg.states.avgNetSales);
          setStateRoyaltyAvg(response.data.avg.states.avgRoyaltySales);
          response.data.audiences.sort((a, b) => b.value_total - a.value_total);
          setAudienceList(response.data.audiences);
          if (audienceId) {
            const aud = response.data.audiences.find(e => e.id === Number(audienceId));
            if (aud) {
              setSelectedAudience(aud);
            }
          } else {
            setSelectedAudience(null);
          }
          setCityAudiences(response.data.cities);
          setAudienceCount(response.data.total);
        }
        setLoading(false);
      });
  }

  function goToAudienceSettings() {
    const id = audienceId ? audienceId : '';
    if (user.type === 'admin') {
      if (routeParams['vendorId']) {
        history.push(`/vendors/${getAccountID()}/settings/audiences/${id}`);
      }
      if (routeParams['licensorId']) {
        history.push(`/clients/${getAccountID()}/settings/audiences/${id}`);
      }
    } else {
      history.push(`/settings/audiences${id}`);
    }
  }

  function toggleShowAllStates() {
    setShowAllStates(!showAllStates);
  }

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  const stateMsg = showAllStates ? 'View Less' : 'View More';
  const stateLimit = showAllStates ? 50 : 5;
  const showViewMore = stateAudiences.length > 5;

  const stateRows =
    (stateAudiences as any[]).map((audience, index) => {
      if (index < stateLimit) {
        return (
          <tr style={{ cursor: 'pointer' }} onClick={() => stateClick(audience.abbr)}>
            <td className="position-rank">{index + 1}.</td>
            <td><strong>{audience.name}</strong></td>
            <td style={{ textAlign: 'right' }}>{Number(audience.value_total).toLocaleString()}</td>
            <td style={{ textAlign: 'right' }}><span>{new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(audience.netSalesPerCapita)}</span></td>
            <td style={{ textAlign: 'right' }}><span>{new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(audience.royaltySalesPerCapita)}</span></td>
          </tr>
        );
      }
    });

  const cityRows =
    (cityAudiences as any[]).map((audience, index) => {
      if (index < 5) {
        return (
          <tr>
            <td className="position-rank">{index + 1}.</td>
            <td><strong>{audience.name}, {audience.abbr}</strong></td>
            <td style={{ textAlign: 'right' }}>{Number(audience.value_total).toLocaleString()}</td>
            <td style={{ textAlign: 'right' }}><span>{new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(audience.netSalesPerCapita)}</span></td>
            <td style={{ textAlign: 'right' }}><span>{new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(audience.royaltySalesPerCapita)}</span></td>
          </tr>
        );
      }
    });

  function audienceClick(id = null) {
    if (audienceId !== id) {
      setLoading(true);
      setAudienceId(id);
      if (user.type === 'admin') {
        if (routeParams['vendorId']) {
          id ? history.push(`/vendors/${getAccountID()}/audiences/${id}`) : history.push(`/vendors/${getAccountID()}/audiences`);
        }
        if (routeParams['licensorId']) {
          id ? history.push(`/clients/${getAccountID()}/audiences/${id}`) : history.push(`/clients/${getAccountID()}/audiences`);
        }
      } else {
        id ? history.push(`audiences/${id}`) : history.push('audiences');
      }
    }
  }

  function stateClick(stateAbbr) {
    const audienceURL = audienceId ? `${audienceId}/` : '';
    if (user.type === 'admin') {
      if (routeParams['vendorId']) {
        history.push(`/vendors/${getAccountID()}/audiences/${audienceURL}detail/?state=${stateAbbr}`);
      }
      if (routeParams['licensorId']) {
        history.push(`/clients/${getAccountID()}/audiences/${audienceURL}detail/?state=${stateAbbr}`);
      }
    } else {
      history.push(`/audiences/${audienceURL}detail/?state=${stateAbbr}`);
    }
  }

  function cityClick(id = null) {
    const audienceURL = audienceId ? `${audienceId}/` : '';
    if (user.type === 'admin') {
      if (routeParams['vendorId']) {
        history.push(`/vendors/${getAccountID()}/audiences/${audienceURL}detail`);
      }
      if (routeParams['licensorId']) {
        history.push(`/clients/${getAccountID()}/audiences/${audienceURL}detail`);
      }
    } else {
      history.push(`/audiences/${audienceURL}detail`);
    }
  }

  const audienceTitle = selectedAudience ? selectedAudience.name : 'Audience';
  const audienceDropdownTitle = selectedAudience ? selectedAudience.name : 'Dashboard';
  const audienceCountMsg = `The metrics below are based on location data for the ${Number(audienceCount).toLocaleString()} individuals in the selected audience and sales data for the selected year.`;

  if (loading) {
    return  (
      <LoadingSpinner/>
    );
  }

  const audienceOptions = audienceList.map(audience =>
    (<MenuItem key={`audience-${audience.id}`} eventKey={audience.id} value={audience.id} >{audience.name}</MenuItem>),
  );

  //  function filterYear(year: any) {
  //     setLoading(true);
  //     const qsParsed = queryString.parse(location.search);
  //     qsParsed.year = year;
  //     qsParsed.page = 1;
  //     history.push(`${location.pathname}?${queryString.stringify(qsParsed)}`);
  //     getAudiencePerCapita();
  //   }
  //
  // const years = [];
  // const date = new Date();
  // let fiscalYear;
  // if (date.getMonth() >= 9) {
  //   fiscalYear = date.getFullYear();
  // } else {
  //   fiscalYear = date.getFullYear() - 1;
  // }
  // const currentYearFilter = query['year'] || formattedFiscalYear(fiscalYear);
  //
  // for (let i = 0; i <= (fiscalYear - 2018); i++) {
  //   const yearToAdd = 2018 + i;
  //   const yearValue = formattedFiscalYear(yearToAdd);
  //
  //   years.push(<MenuItem key={yearValue} eventKey={yearValue} data-year={yearToAdd} >{yearValue}</MenuItem>);
  // }

  return (
    <FullContent breadcrumbs={[{ name: 'audiencesDashboard' }]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ maxWidth: '50%' }}>
          <h3><strong> { audienceTitle }</strong></h3>
          <p className="text-muted">Detailed audience, sales, and per capita performance reports</p>
          <div className="form-inline" style={{ marginBottom: 10 }}>
            <DropdownButton
              id="audience-dropdown"
              bsStyle={'default'}
              title={audienceDropdownTitle}
              onSelect={audienceClick}
            >
              <MenuItem key={'audience-all'} value="all" >Dashboard</MenuItem>
              {audienceOptions}
            </DropdownButton>
            {/*<DropdownButton*/}
            {/*  style={{ marginLeft: 10 }}*/}
            {/*  id="year-dropdown"*/}
            {/*  bsStyle={'default'}*/}
            {/*  title={currentYearFilter}*/}
            {/*  onSelect={filterYear}*/}
            {/*>*/}
            {/*  {years}*/}

            {/*</DropdownButton>*/}
          </div>
        </div>
        <button onClick={goToAudienceSettings} className="btn btn-default">Audience Settings</button>
      </div>
      <p className="text-muted" style={{ marginBottom: 10 }}>{ audienceCountMsg }</p>

      <div className="row">
        <div className="col-md-6">
          <div className="panel panel-portal">
            <div className="panel-body text-center">
              <h3><strong>{ stateNetAvg > 0 ? new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(stateNetAvg) : '—' }</strong></h3>
              <strong><FontAwesomeIcon className="fa-fw text-muted"  icon={faBadgeDollar}/> Avg Sales/Per</strong>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="panel panel-portal">
            <div className="panel-body text-center">
              <h3><strong>{ stateRoyaltyAvg > 0 ? new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(stateRoyaltyAvg) : '—' }</strong></h3>
              <strong><FontAwesomeIcon className="fa-fw text-muted" icon={faSackDollar}/> Avg Royalties/Per</strong>
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-portal">
        <div className="panel-body text-center">
          <AffinityMap
            ref={map}
            height={300}
            width="100%"
            isFullScreen={false}
            loading={loadingMap}
            onMapLoad={getAudienceMap}
            fullParams={{ accountId: getAccountID(), withAudience: true, audienceId: audienceId ? audienceId : undefined }}
            useDefaultCenter={true}
          />
        </div>
      </div>
      <div className="row">
        {
          stateAudiences.length > 0 &&
          <div className="col-md-6">
            <div className="panel panel-portal">
              <div className="panel-body">
                <h4><strong>State Per Capita</strong></h4>
                <div className="table-responsive chart-item-list">
                  <table className="table table-hover">
                    <thead style={{ textTransform: 'capitalize' }}>
                    <tr>
                      <th></th>
                      <th>State</th>
                      <th style={{ textAlign: 'right' }}><FontAwesomeIcon className="fa-fw text-muted" icon={faHashtag}/> Audience</th>
                      <th style={{ textAlign: 'right' }}><FontAwesomeIcon className="fa-fw text-muted" icon={faBadgeDollar}/> Sales/Per</th>
                      <th style={{ textAlign: 'right' }}><FontAwesomeIcon className="fa-fw text-muted" icon={faSackDollar}/> Royalties/Per</th>
                    </tr>
                    </thead>
                    <tbody>
                    {stateRows}
                    </tbody>
                  </table>
                  { showViewMore && <div style={{ cursor: 'pointer' }} onClick={toggleShowAllStates}><p className="text-primary text-right"><strong>{stateMsg}</strong></p></div>}
                </div>
              </div>
            </div>
          </div>
        }
        {cityAudiences.length > 0 &&
          <div className="col-md-6">
            <div className="panel panel-portal">
              <div className="panel-body">
                <h4><strong>City Per Capita</strong></h4>
                <div className="table-responsive chart-item-list">
                  <table className="table table-hover">
                    <thead style={{ textTransform: 'capitalize' }}>
                    <tr>
                      <th></th>
                      <th>City</th>
                      <th style={{ textAlign: 'right' }}><FontAwesomeIcon className="fa-fw text-muted"
                                                                        icon={faHashtag}/> Audience
                      </th>
                      <th style={{ textAlign: 'right' }}><FontAwesomeIcon className="fa-fw text-muted"
                                                                        icon={faBadgeDollar}/> Sales/Per
                      </th>
                      <th style={{ textAlign: 'right' }}><FontAwesomeIcon className="fa-fw text-muted"
                                                                        icon={faSackDollar}/> Royalties/Per
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {cityRows}
                    </tbody>
                  </table>
                  <div style={{ cursor: 'pointer' }} onClick={() => cityClick()}><p className="text-primary text-right">
                    <strong>View More</strong></p></div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

    </FullContent>

  );

};

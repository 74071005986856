import * as moment from 'moment';
import { AffinityClient } from './AffinityClient';
import { File } from './File';
import { Invoice } from './Invoice';
import { RoyaltyReportAction } from './RoyaltyReportAction';
import User from './User';

export class RoyaltyReport {
  id: number;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  dueDate: moment.Moment;
  submittedAt: moment.Moment | null;
  submittedBy: User | null;
  invoiceId: number | null;
  year: number;
  quarter: number;
  totalSales: number;
  totalDue: number;
  feeTotal: number;
  vendor: any;
  phase: any;
  adminUser: User | null;
  salesDataLicensors: AffinityClient[];
  aggregateLicensors: AffinityClient[];
  salesDataFile: File | null;
  firstSalesDataFile: File | null;
  complianceFile: File | null;
  oldFiles: File[];
  invoice: Invoice | null;
  salesDataIncompleteCount: number | null;
  actions: RoyaltyReportAction[];
  salesFileProcessed: boolean;
  totalSalesDataLineItems: number = 0;
  totalAggregateLineItems: number = 0;
  salesFileRowCount: number = 0;
  firstSalesFileRowCount: number = 0;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.createdAt = moment(data.created_at);
      this.updatedAt = moment(data.updated_at);
      this.dueDate = moment(data.due_date);
      this.submittedAt = data.submitted_at ? moment(data.submitted_at) : null;
      this.submittedBy = data.submitted_by ? new User(data.submitted_by) : null;
      this.invoiceId = data.invoice_id ? Number(data.invoice_id) : null;
      this.year = Number(data.year);
      this.quarter = Number(data.quarter);
      this.totalSales = data.total_sales;
      this.totalDue = data.total_due;
      this.vendor = data.vendor;
      this.phase = data.phase;
      this.adminUser = data.admin_user ? new User(data.admin_user) : null;
      this.feeTotal = data.fee_total ? data.fee_total : 0.00;
      this.salesFileRowCount = data.sales_file_row_count;
      this.firstSalesFileRowCount = data.first_sales_file_row_count;
      if (data.old_files) {
        this.oldFiles = data.old_files.map((fileData: any) => new File(fileData));
      } else {
        this.oldFiles = [];
      }

      if (data.sales_data_file) {
        this.salesDataFile = new File(data.sales_data_file);
      } else {
        this.salesDataFile = null;
      }
      if (data.first_sales_data_file) {
        this.firstSalesDataFile = new File(data.first_sales_data_file);
      } else {
        this.firstSalesDataFile = null;
      }

      if (data.compliance_file) {
        this.complianceFile = new File(data.compliance_file);
      } else {
        this.complianceFile = null;
      }

      this.salesDataIncompleteCount = data.sales_data_incomplete !== null ? Number(data.sales_data_incomplete) : null;

      if (data.invoice) {
        this.invoice = new Invoice(data.invoice);
      } else {
        this.invoice = null;
      }

      if (data.actions) {
        this.actions = data.actions.map((item: any) => new RoyaltyReportAction(item));
      } else {
        this.actions = [];
      }

      const mapLicensors = (licensor: any) => (
        new AffinityClient({ id: licensor.account_id, short_name: licensor.licensor_name })
      );

      if (data.report_licensors) {
        this.salesDataLicensors = data.report_licensors
          .filter((licensor: any) => licensor.sales_data_reporting)
          .map(mapLicensors);
        this.aggregateLicensors = data.report_licensors
          .filter((licensor: any) => !licensor.sales_data_reporting)
          .map(mapLicensors);
      } else {
        this.salesDataLicensors = [];
        this.aggregateLicensors = [];
      }

      this.salesFileProcessed = data.sales_file_processed;
      if (data.line_item_counts) {
        this.totalSalesDataLineItems = data.line_item_counts.sales_data;
        this.totalAggregateLineItems = data.line_item_counts.aggregate;
      }
    }
  }

  get statusLabelClass() {
    switch (this.phase.id) {
      case 1:
        return 'label-danger';
      case 3:
        return 'label-info';
      case 14:
        return 'label-success';
      default:
        return 'label-default';
    }
  }

  get statusLabel() {
    switch (this.phase.id) {
      case 1:
        return 'Not Submitted';
      case 3:
        return 'Pending Review';
      case 14:
        return 'Reviewed';
      default:
        return 'Unknown';
    }
  }

  get isFileQueued() {
    return this.salesDataFile != null && !this.salesFileProcessed;
  }

  get firstSalesFileLabel() {
    if (this.firstSalesFileRowCount > this.salesFileRowCount) {
      return `${this.firstSalesFileRowCount - this.salesFileRowCount} rows removed`;

    }  if (this.firstSalesFileRowCount < this.salesFileRowCount) {
      return `${this.salesFileRowCount - this.firstSalesFileRowCount} rows added`;
    }
    return 'Matching row count';
  }

}

export class RoyaltyReportIssue {
  id: number;
  name: string;
  description: string;
  warning: boolean;
  required: boolean;
  adminAudit: boolean;

  static fromApi(data?: any) {
    const r = new RoyaltyReportIssue();
    r.id = data.id;
    r.name = data.name;
    r.description = data.description;
    r.warning = data.warning;
    r.required = data.required;
    r.adminAudit = data.admin_audit;
    return r;
  }

}

export class ReportProcessingStatus {
  processed: boolean = false;
  processing: boolean = false;
  totalRows: number = 0;
  totalLineItems: number = 0;
  feeTotal: number = 0;
  processingDataCount: number = 0;
  processingPercent: number = 0;
  errorCount: number = 0;
  errorPercent: number = 0;
  warningCount: number = 0;
  warningPercent: number = 0;
  feeProcessingPercent: number = 0;
  lineItemPercent: number = 0;
  issueCounts: { issue: RoyaltyReportIssue, count: number, fee: number }[] = [];
  unmappedCategoryCount: number = 0;
  loading: boolean = true;
  processingCSV = '';

  static fromApi(data?: any) {
    const r = new ReportProcessingStatus();
    r.processed = data.processed;
    r.processing = data.processing;
    r.totalRows = data.total_rows;
    r.totalLineItems = data.total_line_items;
    r.processingDataCount = data.processing_data_count;
    r.processingPercent = data.processing_percent;
    r.errorCount = data.error_count;
    r.errorPercent = data.error_percent;
    r.warningCount = data.warning_count;
    r.warningPercent = data.warning_percent;
    r.feeProcessingPercent = data.fee_processing_percent;
    r.lineItemPercent = data.line_item_percent;
    if (data.issues) {
      r.issueCounts = data.issues.map((i: any) => {
        return { issue: RoyaltyReportIssue.fromApi(i.type), count: i.count, fee: Number(i.fee) };
      });
    }
    r.loading = false;
    r.feeTotal = data.fee_total;
    r.unmappedCategoryCount = data.unmapped_category_count;
    r.processingCSV = data.processing_csv;
    return r;
  }

  get warningIssues() {
    return this.issueCounts.filter(i => i.issue.warning);
  }

  get warningIssuesCount() {
    return this.warningIssues.reduce((p, n) => p + n.count, 0);
  }

  get rejectedDesignIssues() {
    return this.warningIssues.filter(i => i.issue.id === 27);
  }

  get rejectedDesignIssuesFee() {
    return this.rejectedDesignIssues.reduce((p, n) => p + n.fee, 0.00);
  }

  get doubleRoyaltyIssues() {
    return this.warningIssues.filter(i => i.issue.id !== 27);
  }
  get doubleRoyaltyIssuesFee() {
    return this.doubleRoyaltyIssues.reduce((p, n) => p + n.fee, 0.00);
  }

  get requiredIssues() {
    return this.issueCounts.filter(i => i.issue.required);
  }
  get requiredIssuesCount() {
    return this.requiredIssues.reduce((p, n) => p + n.count, 0);
  }

  get adminAudits() {
    return this.issueCounts.filter(i => i.issue.adminAudit);
  }

  get currentPercent() {
    // Uses 4 as 'Uploading file %'
    return this.processingPercent + this.feeProcessingPercent + this.lineItemPercent + 4;
  }

  get currentStatus() {
    if (this.processingPercent === 0 && !this.processing) {
      return 'Uploading file...';
    }
    if (this.processingPercent === 0 && this.processing) {
      return 'Report Queued...';
    }
    if (this.processingPercent < 45) {
      return 'Reviewing Data...';
    }
    if (this.lineItemPercent + this.feeProcessingPercent < 50) {
      return 'Processing Data...';
    }
    if (this.currentPercent > 95) {
      return 'Almost Done...';
    }
    return '';
  }

  get userAction() {
    if (this.totalRows === 0 || (this.totalRows > 0 && this.totalRows <= 150)) {
      return 'Please wait on this page.';
    }
    return 'You will receive an email after your data has been uploaded.';
  }
}

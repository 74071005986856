import { faCircle, faRadar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { WebBotUrl } from '../../../shared/WebBotUrl';
import { WebBotUrlFilters } from '../WebBotUrlFilters';

interface IProps {
  listing: WebBotUrl;
  navOverride?: string;
}

export const WebBotListingItem = (props: IProps) => {
  const { listing, navOverride } = props;

  const navlink = () => {
    if (navOverride) {
      return navOverride;
    }
    const filters = WebBotUrlFilters.getInitialFilters(location.search);
    return `content/${listing.id}?${filters.generateQueryParamString()}`;
  };

  return (
    <div className="panel panel-portal">
      <div className="panel-body">
        <Link to={navlink()} className="web-bot-listing-item">
          <div className={`wb-status ${listing.statusColor}`}>
            <FontAwesomeIcon icon={faCircle} /> <strong>{listing.status.name}</strong>

          </div>
          <div className="wb-image-brand">
            {listing.correctImage('sm') ? <img className="img-responsive" src={listing.correctImage('sm')} /> :
              <div className="wb-image-missing">
                <FontAwesomeIcon icon={faRadar} />
              </div>
            }
            <div style={{ backgroundColor: listing.type.color }} className="platform-logo">
              <i className={listing.type.icon} />
            </div>
          </div>
          <div className="wb-seller">
            <img src={listing.seller.image ? listing.seller.image.getSize('th') : ''} className="wb-seller-logo" />
            <div className="wb-seller-info">
              <div className="wb-seller-name small"><strong>{listing.seller.platformUsername}</strong></div>
              <div className="wb-seller-status small">
              </div>
            </div>
          </div>
          <p className="wb-item-desc">
            {listing.name}
          </p>
        </Link>

      </div>

    </div>

  );
};

import * as moment from 'moment';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { TrademarkCase } from '../../../shared/TrademarkCase';
import User from '../../../shared/User';
import { GatewayModal, ModalType } from '../../shared';

type CaseModalForm = {
  owner: number | null;
  autoClose: 1 | 0;
  initialContact: string;
};

export const CaseModal = (props: { shown: boolean, onClose: () => void, enforcementCase: TrademarkCase, onUpdate: (c: TrademarkCase) => void}) => {
  const { shown, onClose, enforcementCase, onUpdate } = props;
  const [adminUsers, setAdminUsers] = React.useState<User[]>([]);
  const [updating, setUpdating] = React.useState(false);
  const form = useForm<CaseModalForm>({ defaultValues: {
    owner: enforcementCase.owner ? Number(enforcementCase.owner.id) : null,
    autoClose: enforcementCase.canAutoClose ? 1 : 0,
    initialContact: enforcementCase.initialContact ? enforcementCase.initialContact.format('YYYY-MM-DD') : '',
  }});

  React.useEffect(() => {
    getAdminUsers();

  },              []);

  const getAdminUsers = async () => {
    const res = await fetch('/api/users?account_id=1');
    const data = await res.json();
    setAdminUsers(data.data.map((u: any) => User.fromApi(u)));
    form.reset();
  };

  const updateCase = async (values: CaseModalForm) => {
    setUpdating(true);
    const params = {
      owner: values.owner,
      can_auto_close: values.autoClose,
      initial_contact: values.initialContact ? moment(values.initialContact).format('YYYY-MM-DD') : null,
    };
    const res = await fetch(`/api/trademark-cases/${enforcementCase.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const data = await res.json();
    onUpdate(TrademarkCase.fromApi(data.data));
    onClose();
    setUpdating(false);
  };

  return (
    <GatewayModal
      shown={shown}
      type={ModalType.Primary}
      title="Update Case"
      onClose={onClose}
    >
      <form onSubmit={form.handleSubmit(updateCase)}>
        <Modal.Body>
          <div className="form-group">
            <label>Owner</label>
            <select {...form.register('owner')} className="form-control" >
              <option disabled>Select Owner</option>
              {adminUsers.map(u => <option key={u.id} value={Number(u.id)}>{u.fullName}</option>)}
            </select>
          </div>
          <div className="form-group">
            <label>Initial Contact</label>
            <input {...form.register('initialContact')} className="form-control" type="date" />
          </div>
          <div className="form-group">
            <label>Auto-Close</label>
            <select {...form.register('autoClose')} className="form-control">
              <option value={1}>Enabled</option>
              <option value={0}>Disabled</option>
            </select>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={onClose} className="btn btn-default pull-left">Cancel</button>

          <button type="submit" className="btn btn-primary pull-right">{updating  ? 'Updating...' : 'Update Case'}</button>
        </Modal.Footer>
      </form>
    </GatewayModal>
  );

};

type CaseCloseModalForm = {
  note: '';
};

export const CloseCaseModal = (props: { shown: boolean, onClose: () => void, enforcementCase: TrademarkCase, onUpdate: (c: TrademarkCase) => void}) => {
  const { shown, onClose, enforcementCase, onUpdate } = props;
  const [updating, setUpdating] = React.useState(false);
  const form = useForm<CaseCloseModalForm>({ defaultValues: {
    note: '',
  }});

  const updateCase = async (values: CaseCloseModalForm) => {
    setUpdating(true);
    const params = {
      action: 'close',
      note: values.note,
    };
    const res = await fetch(`/api/trademark-cases/${enforcementCase.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const data = await res.json();
    onUpdate(TrademarkCase.fromApi(data.data));
    onClose();
    setUpdating(false);
  };

  return (
    <GatewayModal
      shown={shown}
      type={ModalType.Primary}
      title="Close Case"
      onClose={onClose}
    >
      <form onSubmit={form.handleSubmit(updateCase)}>
        <Modal.Body>
          <div className="form-group">
            <label>Note</label>
            <textarea className="form-control" {...form.register('note', { required: true })} > </textarea>
            {form.formState.errors.note && <p className="text-danger small">Note is required.</p>}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={onClose} className="btn btn-default pull-left">Cancel</button>
          <button type="submit" className="btn btn-primary pull-right">{updating  ? 'Closing...' : 'Close Case'}</button>
        </Modal.Footer>
      </form>
    </GatewayModal>
  );
};

import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { EnforcementTemplateForm, EnforcmentTemplateFormType } from './EnforcementTemplateForm';
2;
export const EnforcementTemplateNewPage = () => {
  const history = useHistory();
  const form = useForm<EnforcmentTemplateFormType>({
    defaultValues : {
      name: '',
      description: '',
      content: '',
      platform: 0,
      dmcaType: 0,
      subject: '',
    },
  });

  const submit = async (values: EnforcmentTemplateFormType) => {
    const data = {
      name: values.name,
      description: values.description,
      content: values.content,
      platform_id: values.platform,
      dmca_type: values.dmcaType,
      subject: values.subject,
    };
    await fetch('/api/enforcement-templates', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    history.push('/enforce/templates');

  };

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Templates')} />
      <div className="panel panel-portal">
        <div className="panel-body">
          <form onSubmit={form.handleSubmit(submit)}>
            <EnforcementTemplateForm {... form} />
            <button className="btn btn-primary pull-right">Create Template</button>
          </form>
        </div>
      </div>
    </FullContent>
  );
};

import * as React from 'react';
import { useParams } from 'react-router';
import { WebBotUrl } from '../../../shared/WebBotUrl';
import { WebBotListingItem } from './WebBotListingItem';

interface IProps {
  urls: WebBotUrl[];

}

export const EnforcementDashboardRecentlyRemoved = (props: IProps) => {
  const { licensorId, vendorId } = useParams();
  const { urls } = props;

  const link = licensorId ? `/clients/${licensorId}/enforce/content` : `/vendors/${vendorId}/enforce/content`;

  if (urls.length) {
    return (
      <div>
      <h5 className="text-muted">Recently Removed</h5>
        <div className="recently-removed">
          {urls.map(r =>
            <WebBotListingItem navOverride={`${link}/${r.id}?status=2`} key={r.id} listing={r} />,
          )}
        </div>
      </div>
    );

  }

  return <></>;

};

import { orderBy } from 'lodash';
import * as moment from 'moment';
import { AffinityImage, ImageApiResponse } from './Image';
import { ProductCategory } from './ProductCategory';
import { Trademark, TrademarkApiResponse } from './Trademark';
import User, { UserApiResponse } from './User';
import { Vendor, VendorAPIResponse } from './Vendor';

export interface InsigniaApiResponse {
  id: number;
  title: string;
  client_id: number;
  description: string;
  image: ImageApiResponse;
  parent?: InsigniaApiResponse;
  children?: InsigniaApiResponse[];
  is_parent: boolean;
  is_vendor_specific: boolean;
  deleted_at: string;
  trademarks?: TrademarkApiResponse[];
  design_review_users?: UserApiResponse[];
  vendors: VendorAPIResponse[];
  enabled_for_designs: boolean;
  enabled_for_royalties: boolean;
  brand_code: string;
  order: number;
  rules: any[];
  vendor_ids: number[];
}
export class Insignia {
  id: number;
  title: string = '';
  originalTitle: string;
  code: string;
  clientId: string | null;
  isDeleted: boolean;
  description: string;
  isParent: boolean;
  isVendorSpecific: boolean;
  parent: Insignia | undefined;
  children: Insignia[] = [];
  image: AffinityImage;
  deletedAt: moment.Moment;
  trademarks: Trademark[] = [];
  designReviewUsers: User[] = [];
  vendors: Vendor[] = [];
  enabledForDesigns: boolean;
  royalties: boolean;
  order: number;
  label = '';
  rules: any[] = [];
  vendorIds: number[] = [];

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.title = data.title;
      this.isDeleted = !!data.deleted_at;
      if (data.client_id) {
        this.clientId = String(data.client_id);
      } else {
        this.clientId = null;
      }
    }
  }

  static listFromApi(data: InsigniaApiResponse[]): Insignia[] {
    const parentIds = data.reduce((acc: number[], current: InsigniaApiResponse) => {
      if (current.parent && !acc.includes(current.parent.id)) {
        return acc.concat(Number(current.parent.id));
      }
      return acc;
    },                            []);

    return data.map(item => this.fromApi(item, parentIds));
  }

  static fromApi(data: InsigniaApiResponse, parentIds?: number[]) {
    const insignia = new Insignia();

    insignia.id = data.id;
    insignia.title = data.title;
    insignia.label = data.title;
    insignia.originalTitle = data.title;
    insignia.code = data.brand_code;
    insignia.enabledForDesigns = data.enabled_for_designs;
    insignia.royalties = data.enabled_for_royalties;
    insignia.order = data.order;
    if (data.client_id) {
      insignia.clientId = String(data.client_id);
    }
    insignia.description = data.description;
    insignia.isParent = parentIds ? parentIds.includes(Number(data.id)) : data.is_parent;
    if (data.image) {
      insignia.image = AffinityImage.fromApi(data.image);
    } else {
      insignia.image = new AffinityImage();
    }
    if (data.parent) {
      insignia.parent = Insignia.fromApi(data.parent);
    }
    if (data.children) {
      insignia.children = orderBy(data.children.map(i => Insignia.fromApi(i)), 'order', 'asc');
    }

    if (data.deleted_at) {
      insignia.deletedAt = moment(data.deleted_at);
      insignia.isDeleted = true;
    } else {
      insignia.isDeleted = false;
    }
    if (data.trademarks) {
      insignia.trademarks = data.trademarks.map(t => Trademark.fromApi(t));
    }
    if (data.design_review_users) {
      insignia.designReviewUsers = data.design_review_users.map(u => User.fromApi(u));
    }
    insignia.isVendorSpecific = data.is_vendor_specific;
    if (data.vendors) {
      insignia.vendors = data.vendors.map(v => Vendor.fromApi(v));
    }
    if (data.rules) {
      insignia.rules = data.rules;
    }
    if (data.vendor_ids) {
      insignia.vendorIds = data.vendor_ids;
    }

    return insignia;
  }

  static createDescendantTree(parent: Insignia, potentialChildren: Insignia[]) {
    let withDescendants: Insignia[] = [parent];

    potentialChildren.forEach((i) => {
      if (i.parent && i.parent.id === parent.id) {
        withDescendants.push(i);
        if (i.children.length) {
          withDescendants = withDescendants.concat(Insignia.createDescendantTree(i, i.children));
        }
      }
    });

    return withDescendants;

  }

  static createAncestorTree(child: Insignia, potentialParents: Insignia[], includeSelf = true) {
    let withParents: Insignia[] = [];
    if (includeSelf) {
      withParents = [child];
    }

    if (child.parent) {
      potentialParents.forEach((i) => {
        if (child.parent && i.id === child.parent.id) {

          withParents = withParents.concat(Insignia.createAncestorTree(i, potentialParents, true));
        }
      });
    }
    return withParents;

  }

  static filterTopLevel(insignia: Insignia[]) {
    return insignia.filter(i => !i.parent);
  }

  static parentHasRules(ins: Insignia, list: Insignia[]): boolean {
    // find parent with rule information
    const p = list.find((i) => {
      if (ins.parent) {
        return i.id === ins.parent.id;
      }
      return false;
    });
    if (p) {
      return ins.hasRules() || Insignia.parentHasRules(p, list);
    }
    return ins.hasRules();
  }

  isVendorAllowed(vendor: Vendor): boolean {
    let isParentAllowed: boolean = true;
    if (this.parent) {
      isParentAllowed = this.parent.isVendorAllowed(vendor);
    }
    if (this.isVendorSpecific) {
      return isParentAllowed && this.vendorIds.includes(Number(vendor.id));
    }
    return isParentAllowed && true;
  }

  hasRules(): boolean {
    return this.rules.length !== 0;
  }

  hasApplicableRules(categories: ProductCategory[]) {
    if (categories.length && this.hasRules()) {
      const catIds = categories.map(c => Number(c.id));

      const ruleCatIds = this.rules.map((r) => {
        if (r.product_category) {
          return Number(r.product_category.id);
        }
        return undefined;
      });

      const foundCat = catIds.reduce((p, n) => {
        if (p) {
          return p;
        }
        return ruleCatIds.includes(n);
      },                             false);

      return foundCat;
    }

    return this.hasRules();

  }

}

import { faBadgeDollar, faCircle, faDownload, faHashtag, faSackDollar, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as queryString from 'query-string';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { UserContext } from '../../contexts';
import { Filters } from '../../shared';
import { FullContent } from '../ContentFrame';
import { LoadingSpinner, PaginationFooter } from '../shared';

export const AudiencePerCapitaDetailPage = () => {
  const [loading, setLoading] = useState(true);
  const [cityAudiences, setCityAudiences] = useState([]);
  const [stateNetAvg, setStateNetAvg] = useState(0);
  const [stateRoyaltyAvg, setStateRoyaltyAvg] = useState(0);
  const routeParams = useParams();
  const [audienceId, setAudienceId] = useState(routeParams['id'] ? routeParams['id'] : null);
  const history = useHistory();
  const location = useLocation();
  let query = queryString.parse(location.search);
  const user = useContext(UserContext);
  const defaultSort = 'audience-desc';
  const selectedSort = (query.type && query.sort) ? `${query.type}-${query.sort}` : defaultSort;
  const [selectedStateAbbr, setSelectedStateAbbr] = useState(query.state ? query.state : null);
  const [selectedAudienceName, setSelectedAudienceName] = useState(null);
  const [lastPage, setLastPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = React.useState(new Filters());
  filters.setQueryParamsFromLocation(location.search);

  useEffect(() => {
    query = queryString.parse(location.search);
    getAudiencePerCapita(query.type, query.sort ? query.sort === 'desc' : true);
  },        [location.search, audienceId, selectedStateAbbr, routeParams['id']]);

  function getAudiencePerCapita(type = 'audience', isDesc = true) {
    setLoading(true);
    const params = {
      type,
      isDesc,
      account_id: getAccountID(),
      state: selectedStateAbbr,
      year: query.year ? query.year : null,
      page: query.page ? query.page : 1,
      limit: 24,
    };

    const url = routeParams['id'] ? `/api/audience/percapita/${routeParams['id']}` : '/api/audience/percapita';

    Axios.get(url, { params })
      .then((response) => {
        setStateNetAvg(response.data.avg.states.avgNetSales);
        setStateRoyaltyAvg(response.data.avg.states.avgRoyaltySales);
        setSelectedAudienceName(null);
        if (routeParams['id']) {
          const selectedAudience = response.data.audiences.find(e => e.id === Number(routeParams['id']));
          if (selectedAudience) {
            setSelectedAudienceName(selectedAudience.name);
          }
        }
        setSelectedStateAbbr(query.state ? query.state : null);
        setCityAudiences(response.data.cities);
        setTotalResults(response.data.meta.pagination.total_count);
        setLastPage(response.data.meta.pagination.page_count);
        setPage(response.data.meta.pagination.page);
        setLoading(false);
      });
  }

  function updatePage(newPage: number) {
    setPage(newPage);
    const baseUrl = location.pathname;
    const qsParsed = queryString.parse(location.search);
    qsParsed.page = newPage;

    history.replace(`${baseUrl}?${queryString.stringify(qsParsed)}`);
  }

  function changeCitySort(e) {
    const selectedParts = e.target.value.split('-');
    const type = selectedParts[0] ? selectedParts[0] : null;
    const isDesc = selectedParts[1] === 'desc';
    setLoading(true);
    const qsParsed = queryString.parse(location.search);
    qsParsed.type = type;
    qsParsed.sort = isDesc ? 'desc' : 'asc';
    qsParsed.page = 1;
    setPage(1);
    history.push(`${location.pathname}?${queryString.stringify(qsParsed)}`);
  }

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  const cityRows =
    (cityAudiences as any[]).map((audience, index) => {
      return (
        <tr>
          <td className="position-rank">{ (index + 1) + (page - 1) * 24 }.</td>
          <td><strong>{audience.name}, {audience.abbr}</strong></td>
          <td style={{ whiteSpace: 'pre-line' }}>{audience.labels || ''}</td>
          <td>{new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(audience.netSales)}</td>
          <td>{new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(audience.royaltySales)}</td>
          <td>{Number(audience.value_total).toLocaleString()}</td>
          <td>{new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(audience.netSalesPerCapita)}</td>
          <td>{new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(audience.royaltySalesPerCapita)}</td>
          <td>
            {(Number(audience.rating) > 0) && <strong style={{ color: 'green' }}><FontAwesomeIcon icon={faCircle} className="fa-xs" /> Above Avg</strong>}
            {(Number(audience.rating) === 0) && <strong style={{ color:'orange' }}><FontAwesomeIcon icon={faCircle} className="fa-xs" /> Average</strong>}
            {(Number(audience.rating) < 0) && <strong style={{ color: 'red' }}><FontAwesomeIcon icon={faCircle} className="fa-xs" /> Below Avg</strong>}
          </td>
        </tr>
      );
    });

  // const years = [];
  // const date = new Date();
  // let fiscalYear;
  // if (date.getMonth() >= 9) {
  //   fiscalYear = date.getFullYear();
  // } else {
  //   fiscalYear = date.getFullYear() - 1;
  // }
  // const currentYearFilter = query['year'] || formattedFiscalYear(fiscalYear);
  //
  // for (let i = 0; i <= (fiscalYear - 2018); i++) {
  //   const yearToAdd = 2018 + i;
  //   const yearValue = formattedFiscalYear(yearToAdd);
  //
  //   years.push(<MenuItem key={yearValue} eventKey={yearValue} data-year={yearToAdd} >{yearValue}</MenuItem>);
  // }
  //
  // function filterYear(year: any) {
  //   setLoading(true);
  //   const qsParsed = queryString.parse(location.search);
  //   qsParsed.year = year;
  //   qsParsed.page = 1;
  //   history.push(`${location.pathname}?${queryString.stringify(qsParsed)}`);
  //   // getAudiencePerCapita();
  // }

  function removeStateFilter() {
    setLoading(true);
    setSelectedStateAbbr(null);
    const qsParsed = queryString.parse(location.search);
    if (qsParsed.page) {
      qsParsed.page = 1;
    }
    delete qsParsed.state;
    history.push(`${location.pathname}?${queryString.stringify(qsParsed)}`);
    // getAudiencePerCapita();
  }

  function removeAudienceFilter() {
    setLoading(true);
    setAudienceId(null);
    setSelectedAudienceName(null);
    const qsParsed = queryString.parse(location.search);
    if (qsParsed.page) {
      qsParsed.page = 1;
    }
    if (user.type === 'admin') {
      if (routeParams['vendorId']) {
        history.push(`/vendors/${getAccountID()}/audiences/detail?${queryString.stringify(qsParsed)}`);
      }
      if (routeParams['licensorId']) {
        history.push(`/clients/${getAccountID()}/audiences/detail?${queryString.stringify(qsParsed)}`);
      }
    } else {
      history.push(`audiences/detail?${queryString.stringify(qsParsed)}`);
    }
  }

  if (loading) {
    return  (
      <LoadingSpinner/>
    );
  }

  return (
    <FullContent breadcrumbs={[{ name: 'audiencesDashboardDetail' }]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ maxWidth: '50%' }}>
          {/*<h3><strong>{ audienceTitle}</strong></h3>*/}
        </div>
      </div>

      <div className="panel panel-portal">
        <div className="panel-body">
          <div className="row">
            <div className="col-md-6">
              <h4><strong>City Per Capita</strong></h4>
              {/*<DropdownButton*/}
              {/*  // style={{ marginLeft: 10 }}*/}
              {/*  id="year-dropdown"*/}
              {/*  bsStyle={'default'}*/}
              {/*  title={currentYearFilter}*/}
              {/*  onSelect={filterYear}*/}
              {/*>*/}
              {/*  {years}*/}

              {/*</DropdownButton>*/}
              { selectedStateAbbr && <button
                style={{ marginLeft: 10 }}
                className="btn btn-primary"
                onClick={() => removeStateFilter()}
              >
                <FontAwesomeIcon icon={faTimes} /> &nbsp;{ selectedStateAbbr }
              </button>
              }
              { selectedAudienceName && <button
                style={{ marginLeft: 10 }}
                className="btn btn-primary"
                onClick={() => removeAudienceFilter()}
              >
                <FontAwesomeIcon icon={faTimes} /> &nbsp;{ selectedAudienceName }
              </button>
              }
            </div>
            <div className="col-md-6 text-right">
              <a
                className="btn btn-default"
                href={`/api/audience/percapita${routeParams['id'] ? `/${routeParams['id']}` : ''}/export${location.search}${user.type === 'admin' && getAccountID() ? `${location.search.includes('?') ? '&' : '?'}account_id=${getAccountID()}` : ''}`}
                target="_blank"
              >
                <FontAwesomeIcon icon={faDownload} className="text-primary" style={{ marginLeft: 0 }} />Export
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="panel panel-portal">
            <div className="panel-body text-center">
              <h3><strong>{ stateNetAvg > 0 ? new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(stateNetAvg) : '—'}</strong></h3>
              <strong><FontAwesomeIcon className="fa-fw text-muted"  icon={faBadgeDollar}/> Avg Sales/Per</strong>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="panel panel-portal">
            <div className="panel-body text-center">
              <h3><strong>{ stateRoyaltyAvg > 0 ? Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(stateRoyaltyAvg) : '—'}</strong></h3>
              <strong><FontAwesomeIcon className="fa-fw text-muted" icon={faSackDollar}/> Avg Royalties/Per</strong>
            </div>
          </div>
        </div>
      </div>

      <div className="panel panel-portal">
        <div className="panel-body">
          <div className="row">
            <div className="pull-right" style={{ marginRight: 10 }}>
              <select
                className="form-control"
                name="citySort"
                onChange={event => changeCitySort(event)}
                value={selectedSort}
              >
                <option key="audienceDesc" value="audience-desc">Sort Audience Largest</option>
                <option key="audienceAsc"  value="audience-asc">Sort Audience Smallest</option>
                <option key="salesDesc"  value="sales-desc">Sort Sales High to Low</option>
                <option key="salesAsc"  value="sales-asc">Sort Sales Low to High</option>
                <option key="ratingDesc"  value="rating-desc">Sort Rating High to Low</option>
                <option key="ratingAsc"  value="rating-asc">Sort Rating Low to High</option>
              </select>
            </div>
          </div>
          <div className="table-responsive chart-item-list">
            <table className="table table-hover">
              <thead style={{ textTransform: 'capitalize' }}>
              <tr>
                <th></th>
                <th>City</th>
                <th>Label</th>
                <th>Sales</th>
                <th>Royalties</th>
                <th><FontAwesomeIcon className="fa-fw text-muted" icon={faHashtag}/> Audience</th>
                <th><FontAwesomeIcon className="fa-fw text-muted" icon={faBadgeDollar}/> Sales/Per</th>
                <th><FontAwesomeIcon className="fa-fw text-muted" icon={faSackDollar}/> Royalties/Per</th>
                <th>Rating</th>
              </tr>
              </thead>
              <tbody>
              {cityRows}
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <PaginationFooter
        setPage={updatePage}
        currentPage={page}
        totalResults={totalResults}
        hasNext={lastPage > page} />
    </FullContent>

  );

};

import Axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { NoteThreadCategory } from '../../shared/Notes/NoteThreadCategory';
import { LoadableStatuses } from '../../shared/RequestStatuses';

type Status = LoadableStatuses<NoteThreadCategory[], string>;
interface IProps {
  id?: string|number;
  selectCategoryFn: (categories: NoteThreadCategory[]) => any;
  selectedCategories: NoteThreadCategory[];
}

export const NoteThreadCategories = (props: IProps) => {

  const [status, setStatus] = React.useState<Status>({ state: 'loading' });

  React.useEffect(() => {
    const getCategoriesSource = Axios.CancelToken.source();
    getCategories(getCategoriesSource);

    return () => {
      getCategoriesSource.cancel();
    };
  },              []);

  const getCategories = async (cancelTokenSource: CancelTokenSource) => {
    return Axios.get('/api/note-categories', { cancelToken: cancelTokenSource.token })
      .then((response) => {
        setStatus({
          state: 'success',
          content: response.data.data.map((item: any) => NoteThreadCategory.fromApi(item)),
        });
      }).catch(() => {
        setStatus({ state: 'failure', error: 'Categories failed to load' });
      });
  };

  const onChangeTypeahead = (categories: NoteThreadCategory[]) => {
    props.selectCategoryFn(categories);
  };

  const options = status.state === 'success' ? status.content : [];

  const errorBanner = status.state === 'failure' ? (
    <p className="alert-danger">
      { status.error }
    </p>
  ) : null;

  return (
    <div className="form-group">
      <Typeahead
        id={props.id}
        placeholder="Select tags"
        options={options}
        selected={props.selectedCategories}
        onChange={onChangeTypeahead}
        multiple={true}
        paginate={false}
        disabled={status.state !== 'success'}
        maxResults={options.length}
        labelKey={(item: NoteThreadCategory) => item.name}
      />
      { errorBanner }
    </div>
  );
};

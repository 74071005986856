export interface NoteThreadCategoryApiResponse {
  id: number;
  name: string;
}

export class NoteThreadCategory {
  id: number;
  name: string;

  static fromApi(data: NoteThreadCategoryApiResponse): NoteThreadCategory {
    return {
      id: data.id,
      name: data.name,
    };
  }
}

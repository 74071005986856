import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { WebBotSeller } from '../../../shared/WebBotSeller';
import { WebBotUrlStatuses } from '../../../shared/WebBotUrl';
import { GatewayModal, ModalType } from '../../shared';

export const MassReviewWebBotUrlsModal = (props: { shown: boolean, seller: WebBotSeller, onSuccess: () => void, onClose: () => void, status: { id: WebBotUrlStatuses, name: string }}) => {
  const [loading, setLoading] = React.useState(false);
  const form = useForm<{ action: WebBotUrlStatuses, approve: string }>();

  const { shown, seller, onSuccess, onClose, status } = props;

  React.useEffect(() => {
    // reset modal when opening/close
    form.reset();

  },              [shown]);

  const submit = async (values: { action: WebBotUrlStatuses, approve: string }) => {
    if (loading) return;
    setLoading(true);
    await fetch(`/api/enforcement/sellers/${seller.id}/mass-review?action=${values.action}&status=${status.id}`, { method: 'POST' });
    setLoading(false);
    onClose();
    onSuccess();
  };

  const close = () => {
    if (!loading) onClose();
  };

  return (
    <GatewayModal title="Mass Review" shown={shown} onClose={close} type={ModalType.Primary}>
      <form onSubmit={form.handleSubmit(submit)}>
        <Modal.Body>
          <p className="text-center">You are mass reviewing content for <strong>{seller.platformUsername}</strong>.  <br /> This will affect all content with the status <strong>{status.name}</strong>.</p>

          <div className="form-group">
            <label>Action</label>
            <select className="form-control" {...form.register('action', { required: true })} >
              <option selected disabled>Select Action</option>
              <option value={WebBotUrlStatuses.Authorized}>Authorized</option>
              <option value={WebBotUrlStatuses.NeedsTakedown}>Needs takedown</option>
              <option value={WebBotUrlStatuses.PendingRemoval}>Pending Removal</option>
              <option value={WebBotUrlStatuses.NeedsReview}>Needs Review</option>
              <option value={WebBotUrlStatuses.Ignored}>Ignored</option>
              <option value={WebBotUrlStatuses.Removed}>Removed</option>
            </select>
          </div>

          {form.watch('action') ? <div className="form-group">
            <label>Please type approve to continue with mass review.</label>
            <input autoComplete="off" {...form.register('approve', { required: true, pattern: /approve/ })} type="text" className="form-control" />
            {form.formState.errors && form.formState.errors.approve ? <p className="text-danger small"> You must type approve</p> : null}

          </div> : null}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={close} type="button" className="btn btn-default pull-left">Cancel</button>
          <button className="btn btn-primary pull-right">{loading ? 'Reviewing...' : 'Mass Review'}</button>
        </Modal.Footer>

      </form>

    </GatewayModal>
  );

};

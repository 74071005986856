import { faRadar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikActions, FormikValues } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { UserContext } from '../../../contexts';
import { AccountWebUrlType, AffinityClient, AffinityImage, Filters } from '../../../shared';
import { WebBotSeller, WebBotSellerApiResponse } from '../../../shared/WebBotSeller';
import { FullContent } from '../../ContentFrame';
import {
  FilterBar,
  GatewayModal,
  LicensorTypeahead,
  LoadingSpinner,
  ModalType,
  PaginationFooter,
} from '../../shared';

interface Paginator {
  total: number;
  page: number;
  lastPage: boolean;
  totalPages: number;
}

export const ScansIndexPage = () => {
  const location = useLocation();
  const user = React.useContext(UserContext);
  const query = queryString.parse(location.search);
  const [search, setSearch] = useState(query['search'] ? query['search'] : null);
  const [sellers, setSellers] = React.useState<WebBotSeller[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [selectedSort, setSelectedSort] = React.useState(query['sort'] ? query['sort'] : 'recent');
  const [selectedFilter, setSelectedFilter] = React.useState(query['accounts'] ? query['accounts'] : 'all');
  const [sellerFilter, setSellerFilter] = React.useState(query['sellers'] ? query['sellers'] : '');
  const [createScanModalShown, setCreateScanModalShown] = React.useState(false);
  const [licensor, setLicensor] = React.useState<AffinityClient[]>([]);
  const [pagination, setPagination] = React.useState<Paginator>({
    total: 0,
    page: 0,
    lastPage: false,
    totalPages: 0,
  });
  const [webUrlTypes, setWebUrlTypes] = useState<AccountWebUrlType[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<AccountWebUrlType | undefined>(undefined);
  const filters = new Filters();
  const routeParams = useParams();
  const history = useHistory();
  const routeMatch = useRouteMatch<{vendorId: string, clientId: string}>();
  const firstLoad = React.useRef(true);

  React.useEffect(() => {
    const fetchData = async () => {
      if (firstLoad.current) {
        await getWebUrlTypes();
        firstLoad.current = false;
      }
      await getWebBotSellers();
    };
    fetchData();
  },              [location.search]);

  if (user.type !== 'admin') {
    return <Redirect to="/" />;
  }

  const setPage = (newPage: number) => {
    const temp = pagination;
    temp.page = newPage;
    setPagination(temp);
    let currentParams = queryString.parse(location.search);
    currentParams = { ...currentParams, page: newPage };
    const cleaned = omitBy(currentParams, isNil);
    const baseUrl = location.pathname;
    history.replace(`${baseUrl}?${queryString.stringify(cleaned)}`);
  };

  const getWebUrlTypes = async () => {
    try {
      const response = await Axios.get('/api/account-web-url-types');
      const types = response.data.data.map((typeData: any) => AccountWebUrlType.fromApi(typeData));
      setWebUrlTypes(types);
      const etsyPlatform = types.find((type: AccountWebUrlType) => type.id === 2);
      if (etsyPlatform) {
        setSelectedPlatform(etsyPlatform);
      }
    } catch (error) {
      console.error('Error fetching web_url_types:', error);
    }
  };

  function changeScanSort(e: any) {
    setLoading(true);
    setSelectedSort(e.target.value);
    const qsParsed = queryString.parse(location.search);
    qsParsed.sort = e.target.value;
    qsParsed.page = 1;
    history.push(`${location.pathname}?${queryString.stringify(qsParsed)}`);
  }

  function changeAccountFilter(e: any) {
    setLoading(true);
    setSelectedFilter(e.target.value);
    const qsParsed = queryString.parse(location.search);
    qsParsed.accounts = e.target.value !== '' ? e.target.value : null;
    qsParsed.page = 1;
    const cleaned = omitBy(qsParsed, isNil);
    history.push(`${location.pathname}?${queryString.stringify(cleaned)}`);
  }

  function changeSellerFilter(e: React.ChangeEvent<HTMLSelectElement>) {
    setLoading(true);
    setSellerFilter(e.target.value);
    const qsParsed = queryString.parse(location.search);
    qsParsed.sellers = e.target.value !== '' ? e.target.value : null;
    qsParsed.page = 1;
    const cleaned = omitBy(qsParsed, isNil);
    history.push(`${location.pathname}?${queryString.stringify(cleaned)}`);
  }

  function updateFilters(newFilters: Filters, performSearch: boolean) {
    if (typeof newFilters.search === 'string' && !performSearch) {
      setSearch(newFilters.search);
    }
    filters.setFilters(newFilters);
    let currentParams = queryString.parse(location.search);
    currentParams = { ...currentParams, ...newFilters, search };
    if (performSearch) {
      currentParams.page = 1;
      const cleaned = omitBy(currentParams, isNil);
      const baseUrl = location.pathname;
      history.push(`${baseUrl}?${queryString.stringify(cleaned)}`);
    }
  }

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['clientId']) {
      return routeParams['clientId'];
    }
    return '';
  }

  function hideCreateModal() {
    setCreateScanModalShown(false);
  }

  function getScanHistoryPage() {
    if (routeParams['vendorId']) {
      history.push(`/vendors/${getAccountID()}/scans/history`);
    } else if (routeParams['clientId']) {
      history.push(`/clients/${getAccountID()}/scans/history`);
    } else {
      history.push('/scans/history');
    }
  }

  function getScanSellerDetailPageLink(id: number) {
    return `/scans/sellers/${id}`;
  }

  const getWebBotSellers = async () => {
    setLoading(true);
    let clientId = null;
    if (routeMatch !== null && routeMatch.params['clientId']) {
      clientId = routeMatch.params['clientId'];
    }
    const query = queryString.parse(location.search);
    const params = {
      clientId,
      page: query.page ? query.page : null,
      search: query.search ? query.search : null,
      sort: query.sort ? query.sort : null,
      accounts: query.accounts ? query.accounts : null,
      sellers: query.sellers ? query.sellers : null,
    };
    const cleaned = omitBy(params, isNil);
    const s = await Axios.get('/api/enforcement-bots/sellers', { params: cleaned });
    const paginationResult = s.data.meta.pagination;
    setPagination({
      total: paginationResult.total,
      page: paginationResult.current_page,
      lastPage: paginationResult.total_pages === paginationResult.current_page,
      totalPages: paginationResult.total_pages,
    });
    const sellers = s.data.data.map((seller: WebBotSellerApiResponse) => WebBotSeller.fromApi(seller));
    setSellers(sellers);
    setLoading(false);
  };

  const saveScanRequest = async (values: FormikValues, formProps: FormikActions<FormikValues>) => {
    setSaving(true);
    let clientId = null;
    if (routeMatch !== null && routeMatch.params['clientId']) {
      clientId = routeMatch.params['clientId'];
    }
    const params = {
      clientId: values.scanType === 'licensor' ? (licensor && licensor[0] ? licensor[0].id : null) : clientId,
      url_type: values.platform,
      scan_type: values.scanType,
      keyword: values.scanType === 'keyword' ? values.keywordSearch : null,
      seller_url: values.scanType === 'seller' ? values.sellerUrlSearch : null,
      licensor: values.scanType === 'licensor' ? (licensor && licensor[0] ? licensor[0].shortName : null) : null,
    };
    const cleaned = omitBy(params, isNil);
    const s = await Axios.get('/api/enforcement-bots/start-scan', { params: cleaned });
    if (s.data === 'Scan already in progress.') {
      alert('Scan already in progress');
    }
    await getWebBotSellers();
    setSaving(false);
    setCreateScanModalShown(false);
  };

  const initialValues = {
    platform: '',
    scanType: '',
    keywordSearch: '',
    sellerUrlSearch: '',
    licensorSearch: new AffinityClient(),
  };

  const validation = Yup.object({
    platform: Yup.string().min(1).required('A platform name is required'),
    scanType: Yup.string().min(1).required('A scan type is required'),
    keywordSearch: Yup.string().when('scanType', { is: 'keyword', then: Yup.string().min(1).required('A keyword is required') }),
    sellerUrlSearch: Yup.string().when('scanType', { is: 'seller', then: Yup.string().min(1).required('A seller URL is required') }),
    licensorSearch: Yup.string().when('scanType', { is: 'licensor', then: Yup.string().min(1).required('A licensor is required') }),
  });

  const createScanModalText = saving ? 'Submitting...' : 'Request Scan';

  const statusColors = {
    1: 'black', // 'Potential'
    2: '#28a745', // 'Current'
    3: 'black', // 'Cancelled'
    4: 'black', // 'Registered'
    5: '#e06026', // 'Illegal'
    6: 'black', // 'Out of Business'
    7: 'black', // 'Non-Producing'
    8: 'black', // 'One-Time'
    10: 'black', // 'Declined'
    11: 'black', // 'Non-Issue'
    12: 'black', // 'Retailer'
  };

  const backupImage = new AffinityImage();
  backupImage.defaultImage = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/vendor-icon.png';

  const html =
    <FullContent>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ maxWidth: '50%' }}>
          <h3><strong>Scans</strong></h3>
        </div>
        <div style={{ maxWidth: '50%' }}>
          <button className="btn btn-default" onClick={getScanHistoryPage}>Scan History</button>
          <button className="btn btn-primary" onClick={() => setCreateScanModalShown(true)} style={{ marginLeft: 5 }}>New Scan</button>
        </div>
      </div>
      <FilterBar
        updateFilters={updateFilters}
        usePerPage={false}
        useQuarter={false}
        useSearch={true}
        useDates={false}
        search={search}
        perPage={10}
        fieldClassOverrides={{ search: 'address-search-bar col-lg-3 col-sm-3 col-xs-12' }}
      >
        <div className="pull-right" style={{ marginRight: 10 }}>
          <select
            className="form-control"
            name="scanFilter"
            onChange={event => changeAccountFilter(event)}
            value={selectedFilter}
          >
            <option key="allaccounts" value="">Account Filter (All)</option>
            <option key="current" value="current">Current Accounts</option>
            <option key="noncurrent" value="noncurrent">Non-Current Accounts</option>
            <option key="unknown" value="unknown">Unknown Accounts</option>
          </select>
        </div>
        <div className="pull-right" style={{ marginRight: 10 }}>
          <select
            className="form-control"
            name="scanSort"
            onChange={event => changeScanSort(event)}
            value={selectedSort}
          >
            <option key="recent" value="recent">Most Recent</option>
            <option key="alerts" value="alerts">Licensor Alerts</option>
            <option key="total" value="total">Total Products</option>
          </select>
        </div>
        <div className="pull-right" style={{ marginRight: 10 }}>
          <select
            className="form-control"
            name="sellerFiler"
            onChange={event => changeSellerFilter(event)}
            value={sellerFilter}
          >
            <option key="recent" value="">All Sellers</option>
            <option key="alerts" value="open">Open Sellers</option>
            <option key="total" value="closed">Closed Sellers</option>
          </select>
        </div>
      </FilterBar>
      {loading ? <LoadingSpinner /> : <div style={{ marginTop: 15 }} className="panel panel-portal">
        <div className="table-responsive">
          <table className="table table-portal table-hover">
            <thead>
            <tr>
              <th style={{ minWidth: 150 }}>Seller</th>
              <th style={{ minWidth: 150 }}>Account</th>
              <th>Account Status</th>
              <th>Link</th>
              <th>Total Products</th>
              <th>Licensor Alerts</th>
              <th>Last Scan</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {sellers.map((s: WebBotSeller) => (
              <tr key={s.id}>
                <td>
                  <Link style={{ color: 'black' }} to={getScanSellerDetailPageLink(s.id)}>
                    {s.image ?
                      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <img style={{ width: 35, height: 35 }} src={s.image.getSize('sm')} />
                        <span style={{ marginLeft: 10 }}>
                            {s.platformUsername}
                          </span>
                      </div>
                      : <span className="text-muted">Account Not Found</span>}
                  </Link>
                </td>
                <td>
                  <Link style={{ color: 'black' }} to={getScanSellerDetailPageLink(s.id)}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                      <img style={{ width: 35, height: 35 }} src={s.linkedAccount ? s.linkedAccount.logo.getSize('sm') : backupImage.getSize('sm')} />
                      <span style={{ marginLeft: 10 }}>
                          {s.linkedAccount ? s.linkedAccount.accountName : <span className="text-muted">Account Not Found</span>}
                        </span>
                    </div>
                  </Link>
                </td>
                <td>
                  <Link style={{ color: 'black' }} to={getScanSellerDetailPageLink(s.id)}>
                    {s.linkedAccount ? <span style={{ color: statusColors[s.linkedAccount.status.id] }}>{s.linkedAccount.status.display}</span> : '-'}
                  </Link>
                </td>
                <td>
                  <a target="_blank" href={s.sellerUrl}>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`${s.id}-platform`}>{s.sellerUrl}</Tooltip>}
                    >
                        <span>
                          <i
                            className={
                              s.platform && s.platform.icon
                                ? s.platform.icon
                                : 'fas fa-globe'
                            }
                          ></i>
                        </span>
                    </OverlayTrigger>
                  </a>
                </td>
                <td>
                  <Link style={{ color: 'black' }} to={getScanSellerDetailPageLink(s.id)}>
                    {(s.scanRequest === null || (s.scanRequest.startTime && s.scanRequest.endTime))
                      ? s.urlCount
                      : <span>-</span>}
                  </Link>
                </td>
                <td>
                  <Link style={{ color: 'black' }} to={getScanSellerDetailPageLink(s.id)}>
                    {(s.scanRequest === null || (s.scanRequest.startTime && s.scanRequest.endTime))
                      ? <span className={s.licensorAlerts > 0 ? 'text-danger' : ''}>
                          {s.licensorAlerts}
                        </span>
                      : <span>-</span>}
                  </Link>
                </td>

                <td>
                  <Link style={{ color: 'black' }} to={getScanSellerDetailPageLink(s.id)}>
                    {s.latestUrlDate ? s.latestUrlDate.format('MMM DD, YYYY') : '-'}
                  </Link>
                </td>
                <td>
                  {s.scanRequest && (s.scanRequest.isInProgress || !s.scanRequest.startTime) ?
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`${s.id}-progress`}>{s.scanRequest.isInProgress ? 'Scan In Progress' : 'Scan Not Started'}</Tooltip>}
                    >
                        <span>
                          <FontAwesomeIcon icon={faRadar} className={s.scanRequest.isInProgress ? 'text-primary' : 'text-muted'} />
                        </span>
                    </OverlayTrigger>
                    : null}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>}
      {loading && pagination ? null : <PaginationFooter
        totalResults={pagination.total}
        hasNext={!pagination.lastPage}
        currentPage={pagination.page}
        setPage={setPage}
        totalPages={pagination.totalPages}
        showFirstLast={true}
      />}
      <GatewayModal shown={createScanModalShown} type={ModalType.Primary} onClose={hideCreateModal} title="New Scan">
        <Modal.Body>
          <Formik
            onSubmit={(values, formProps) => saveScanRequest(values, formProps)}
            validationSchema={validation}
            validateOnBlur={false}
            initialValues={initialValues}>
            {formProps => (
              <div>
                <Form id="scan-request-form">
                  <div className="form-group">
                    <label htmlFor="platform">Platform</label>
                    <Field
                      component="select"
                      name="platform"
                      className="form-control"
                      onChange={(event: any) => {
                        const platformId = event.target.value;
                        formProps.setFieldValue('platform', platformId);
                        formProps.setFieldValue('scanType', '');
                        const platform = webUrlTypes.find((type: AccountWebUrlType) => type.id === Number(platformId));
                        setSelectedPlatform(platform);
                      }}
                    >
                      <option value="" disabled>
                        Select Platform
                      </option>
                      {webUrlTypes
                        .filter((type: AccountWebUrlType) => type.hasScanRequests)
                        .map((type: AccountWebUrlType) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                    </Field>
                    <p className="text-danger">
                      <ErrorMessage name="platform" />
                    </p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="scanType">Scan Type</label>
                    <Field
                      component="select"
                      name="scanType"
                      className="form-control"
                      disabled={!selectedPlatform}
                    >
                      <option value="" disabled>
                        Select Scan Type
                      </option>
                      {selectedPlatform && selectedPlatform.hasScanRequests && (
                        <>
                          <option value="keyword">Keyword</option>
                          <option value="licensor">Licensor</option>
                        </>
                      )}
                      {selectedPlatform && selectedPlatform.hasSellerScans && (
                        <option value="seller">Seller</option>
                      )}
                    </Field>
                    <p className="text-danger">
                      <ErrorMessage name="scanType" />
                    </p>
                  </div>
                  {formProps.values.scanType === 'keyword' ?
                    <div className="form-group">
                      <label htmlFor="keywordSearch">Keyword</label>
                      <Field placeholder="Keyword" name="keywordSearch" className="form-control" />
                      <p className="text-danger">
                        <ErrorMessage name="keywordSearch" />
                      </p>
                    </div>
                    : null}
                  {formProps.values.scanType === 'seller' ?
                    <div className="form-group">
                      <label htmlFor="keywordSearch">Seller URL</label>
                      <Field placeholder="Seller URL" name="sellerUrlSearch" className="form-control" />
                      <p className="text-danger">
                        <ErrorMessage name="sellerUrlSearch" />
                      </p>
                    </div>
                    : null}
                  {formProps.values.scanType === 'licensor' ?
                    <div className="form-group">
                      <label htmlFor="licensorSearch">Licensor</label>
                      <LicensorTypeahead selected={licensor} onChange={setLicensor} multiple={false} />
                      <p className="text-danger">
                        <ErrorMessage name="licensorSearch" />
                      </p>
                    </div>
                    : null}
                </Form>
              </div>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideCreateModal} className="btn btn-default pull-left">Cancel</button>
          <button type="submit" form="scan-request-form" className="btn btn-primary pull-right" disabled={saving}>{createScanModalText}</button>
        </Modal.Footer>
      </GatewayModal>
    </FullContent>;

  return html;
};

import * as React from 'react';
import Media from 'react-media';
import { Redirect, Route, Switch } from 'react-router';
import { UserContext } from '../../contexts';
import { AccountAddressPage, AccountProfilePage, AliasFormPage, AliasPage, AudienceSegmentsPage, EditAccount, EditAccountAddress, ExpeditedDesignsPage, ExternalWebsitesPage, PaymentMethodsPage, RequestProfileUpdate, SettingsContainer, TaxNexusPage, TrademarkDetailPage, TrademarksPage, VendorProductsPage } from '../Account';
import { CreateAccountAddress } from '../Account/Settings/CreateAccountAddress';
import { AnnualInvoiceEmailsPage, QuarterlyReportsManagementPage } from '../Admin';
import { AnnualReportUploads } from '../Admin/AnnualReportUploads';
import { UninvoicedCancelledMinimumsPage } from '../Admin/UninvoicedCancelledMinimumsPage';
import AngularWrapper from '../AngularWrapper/AngularWrapper';
import { AudienceMapViewer, AudiencePerCapitaDetailPage, AudiencePerCapitaPage } from '../Audience';
import { BrandIndexPage, ClientBrandPage,  VendorBrandIndex  } from '../Brands';
import ClientRoyaltySchedulesPage from '../ClientRoyaltySchedules/ClientRoyaltySchedulesPage';
import { PerformanceContainer } from '../Dashboard';
import { DesignResponseIndexPage } from '../DesignResponses/DesignResponseIndexPage';
import { DesignPage, DesignsIndexPage } from '../Designs';
import { DesignUploadPage, SingleDesignUploadPage } from '../Designs/DesignUpload';
import {
  CaseDetailPage,
  CasesIndexPage,
  EnforcementDashboardPage,
  EnforcementIndexRedirect,
  EnforcementTemplateDetailPage,
  EnforcementTemplateIndexPage,
  EnforcementTemplateNewPage,
  ManualWebBotUrlForm,
  ResearchIndex,
  ScansIndexPage,
  SellerDetailPage,
  UsesDetailPage,
  UsesIndexPage,
  UsesManualNewPage,
  VendorEnforcementDashboardPage,
  WebBotUrlIndexPage,
  WebBotUrlPage,
} from '../Enforcement';
import { NoOpenCasesPage } from '../Enforcement/Cases/NoOpenCasesPage';
import { ScanHistoryPage } from '../Enforcement/Scans/ScanHistoryPage';
import { Help } from '../Help';
import { InsigniaFormPage, InsigniaIndexPage } from '../Insignia';
import { InvoicePaymentPage } from '../Invoices';
import { StripePayoutsPage } from '../Invoices/StripePayoutsPage';
import { LabelsOrderPage } from '../Labels';
import { SerialSearchPage } from '../Labels/SerialSearchPage';
import { ApplicationIndexPage, ManageRenewalsPage } from '../Licenses';
import { LicensorOverviewPage } from '../LicensorOverview';
import { AdDetailPage, AddGalleryWrapper, AddProductWrapper, AdsIndexPage, GalleryIndexPage, GalleryItemPage, MessagesIndexPage, MessagesWrapper, MessageThreadPage, NewAdPage, NewPostPage, NewSitePagePage, PostDetailPage, PostsIndexPage, ProductIndexPage, ProductPage, SitePageIndexPage, SitePagePage, SitesIndexPage } from '../Marketplace';
import { MarketplaceDashboardPage } from '../Marketplace/MarketplaceDashboardPage';
import { OrderIndexPage } from '../Marketplace/OrderIndexPage';
import { OrderPage } from '../Marketplace/OrderPage';
import { MarketplacePrivacyPolicy, MarketplaceSellersPolicy, MarketplaceSettingsPage, MarketplaceSignup, MarketplaceTermsOfUse } from '../Marketplace/Settings';
import { ShopifyPage } from '../Marketplace/ShopifyPage';
import { NotesContainer } from '../Notes';
import { PaymentsTestPage } from '../Payments/PaymentsTestPage';
import {
  RoyaltiesIndex,
  RoyaltyAnalyticsPage,
  RoyaltyReportContainer,
  } from '../Royalties';
import { VendorIndex } from '../Vendors';
import { ClientVendorList } from '../Vendors/ClientVendorList';
import { LabelsIndex } from '../Vendors/LabelsIndex';
import { VendorDetailPage } from '../Vendors/VendorDetailPage';
import { VendorRouteHandler } from '../Vendors/VendorRouteHandler';
import { VendorRoyaltyMinimums } from '../Vendors/VendorRoyaltyMinimums';

export class GatewayRoutes extends React.Component {
  generateRoutesWithVendorRoutes(routeList: { path: string, component: any }[]) {

    const routes: any[] = [];
    routeList.forEach((route) => {
      routes.push(
        <Route
          key={`vendor-${route.path}`}
          path={`/vendors/:vendorId/${route.path}`}
          exact={true}
          component={route.component}
        />,
      );
      routes.push(<Route key={route.path} path={`/${route.path}`} exact={true} component={route.component} />);
    });

    return routes;

  }

  render() {
    const royaltyRoutes = this.generateRoutesWithVendorRoutes([
        { path: 'royalties/minimums', component: VendorRoyaltyMinimums },
        { path: 'royalties', component: RoyaltiesIndex },
    ]);
    return (
      <Switch>
        {royaltyRoutes}
        <Route path="/royalties/analytics" exact={true} component={RoyaltyAnalyticsPage} />
        <Route path="/vendors/:vendorId/royalties/analytics" exact={true} component={RoyaltyAnalyticsPage} />
        <Route path="/vendors/:vendorId/royalties/:id" component={RoyaltyReportContainer} />
        <Route path="/royalties/:id" component={RoyaltyReportContainer} />
        <Route path="/notes" component={NotesContainer} />
        <Route path="/payments-test" exact={true} component={PaymentsTestPage} />
        <Route path="/help" exact={true} component={Help} />
        <Route path="/vendors" exact={true} component={VendorIndex} />
        <Route path="/clients/:licensorId/vendors" exact={true} component={ClientVendorList} />
        <Route path="/clients/:licensorId/vendors/:vendorId" exact={true} component={VendorDetailPage} />
        <Route path="/vendors/:vendorId" exact={true} component={VendorRouteHandler} />

        <Route path="/clients/:licensorId/royalties" exact={true} component={RoyaltyAnalyticsPage} />
        <Route
          path="/clients/:licensorId/royaltyschedules"
          exact={true}
          component={ClientRoyaltySchedulesPage}
        />
        <Route path="/brand/assets" exact={true} component={BrandIndexPage} />
        <Route path="/brand/trademarks" exact={true} component={TrademarksPage} />
        <Route path="/brand/trademarks/:id" exact={true} component={TrademarkDetailPage} />
        <Route path="/brand/settings" exact={true} component={InsigniaIndexPage} />
        <Route path="/clients/:licensorId/brand/trademarks" exact={true} component={TrademarksPage} />
        <Route path="/clients/:licensorId/brand/trademarks/:id" exact={true} component={TrademarkDetailPage} />
        <Route path="/clients/:licensorId/brand/settings" exact={true} component={InsigniaIndexPage} />
        <Route path="/clients/:licensorId/brand/settings/add" exact={true} component={InsigniaFormPage} />
        <Route path="/clients/:licensorId/brand/settings/:id/edit" exact={true} component={InsigniaFormPage} />
        <Route path="/design-responses" exact={true} component={DesignResponseIndexPage} />
        <Route
          path="/clients/:licensorId/design-responses"
          exact={true}
          component={DesignResponseIndexPage}
        />
        <Route path="/vendors/:vendorId/brand/assets" exact={true} component={VendorBrandIndex} />
        <Route path="/clients/:licensorId/brand/assets" exact={true} render={route =>
          <UserContext.Consumer>
            {user => <ClientBrandPage {...route} user={user} />}
          </UserContext.Consumer>
        } />

        <Route path="/licenses/fees" exact={true} component={LicensorOverviewPage} />
        <Route path="/licenses/renewals" exact={true} component={ManageRenewalsPage} />
        <Route path="/licenses/applications" exact={true} component={ApplicationIndexPage} />
        <Route path="/vendors/:vendorId/licenses/renewals" exact={true} component={ManageRenewalsPage} />

        <Route path="/designs" exact={true}  component={DesignsIndexPage} />
        <Route path="/designs/upload" exact={true}  component={SingleDesignUploadPage} />
        <Route path="/designs/upload/single" exact={true}  component={SingleDesignUploadPage} />
        <Route path="/designs/mass-upload" exact={true}  component={DesignUploadPage} />
        <Route path="/vendors/:vendorId/designs/upload" exact={true}  component={SingleDesignUploadPage} />
        <Route path="/vendors/:vendorId/designs/mass-upload" exact={true}  component={DesignUploadPage} />
        <Route path="/vendors/:vendorId/designs/upload/single" exact={true}  component={SingleDesignUploadPage} />
        <Route path="/designs/:id" exact={true}  component={DesignPage} />
        <Route path="/clients/:licensorId/designs" exact={true} component={DesignsIndexPage} />
        <Route path="/vendors/:vendorId/designs" exact={true} component={DesignsIndexPage} />
        <Route path="/clients/:licensorId/designs/:id" exact={true} component={DesignPage} />
        <Route path="/vendors/:vendorId/designs/:id" exact={true} component={DesignPage} />

        <Route path="/marketplace" exact={true} component={MarketplaceDashboardPage} />
        <Route path="/vendors/:vendorId/marketplace" exact={true} component={MarketplaceDashboardPage} />
        <Route path="/clients/:licensorId/marketplace" exact={true} component={MarketplaceDashboardPage} />

        <Route path="/marketplace/settings" exact={true} component={MarketplaceSettingsPage} />
        <Route path="/vendors/:vendorId/marketplace/settings" exact={true} component={MarketplaceSettingsPage} />
        <Route path="/marketplace/seller-policy" exact={true} component={MarketplaceSellersPolicy} />
        <Route path="/marketplace/terms-of-use" exact={true} component={MarketplaceTermsOfUse} />
        <Route path="/marketplace/privacy" exact={true} component={MarketplacePrivacyPolicy} />

        <Route path="/marketplace/products" exact={true} component={ProductIndexPage} />
        <Route path="/vendors/:vendorId/marketplace/products" exact={true} component={ProductIndexPage} />
        <Route path="/clients/:licensorId/marketplace/products" exact={true} component={ProductIndexPage} />
        <Route path="/marketplace/products/add" exact={true} component={AddProductWrapper} />
        <Route path="/vendors/:vendorId/marketplace/products/add" exact={true} component={AddProductWrapper} />
        <Route path ="/marketplace/products/:id" exact={true} component={ProductPage} />

        <Route path="/marketplace/gallery" exact={true} component={GalleryIndexPage} />
        <Route path="/vendors/:vendorId/marketplace/gallery" exact={true} component={GalleryIndexPage} />
        <Route path="/clients/:licensorId/marketplace/gallery" exact={true} component={GalleryIndexPage} />
        <Route path="/marketplace/gallery/add" exact={true} component={AddGalleryWrapper} />
        <Route path="/vendors/:vendorId/marketplace/gallery/add" exact={true} component={AddGalleryWrapper} />
        <Route path ="/marketplace/gallery/:id" exact={true} component={GalleryItemPage} />

        <Route path="/marketplace/sites" exact={true} component={SitesIndexPage} />

        <Route path="/marketplace/sites/:siteId" exact={true} component={SitePageIndexPage} />

        <Route path="/marketplace/sites/:siteId/pages/new" exact={true} component={NewSitePagePage}/>

        <Route path="/marketplace/sites/:siteId/pages/:pageId" exact={true} component={SitePagePage} />

        <Route path="/marketplace/shopify" exact={true} component={ShopifyPage} />
        <Route path="/vendors/:vendorId/marketplace/shopify" exact={true} component={ShopifyPage} />

        <Route path="/marketplace/signup" exact={true} component={MarketplaceSignup} />
        <Route path="/vendors/:vendorId/marketplace/signup" exact={true} component={MarketplaceSignup} />
        <Route path="/marketplace/orders" exact={true} component={OrderIndexPage} />
        <Route path="/vendors/:vendorId/marketplace/orders" exact={true} component={OrderIndexPage} />
        <Route path="/vendors/:vendorId/marketplace/orders/:id" exact={true} component={OrderPage} />
        <Route path="/marketplace/orders/:id" exact={true} component={OrderPage} />
        <Route path="/marketplace/orders/:id/test" exact={true} component={OrderPage} />
        <Route path="/marketplace/messages" exact={true} component={MessagesWrapper} />
        <Route path="/vendors/:vendorId/marketplace/messages" exact={true} component={MessagesWrapper} />
        <Route path="/marketplace/messages/:id" exact={true} render={
          routeProps =>
            <Media query={{ minWidth: 992 }}>
              {
                matches =>
                <UserContext.Consumer>
                  {user => matches ?
                    <MessagesIndexPage {...routeProps} user={user} /> :
                    <MessageThreadPage {...routeProps} user={user} />}
                </UserContext.Consumer>
              }
          </Media>
        } />
        <Route path="/vendors/:vendorId/marketplace/messages/:id" exact={true} render={
          routeProps =>
            <Media query={{ minWidth: 992 }}>
              {
                matches =>
                  <UserContext.Consumer>
                    {user => matches ?
                      <MessagesIndexPage {...routeProps} user={user} /> :
                      <MessageThreadPage {...routeProps} user={user} />}
                  </UserContext.Consumer>
              }
            </Media>
        } />;

        <Route path="/marketplace/posts" exact={true} component={PostsIndexPage} />
        <Route path="/marketplace/posts/new" exact={true} component={NewPostPage} />
        <Route path="/marketplace/posts/:id" exact={true} component={PostDetailPage} />

        <Route path="/marketplace/ads" exact={true} component={AdsIndexPage} />
        <Route path="/marketplace/ads/new" exact={true} component={NewAdPage} />
        <Route path="/marketplace/ads/:id" exact={true} component={AdDetailPage} />

        <Route path="/performance" component={PerformanceContainer} />
        <Route path="/clients/:licensorId/performance" component={PerformanceContainer} />

        <Route path="/clients/:clientId/labels" exact={true} component={SerialSearchPage} />
        <Route path="/labels" exact={true} component={LabelsIndex} />
        <Route path="/labels/order" exact={true} component={LabelsOrderPage} />
        <Route path="/labels/search" exact={true} component={SerialSearchPage} />
        <Route path="/clients/:clientId/labels/search" exact={true} component={SerialSearchPage} />
        <Route path="/vendors/:vendorId/labels/order" exact={true} component={LabelsOrderPage} />

        <Route path="/vendors/:vendorId/profile" exact={true} component={AccountProfilePage} />

        {/* Invoice Routes */}

        <Route path="/invoices/:id/pay" exact={true} component={InvoicePaymentPage} />
        <Route path="/vendors/:vendorId/invoices/:id/pay" exact={true} component={InvoicePaymentPage} />

        {/* Insignia Routes */}

        <Route path="/clients/:licensorId/brandmarks" exact={true} component={InsigniaIndexPage} />
        <Route path="/clients/:licensorId/brandmarks/add" exact={true} component={InsigniaFormPage} />
        <Route path="/clients/:licensorId/brandmarks/:id/edit" exact={true} component={InsigniaFormPage} />

        {/* Settings Routes */}

        <Route path="/vendors/:vendorId/settings" component={SettingsContainer} />
        <Route path="/clients/:licensorId/settings" component={SettingsContainer} />
        <Route path="/settings" component={SettingsContainer} />

        <Route path="/enforce" exact={true} component={EnforcementIndexRedirect} />
        <Route path="/enforce/content" exact={true} component={WebBotUrlIndexPage} />
        <Route path="/enforce/content/:id" exact={true} component={WebBotUrlPage} />
        <Route path="/enforce/sellers" exact={true} component={ScansIndexPage} />
        <Route path="/enforce/cases" exact={true} component={CasesIndexPage} />
        <Route path="/enforce/cases/:id" exact={true} component={CaseDetailPage} />
        <Route path="/enforce/cases/:caseId/uses" exact={true} component={UsesIndexPage} />
        <Route path="/enforce/cases/:caseId/uses/manual-new" exact={true} component={UsesManualNewPage} />
        <Route path="/enforce/cases/:id/uses/:useId" exact={true} component={UsesDetailPage} />
        <Route path="/clients/:clientId/enforce/cases" exact={true} component={CasesIndexPage} />
        <Route path="/clients/:clientId/enforce/cases/:id" exact={true} component={CaseDetailPage} />
        <Route path="/clients/:clientId/enforce/cases/:caseId/uses" exact={true} component={UsesIndexPage} />
        <Route path="/clients/:clientId/enforce/cases/:caseId/manual-new" exact={true} component={UsesManualNewPage} />
        <Route path="/clients/:clientId/enforce/cases/:id/uses/:useId" exact={true} component={UsesDetailPage} />
        <Route path="/vendors/:vendorId/enforce-old" exact={true} component={NoOpenCasesPage} />
        <Route path="/vendors/:vendorId/enforce/cases" exact={true} component={CasesIndexPage} />
        <Route path="/vendors/:vendorId/enforce/cases/closed" exact={true} component={CasesIndexPage} />
        <Route path="/vendors/:vendorId/enforce/cases/:id" exact={true} component={CaseDetailPage} />
        <Route path="/vendors/:vendorId/enforce/cases/:caseId/uses" exact={true} component={UsesIndexPage} />
        <Route path="/vendors/:vendorId/enforce/cases/:caseId/uses/manual-new" exact={true} component={UsesManualNewPage} />
        <Route path="/vendors/:vendorId/enforce/cases/:id/uses/:useId" exact={true} component={UsesDetailPage} />

        <Route path="/enforce/uses" exact={true} component={UsesIndexPage} />
        <Route path="/enforce/uses/manual-new" exact={true} component={UsesManualNewPage} />
        <Route path="/enforce/uses/:useId" exact={true} component={UsesDetailPage} />
        <Route path="/clients/:clientId/enforce/uses" exact={true} component={UsesIndexPage} />
        <Route path="/clients/:clientId/enforce/uses/manual-new" exact={true} component={UsesManualNewPage} />
        <Route path="/clients/:clientId/enforce/uses/:useId" exact={true} component={UsesDetailPage} />
        <Route path="/vendors/:vendorId/enforce/uses" exact={true} component={UsesIndexPage} />
        <Route path="/vendors/:vendorId/enforce/uses/manual-new" exact={true} component={UsesManualNewPage} />
        <Route path="/vendors/:vendorId/enforce/uses/:useId" exact={true} component={UsesDetailPage} />

        <Route path="/clients/:licensorId/enforce" exact={true} component={EnforcementDashboardPage} />
        <Route path="/clients/:licensorId/enforce/content" exact={true} component={WebBotUrlIndexPage} />
        <Route path="/clients/:licensorId/enforce/content/:id" exact={true} component={WebBotUrlPage} />

        <Route path="/vendors/:vendorId/enforce" exact={true} component={VendorEnforcementDashboardPage} />
        <Route path="/vendors/:vendorId/enforce/manual-content" exact={true} component={ManualWebBotUrlForm} />
        <Route path="/vendors/:vendorId/enforce/content" exact={true} component={WebBotUrlIndexPage} />
        <Route path="/vendors/:vendorId/enforce/content/:id" exact={true} component={WebBotUrlPage} />

        <Route path="/clients/:clientId/enforce/templates" exact={true} component={EnforcementTemplateIndexPage} />
        <Route path="/vendors/:vendorId/enforce/templates" exact={true} component={EnforcementTemplateIndexPage} />
        <Route path="/enforce/templates" exact={true} component={EnforcementTemplateIndexPage} />
        <Route path="/enforce/templates/new" exact={true} component={EnforcementTemplateNewPage} />
        <Route path="/clients/:clientId/enforce/templates/new" exact={true} component={EnforcementTemplateNewPage} />
        <Route path="/vendors/:vendorId/enforce/templates/new" exact={true} component={EnforcementTemplateNewPage} />
        <Route path="/enforce/templates/:id" exact={true} component={EnforcementTemplateDetailPage} />
        <Route path="/clients/:clientId/enforce/templates/:id" exact={true} component={EnforcementTemplateDetailPage} />
        <Route path="/vendors/:vendorId/enforce/templates/:id" exact={true} component={EnforcementTemplateDetailPage} />

        <Route path="/clients/:clientId/enforce/research" exact={true} component={ResearchIndex} />
        <Route path="/clients/:clientId/scans" exact={true} component={ScansIndexPage} />
        <Route path="/clients/:clientId/scans/history" exact={true} component={ScanHistoryPage} />
        <Route path="/scans" exact={true} component={ScansIndexPage} />
        <Route path="/scans/sellers/:id" exact={true} component={SellerDetailPage} />
        <Route path="/scans/history" exact={true} component={ScanHistoryPage} />
        <Route path="/scans/sellers/:id/history" exact={true} component={SellerDetailPage} />
        <Route path="/clients/:clientId/enforce/sellers/:id" exact={true} component={SellerDetailPage} />
        <Route path="/clients/:clientId/enforce/sellers/history" exact={true} component={ScanHistoryPage} />
        <Route path="/clients/:clientId/enforce/sellers/:id/history" exact={true} component={ScanHistoryPage} />

        <Route path="/admin/stripe-payouts" exact={true} component={StripePayoutsPage} />
        <Route path="/admin/annual-invoice-emails" exact={true} component={AnnualInvoiceEmailsPage} />
        <Route path="/admin/uninvoiced-cancelled-minimums" exact={true} component={UninvoicedCancelledMinimumsPage} />
        <Route path="/admin/annual-report-uploads" exact={true} component={AnnualReportUploads} />
        <Route path="/admin/manage-quarterly-reports" exact={true} component={QuarterlyReportsManagementPage} />

        <Route path="/vendors/:accountId/notes" component={NotesContainer} />
        <Route path="/clients/:accountId/notes" component={NotesContainer} />

        <Redirect from="/brand" exact={true} to="/brand/assets" />
        <Redirect from="/clients/:licensorId/brand" exact={true} to="/clients/:licensorId/brand/assets" />
        <Redirect from="/clients/:licensorId/reports" exact={true} to="/clients/:licensorId/performance" />
        <Redirect from="/clients/:licensorId/vendors" exact={true} to="/clients/:licensorId/performance" />
        <Redirect from="/clients/:licensorId/royalties" exact={true} to="/clients/:licensorId/performance" />
        <Redirect from="/reports" to="/performance" />
        <Redirect from="/account/edit" to="/settings" />
        <Redirect from="/clients/:licensorId/account/edit" to="/clients/:licensorId/settings" />
        <Redirect from="/vendors/:vendorId/account/edit" to="/vendors/:vendorId/settings" />

        <Route path="/clients/:licensorId/settings/addresses" exact={true} component={AccountAddressPage} />
        <Route path="/clients/:licensorId/settings/addresses/add" exact={true} component={CreateAccountAddress} />
        <Route path="/vendors/:vendorId/settings/addresses" exact={true} component={AccountAddressPage} />
        <Route path="/vendors/:vendorId/settings/addresses/add" exact={true} component={CreateAccountAddress} />

        <Route path="/clients/:licensorId/audiences" exact={true} component={AudiencePerCapitaPage} />
        <Route path="/clients/:licensorId/audiences/detail" exact={true} component={AudiencePerCapitaDetailPage} />
        <Route path="/clients/:licensorId/audiences/map" exact={true} component={AudienceMapViewer} />
        <Route path="/clients/:licensorId/audiences/:id/detail" exact={true} component={AudiencePerCapitaDetailPage} />
        <Route path="/clients/:licensorId/audiences/:id" exact={true} component={AudiencePerCapitaPage} />

        <Route path="/audiences" exact={true} component={AudiencePerCapitaPage} />
        <Route path="/audiences/detail" exact={true} component={AudiencePerCapitaDetailPage} />
        <Route path="/audiences/map" exact={true} component={AudienceMapViewer} />
        <Route path="/audiences/:id/detail" exact={true} component={AudiencePerCapitaDetailPage} />
        <Route path="/audiences/:id" exact={true} component={AudiencePerCapitaPage} />

        <Route path="/clients/:licensorId/map" exact={true} component={AudienceMapViewer} />
        <Route path="/map" exact={true} component={AudienceMapViewer} />

        {/* All routes need to be above these */}
    <Route path="/clients/:licensorId/:any" exact={true} component={AngularWrapper} />
        <Route path="/vendors/:vendorId/:any" exact={true} component={AngularWrapper} />

        <Route component={AngularWrapper} />
      </Switch >
    );

  }

}

import { faCog, faEdit, faPaperclip, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as moment from 'moment';
import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Note, NoteThread } from '../../shared';
import { LoadingSpinner } from '../shared';
import { DeleteNoteModal } from './DeleteNoteModal';
import { DeleteNoteThreadModal } from './DeleteNoteThreadModal';
import { EditNoteModal } from './EditNoteModal';
import { EditNoteThreadModal } from './EditNoteThreadModal';
import { NoteFormValues, ThreadFormValues } from './forms';
import { MassUploadButton, MassUploadStatus, MassUploadStatusAlert } from './MassUploadButton';
import { NewNoteForm } from './NewNoteForm';
import { NoteUserList } from './NoteUserList';

export const NotesDetail = () => {
  const { noteId, accountId } = useParams<any>();
  const location = useLocation();
  const [thread, setThread] = React.useState<NoteThread | null>(null);
  const [creatingNote, setCreatingNote] = React.useState(false);
  const [editThreadModalShown, setEditThreadModalShown] = React.useState(false);
  const [editingNote, setEditingNote] = React.useState<Note | null>(null);
  const [submittingEdit, setSubmittingEdit] = React.useState(false);
  const [deleteThread, setDeleteThread] = React.useState(false);
  const [noteToDelete, setNoteToDelete] = React.useState<Note | null>(null);
  const [massUploadStatus, setMassUploadStatus] = React.useState<MassUploadStatus>({ state: 'unsubmitted' });
  const history = useHistory();

  React.useEffect(() => {
    setThread(null);
    setCreatingNote(false);
    getThread();

  },              [noteId]);

  const getThread = async () => {
    const t = await Axios.get(`/api/note-threads/${noteId}`);
    setThread(NoteThread.fromApi(t.data.data));
  };

  if (!thread) {
    return <LoadingSpinner />;
  }

  const createNote = async (values: NoteFormValues) => {
    const formData = new FormData();
    formData.append('thread_id', `${thread.id}`);
    formData.append('note', values.note);
    if (values.contactMethod) {
      formData.append('note_method_id', values.contactMethod.id);
    }
    if (values.users.length) {
      values.users.forEach(u => formData.append('tagged_user_ids[]', `${u.id}`));
    }
    if (values.accountUsers.length) {
      values.accountUsers.forEach(u => formData.append('tagged_user_ids[]', `${u.id}`));
    }
    if (values.attachments.length) {
      Array.from(values.attachments).forEach(a => formData.append('note_attachments[]', a));
    }
    if (values.noteDate !== moment().format('YYYY-MM-DD')) {
      formData.append('occurred_at', values.noteDate);
    }

    const n = await Axios.post('api/notes', formData);
    const note = Note.fromApi(n.data.data);
    const t = thread;
    t.notes.unshift(note);
    setThread(t);
    setCreatingNote(false);
    updateListView();

  };

  const updateNote = async (values: NoteFormValues) => {
    if (submittingEdit) {
      return;
    }
    setSubmittingEdit(true);
    const formData = new FormData();
    formData.append('note', values.note);
    if (values.contactMethod) {
      formData.append('note_method_id', values.contactMethod.id);
    }
    if (values.users.length) {
      values.users.forEach(u => formData.append('tagged_user_ids[]', `${u.id}`));
    }
    if (values.accountUsers.length) {
      values.accountUsers.forEach(u => formData.append('tagged_user_ids[]', `${u.id}`));
    }
    if (values.attachments.length) {
      Array.from(values.attachments).forEach(a => formData.append('note_attachments[]', a));
    }
    if (values.currentAttachments  && values.currentAttachments.length) {
      Array.from(values.currentAttachments).forEach(a => formData.append('old_note_attachments[]', `${a.id}`));
    }
    if (editingNote && values.noteDate !== editingNote.createdAt.format('YYYY-MM-DD')) {
      formData.append('occurred_at', values.noteDate);
    }

    if (editingNote) {
      const n = await Axios.post(`/api/notes/${editingNote.id}`, formData);

      const note = Note.fromApi(n.data.data);
      const t = thread;
      t.replaceNote(note);
      setThread(t);
      setEditingNote(null);
      setSubmittingEdit(false);
      updateListView();
    }

  };

  const updateThread = async (values: ThreadFormValues) => {
    const data = {
      subject: values.subject,
      note_code: values.code,
      is_pinned: values.pinned ? 1 : 0,
      note_warn: values.flagged ? 1 : 0,
      category_ids: values.categories.map(c => c.id),
    };

    const d = await Axios.put(`/api/note-threads/${thread.id}`, data);
    const t =  NoteThread.fromApi(d.data.data);
    setThread(t);
    updateListView();
    setEditThreadModalShown(false);

  };

  const updateListView = () => {
    const createThreadEvent = new CustomEvent('note-list-reload', {
      bubbles: true,
      detail: { thread: `${(new Date()).getTime()}` },
    });
    document.body.dispatchEvent(createThreadEvent);

  };

  const deleteNoteThread = async () => {
    if (thread) {
      await Axios.delete(`/api/note-threads/${thread.id}`);
      let baseRoute = '/notes';
      if (location.pathname.includes('vendors')) {
        baseRoute = `/vendors/${accountId}/notes`;
      }
      if (location.pathname.includes('clients')) {
        baseRoute = `/clients/${accountId}/notes`;
      }

      history.push(baseRoute);

      setDeleteThread(false);
      updateListView();
    }
  };

  const deleteNote = async () => {
    if (noteToDelete) {
      await Axios.delete(`/api/notes/${noteToDelete.id}`);
      const t = thread;
      t.replaceNote(noteToDelete, true);
      setThread(t);
      setNoteToDelete(null);
      updateListView();
    }

  };

  const notes = thread.notes.map(n => (
    <div key={n.id} className="note-item panel-portal">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <FontAwesomeIcon
          onClick={() => setEditingNote(n)}
          style={{ cursor: 'pointer' }}
          className="text-primary"
          icon={faEdit}
        />
        <NoteUserList users={n.users} />
        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          className="text-muted"
          icon={faTimes}
          onClick={() => setNoteToDelete(n)}
        />
      </div>
      <div className="note-header text-muted small">
        {n.contactMethod ?
          <span className="text-right">{n.contactMethod.name} • </span> :
          null}
        <span style={{ marginLeft: 5 }}> {n.occurredAt.format('MMM, D, YYYY')}</span>
        <span style={{ marginLeft: 5 }}>({n.occurredAt.fromNow()})</span>

      </div>

      <div className="note-content">
        <div dangerouslySetInnerHTML={{ __html: n.linkify }}></div>
        {n.attachments.length ? <div style={{ marginTop: 10 }} >
          <FontAwesomeIcon className="text-muted" icon={faPaperclip} /> <span className="small">Attachments</span>
          <div style={{ display: 'flex', flexDirection: 'column' }}>

            {n.attachments.map(a => (<a className="small" target="_blank" href={a.url}>{a.previousFileName}</a>))}
          </div>

        </div> : null}
      </div>

    </div>
  ));
  return (
    <div style={{ flexDirection: 'column', paddingLeft: 5, paddingRight: 5, paddingTop: 5 }} className="flex-container">
      <div style={{ minHeight: 34, marginBottom: 15, display: 'flex', flexDirection: 'row-reverse' }}>
        {!accountId ? <MassUploadButton
          statusUpdateFn={(status) => { setMassUploadStatus(status); }}
          disabled={massUploadStatus.state === 'loading'}
        /> : null}
      </div>

      <MassUploadStatusAlert status={massUploadStatus} />
      <div style={{ marginBottom: 0, zIndex: 2 }} className="panel panel-portal">
          <div className="note-list-item as-header">
            <div className="note-list-icon">
              {thread.threadIcon ? <FontAwesomeIcon className={thread.iconClass} icon={thread.threadIcon} /> : null}

            </div>
            <div className="note-list-content">
            {!accountId ?
              <Link
                className="vendor-header"
                to={`/${thread.account.accountType === 'client' ? 'clients' : 'vendors'}/${thread.account.id}/notes/${thread.id}`}
              >
                <img
                  className="img-responsive"
                  style={{ height: 20, width: 20, marginRight: 10 }}
                  src={thread.account.logo.getSize('th')}
                />
                <strong className="small">
                  {thread.account.accountName}
                </strong>
              </Link> : null}
            <NoteUserList users={thread.users} />

            <div className="note-title-container">
              <h4 onClick={() => setEditThreadModalShown(true)} className="text-primary">
                <strong>{thread.title}</strong>
              </h4>
              <div onClick={() => setEditThreadModalShown(true)} className="text-primary">
                <strong>
                  <FontAwesomeIcon icon={faCog} />
                </strong>
              </div>
            </div>
              <p className="small text-muted">
                Updated on {thread.updatedAt.format('MMM DD, YYYY')} ({thread.updatedAt.fromNow()})
                • Created on {thread.createdAt.format('MMM DD, YYYY')} ({thread.createdAt.fromNow()})
              </p>
            </div>
          </div>
      </div>

      <div className="hide-scrollbar" style={{ height: '100%', overflowY: 'scroll' }}>
        <div className="note-detail-list">
          <div className="create-note-container panel-portal">
            {creatingNote ? null :
              <button className="btn btn-block btn-primary" onClick={() => setCreatingNote(true)}>

               Add Note
              </button>
            }
            {creatingNote ? <div className="new-note-container">
              <NewNoteForm onCancel={() => setCreatingNote(false)} onCreate={createNote} />

            </div> : null}
          </div>

          {notes}

          <button onClick={() => setDeleteThread(true)} className="btn btn-block btn-default">Delete Thread</button>

        </div>

      </div>
      <EditNoteThreadModal
        shown={editThreadModalShown}
        onClose={() => setEditThreadModalShown(false)}
        thread={thread}
        onSubmit={updateThread}
      />
      <EditNoteModal
        shown={editingNote !== null}
        submitting={submittingEdit}
        note={editingNote}
        onClose={() => setEditingNote(null)}
        onSubmit={updateNote}
      />
      <DeleteNoteThreadModal
        shown={deleteThread}
        onClose={() => setDeleteThread(false)}
        onDelete={deleteNoteThread}
      />
      <DeleteNoteModal
        shown={noteToDelete !== null}
        onClose={() => setNoteToDelete(null)}
        onDelete={deleteNote}
      />

    </div>
  );
};

import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { EnforcementDashboardCounts } from './enforcement-dashboard-shared';

interface IProps {
  counts: EnforcementDashboardCounts;
}

export const EnforcementDashboardCountSummary = (props: IProps) => {
  const { counts } = props;

  const toFormat = (value: string | number) => (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator={true}
    />
  );

  return (
    <div className="enforcement-dashboard-detail-panels" >
      <div className="panel panel-portal">
        <div className="panel-body">
          <h6 className="text-primary">Sellers</h6>
          <h3>{toFormat(counts.sellers)}</h3>
        </div>
      </div>
      <div className="panel panel-portal">
        <div className="panel-body">
          <h6 className="text-primary">Listings</h6>
          <h3><Link style={{ color: '#333' }} to={'enforce/content'} >{toFormat(counts.listings)}</Link></h3>
        </div>
      </div>
      <div className="panel panel-portal">
        <div className="panel-body">
          <h6 className="text-primary">Needs review</h6>
          <h3><Link style={{ color: '#333' }} to={'enforce/content?status=1'} >{toFormat(counts.review)}</Link></h3>
        </div>
      </div>
      <div className="panel panel-portal">
        <div className="panel-body">
          <h6 className="text-primary">Pending removal</h6>
          <h3>{toFormat(counts.pending)}</h3>
        </div>
      </div>
      <div className="panel panel-portal">
        <div className="panel-body">
          <h6 className="text-primary">Removed</h6>
          <h3>{toFormat(counts.removed)}</h3>
        </div>
      </div>
    </div>
  );

};

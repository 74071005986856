import * as React from 'react';
import { MenuItem, SplitButton } from 'react-bootstrap';
import { Redirect, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { AccountWebUrlType } from '../../../shared';
import { TrademarkCase } from '../../../shared/TrademarkCase';
import { WebBotSeller } from '../../../shared/WebBotSeller';
import { WebBotUrl } from '../../../shared/WebBotUrl';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { CaseModal, CloseCaseModal } from '../Cases';
import { EnforcementDashboardCounts, EnforcementDashboardPlatformSummary } from './enforcement-dashboard-shared';
import { EnforcementDashboardCountSummary } from './EnforcementDashboardCountSummary';
import { EnforcementDashboardRecentlyRemoved } from './EnforcementDashboardRecentlyRemoved';
import { EnforcementPlatformSellerDetails } from './EnforcementPlatformSellerDetails';

export const VendorEnforcementDashboardPage = () => {
  const match = useRouteMatch();
  const user = React.useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [platforms, setPlatforms] = React.useState<EnforcementDashboardPlatformSummary[]>([]);
  const [enforcementCase, setEnforcementCase] = React.useState<TrademarkCase>();
  const [counts, setCounts] = React.useState<EnforcementDashboardCounts>({
    sellers: 0,
    listings: 0,
    removed: 0,
    pending: 0,
    review: 0,
  });
  const [recentlyRemoved, setRecentlyRemoved] = React.useState<WebBotUrl[]>([]);
  const [updatingCase, setUpdatingCase] = React.useState(false);
  const [caseModal, setCaseModal] = React.useState(false);
  const [closeCaseModal, setCloseCaseModal] = React.useState(false);

  if (user.type !== 'admin') {
    return <Redirect to="/" />;
  }

  React.useEffect(() => {
    getData();
  },              []);

  if (!match) {
    return <Redirect to="/" />;
  }

  const getData = async () => {
    setLoading(true);
    await Promise.all([getUrls(), getSummary()]);
    setLoading(false);
  };

  const getUrls =  async () => {
    const response = await fetch(`/api/enforcement/urls?vendor=${match.params['vendorId']}&status=2&limit=3`);
    const data = await response.json();
    setRecentlyRemoved(data.data.map((r: any) => WebBotUrl.fromApi(r)));
  };

  const getSummary =  async () => {
    const response = await fetch(`/api/enforcement/vendor-dashboard?vendor=${match.params['vendorId']}`);
    const data = await response.json();

    const p = data.data.platforms.map((p: any) => {
      const d = p;
      d.platform = AccountWebUrlType.fromApi(d.platform);
      d.recent = d.recent.map((w: any) => WebBotUrl.fromApi(w));
      d.seller = WebBotSeller.fromApi(d.seller);

      return d;
    });

    if (data.data.case) {
      setEnforcementCase(TrademarkCase.fromApi(data.data.case));
    }

    setPlatforms(p);
    setCounts(data.data.counts);
  };

  const createCase = async () => {
    setUpdatingCase(true);
    const response = await fetch(`/api/enforcement/cases?vendor=${match.params['vendorId']}`, { method: 'POST' });
    const data = await response.json();
    setEnforcementCase(TrademarkCase.fromApi(data.data));
    setUpdatingCase(false);
  };

  const openCase = async () => {
    if (enforcementCase) {
      setUpdatingCase(true);
      const res = await fetch(`/api/trademark-cases/${enforcementCase.id}?action=open`, { method: 'PATCH' });
      const data = await res.json();
      setEnforcementCase(TrademarkCase.fromApi(data.data));
      setUpdatingCase(false);
    }
  };

  if (loading) {
    return (
      <FullContent>
        <LoadingSpinner />
      </FullContent>
    );
  }

  return (
    <FullContent>
      <div>
          {enforcementCase ? <div className={`alert alert-${enforcementCase.isOpen ? 'danger' : 'success'}`}>
          Case {enforcementCase.isOpen ? 'Open' : 'Closed'}
        </div> : null}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
          <div>
            <h3>Enforce</h3>
            <p className="text-muted small">Monitor and manage content found on ecommerce platforms.</p>
          </div>
          <div>
            {enforcementCase ?
              enforcementCase.closedAt ?
                <button onClick={openCase} className="btn btn-default">
                  {updatingCase ? 'Opening..' : 'Open Case'}
                </button> :
                <SplitButton onClick={() => setCaseModal(true)} id="update-case-split" title="Update case">
                 <MenuItem onSelect={() => setCloseCaseModal(true)} eventKey="1">Close Case</MenuItem>
                </SplitButton>
                :
              <button onClick={createCase} className="btn btn-default">
                {updatingCase ? 'Creating..' : 'Create Case'}
              </button>
            }

            <Link style={{ marginLeft: 10 }} to="enforce/manual-content" className="btn btn-primary">Add Content</Link>
          </div>
        </div>
        <EnforcementDashboardCountSummary counts={counts} />

        {platforms.length ? <h5 className="text-muted">Ecommerce Platforms</h5> : null}

        <div className="enforcement-dashboard-platforms">
          {platforms.map(p => (<EnforcementPlatformSellerDetails key={p.seller ? p.seller.id : p.platform.id} platform={p} />))}
        </div>
        <EnforcementDashboardRecentlyRemoved urls={recentlyRemoved} />
        {enforcementCase ? <CaseModal shown={caseModal} onClose={() => setCaseModal(false)} enforcementCase={enforcementCase} onUpdate={setEnforcementCase} /> : null}
        {enforcementCase ? <CloseCaseModal shown={closeCaseModal} onClose={() => setCloseCaseModal(false)} enforcementCase={enforcementCase} onUpdate={setEnforcementCase} /> : null}

      </div>
    </FullContent>
  );
};

import * as moment from 'moment';
import { AccountProfile, AccountProfileAPIResponse, AccountWebUrlType, NoteThread, NoteThreadApiResponse, UserApiResponse } from '.';
import User from './User';

export interface TrademarkCaseApiResponse {
  id: number;
  created_at: string;
  updated_at: string|null;
  closed_at: string|null;
  is_pinned: boolean;
  latest_note?: NoteThreadApiResponse;
  vendor?: AccountProfileAPIResponse;
  platforms?: any[];
  owner?: UserApiResponse;
  created_by?: UserApiResponse;
  property_owners?: AccountProfileAPIResponse[];
  is_auto_closed: boolean;
  can_auto_close: boolean;
  initial_contact: string;
}

export class TrademarkCase {
  id: number;
  createdAt: moment.Moment;
  updatedAt: moment.Moment|null;
  closedAt: moment.Moment|null;
  isPinned: boolean;
  latestNoteThread: NoteThread | null;
  vendor: AccountProfile | null;
  platforms: AccountWebUrlType[] = [];
  owner: User | null;
  createdBy: User | null;
  isAutoClosed: boolean;
  canAutoClose: boolean;
  initialContact: moment.Moment|null;

  propertyOwners: AccountProfile[] = [];

  static fromApi(data: TrademarkCaseApiResponse) {
    const r = new TrademarkCase();
    r.id = data.id;
    r.createdAt = moment(data.created_at);
    r.updatedAt = data.updated_at ? moment(data.updated_at) : null;
    r.closedAt = data.closed_at ? moment(data.closed_at) : null;
    r.isPinned = data.is_pinned;
    r.latestNoteThread = data.latest_note ? NoteThread.fromApi(data.latest_note) : null;
    r.vendor = data.vendor ? AccountProfile.fromApi(data.vendor) : null;
    r.platforms = data.platforms ? data.platforms.map(v => AccountWebUrlType.fromApi(v)) : [];
    r.owner = data.owner ? User.fromApi(data.owner) : null;
    r.createdBy = data.created_by ? User.fromApi(data.created_by) : null;
    r.propertyOwners = data.property_owners ? data.property_owners.map(o => AccountProfile.fromApi(o)) : [];
    r.isAutoClosed = data.is_auto_closed;
    r.canAutoClose = data.can_auto_close;
    r.initialContact = data.initial_contact ? moment(data.initial_contact) : null;

    return r;
  }

  get isOpen() {
    return this.closedAt === null;
  }

}

import Axios from 'axios';
import { render } from 'enzyme';
import * as React from 'react';
import { AsyncTypeahead, MenuItem } from 'react-bootstrap-typeahead';
import { Vendor } from '../../../shared';
import { LoadableStatuses } from '../../../shared/RequestStatuses';

interface IProps {
  selected: Vendor[];
  onChange: (accounts: Vendor[]) => void;
  multiple: boolean;
  licensor?: number;
  isRetailers?: boolean;
}

type Status = LoadableStatuses<Vendor[], string>;

export const VendorTypeahead = (props: IProps) => {
  const [status, setStatus] = React.useState<Status>({ state: 'loading' });

  React.useEffect(() => {
    getAccounts('');
    loadMissing();

  },              []);

  const getAccounts = async (query: string) => {
    setStatus({ state: 'loading' });
    const params = {
      keyword: query,
    };
    let url = '/api/vendors';
    if (props.licensor) {
      url = `/api/licensors/${props.licensor}/licensed-vendors`;
    }
    const v = await Axios.get(url, { params : !props.licensor ? params : undefined });
    const vendors = v.data.data.map((u: any) => Vendor.fromApi(u));
    setStatus({ content: vendors, state: 'success' });

  };

  const loadMissing = async () => {
    const selected = [... props.selected];
    for (let i = 0; i < selected.length; i++) {
      const v = selected[i];
      const a = await Axios.get(`/api/vendors/${v.id}`);
      const fullVendor = Vendor.fromApi(a.data.data);
      selected[i] = fullVendor;
    }
    props.onChange(selected);
  };

  const renderItem = (vendor: Vendor, tokenProps: any, index: number) => {
    return (
      <MenuItem
        key={index}
        position={index}
        option={vendor}
        className="user-typeahead-item"
      >
        <div style={{ alignItems: 'center' }} className="flex-container">
          {vendor.image ? <img style={{ height: 50, width: 50 }} src={vendor.image.getSize('th')} />
          : <div style={{ width: 50, height: 50 }} />}
          <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, marginLeft: 10, alignItems: 'center' }}>
            <div>
              <strong>{vendor.shortName}</strong>
              <br />
              <span>{vendor.city ? `${vendor.city},` : ''} {vendor.state}</span>
            </div>

            <span className={`label label-${vendor.statusColor}`}>{vendor.status.display}</span>

          </div>

        </div>

      </MenuItem>
    );

  };

  const options = status.state === 'success' ? status.content : [];
  const isLoading = status.state === 'loading';
  const placeholder = props.isRetailers ? `Select Retailer${props.multiple ? 's' :''}` : `Select Vendor${props.multiple ? 's' :''}`;

  return (
    <AsyncTypeahead
      id={'vendor-typeahead'}
      placeholder={placeholder}
      options={options}
      selected={props.selected}
      onChange={props.onChange}
      multiple={props.multiple}
      paginate={false}
      isLoading={isLoading}
      maxResults={options.length}
      labelKey={(item: Vendor) => item.shortName}
      onSearch={getAccounts}
      renderMenuItemChildren={renderItem}

    />

  );

};

import {
  findIconDefinition,
  IconDefinition,
  IconLookup,
} from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

/**
 * Takes a four-digit numeric year and returns the "xxxx-yy"
 * fiscal year string used in the Gateway.
 *
 * e.g., input of 2017 returns "2017-18"
 *
 * @param {number} year Year in the "year-(year+1)"
 */
export const formattedFiscalYear = (year: number): string => {

  // If this is a 4-digit year, the end segment should be
  // truncated to two digits.
  const endOffset = (year >= 1000) ? 2 : 0;

  const currentYearStr = year.toString(10);
  const endYearStr = (year + 1).toString(10).substr(endOffset);

  return `${currentYearStr}-${endYearStr}`;
};

export const getYearFromFiscal = (fiscalYear: string) => {
  return fiscalYear.split('-')[0];
};

type SalesDateRange = {
  start: Date;
  end: Date;
};

export const getSalesDateRange = (quarter: number, year: number): SalesDateRange => {
  switch (quarter) {
    case 1:
      return {
        start: new Date(year, 7 - 1, 1),
        end: new Date(year, 9 - 1, 30),
      };
    case 2:
      return {
        start: new Date(year, 10 - 1, 1),
        end: new Date(year, 12 - 1, 31),
      };
    case 3:
      return {
        start: new Date(year + 1, 1 - 1, 1),
        end: new Date(year + 1, 3 - 1, 31),
      };
    case 4:
      return {
        start: new Date(year + 1, 4 - 1, 1),
        end: new Date(year + 1, 6 - 1, 30),
      };
    default:
      throw 'Invalid quarter';
  }
};

export const getSalesText = (quarter: number|null, year: number|null) => {

  if (quarter === null || year === null) {
    return '';
  }

  try {
    const salesDateRange = getSalesDateRange(quarter, year);
    const startOptions = { month: 'short', day: 'numeric' };
    const endOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const startString = salesDateRange.start.toLocaleDateString('en-US', startOptions);
    const endString = salesDateRange.end.toLocaleDateString('en-US', endOptions);
    return `Sales ${startString} - ${endString}`;
  } catch (e) {
    return '';
  }
};

export const getDueDateText = (quarter: number|null, year: number|null) => {

  if (quarter === null || year === null) {
    return '';
  }

  switch (quarter) {
    case 1:
      return `Oct 30, ${String(year)}`;
    case 2:
      return `Jan 30, ${String(year + 1)}`;
    case 3:
      return `Apr 30, ${String(year + 1)}`;
    case 4:
      return `July 30, ${String(year + 1)}`;
    default:
      return '';
  }
};

export const getQuarter = (d?: Date) => {
  let date = new Date();
  if (d) {
    date = d;
  }
  switch (date.getMonth()) {
    case 0:
    case 1:
    case 2:
      return 3;
    case 3:
    case 4:
    case 5:
      return 4;
    case 6:
    case 7:
    case 8:
      return 1;
    case 9:
    case 10:
    case 11:
      return 2;
    default:
      return 0;
  }
};

export const getCurrentFiscalYear = () => {
  let year: number;
  const date = new Date();
  if (date.getMonth() >= 9) {
    year = date.getFullYear();
  } else {
    year = date.getFullYear() - 1;
  }
  return year;
};

interface IFiscalQuarter {
  year: number;
  quarter: 1 | 2 | 3 | 4;
}

export const getCurrentReportQuarter = (manualDate?: Date): IFiscalQuarter => {
  let date = new Date();
  if (manualDate) {
    date = manualDate;
  }
  let year = date.getFullYear() - 1;
  if (date.getMonth() >= 8) {
    year = date.getFullYear();
  }
  switch (date.getMonth()) {
    // 1 Dec - end of Feb
    case 11:
    case 0:
    case 1:
      return { year, quarter: 1 };
    // 1 Mar - end of May
    case 2:
    case 3:
    case 4:
      return { year, quarter: 2 };
    case 5:
    case 6:
    case 7:
      return { year, quarter: 3 };
    case 8:
    case 9:
    case 10:
      return { year, quarter: 4 };
    default:
      throw (`Unexpected month ${date.getMonth()}`);
  }
};

export const getAffinityPageTitle = (title: string) => {
  return `Affinity Gateway${title.length ? ` | ${title}` : ''}`;
};

export const addHttp = (u: string) => {
  let url = u;
  if (!/^(?:f|ht)tps?\:\/\//.test(u)) {
    url = `http://${url}`;
  }
  return url;
};

export const removeHttpWww = (url: string) => {
  // removes http:// https:// www. http://www. https://www.
  const protocolAndWwwRegex = /^(?:f|ht)tps?:\/\/(?:www\.)?|www\./;

  return url.replace(protocolAndWwwRegex, '');
};

export const toUSD = (value: number) => {
  return value.toLocaleString(undefined, { style: 'currency', currency: 'USD' });
};

export const toUSDNoDecimal = (value: number) => {
  return value.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

export const findFAIcon = (icon: any) => {
  const iconLookup: IconLookup = { prefix: 'fas', iconName: icon };
  const iconDef: IconDefinition = findIconDefinition(iconLookup);
  if (iconDef) {
    return iconDef;
  }

  return faQuestionCircle;

};

export const isNumeric = (value: string) => {
  const re = /^\d+(\.\d*)?$/;
  return re.test(value);
};

export const dataURItoBlob = (dataURI: any) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = atob(dataURI.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {
    type: mimeString,
  });
};

export const filenameWithoutExtension = (filename: string, cleanup: boolean): string => {
  const dotIdx = filename.lastIndexOf('.');
  if (dotIdx === -1) {
    return filename;
  }
  let name = filename.substr(0, dotIdx);
  if (cleanup) {
    name = name.replace(/[_,-]/g, ' ');
  }
  return name;
};

export const formatRoyaltyRate = (
  title: string,
  royaltyRate: number,
  minimumRoyaltyPerUnit?: number,
  unitDefinition?: string,
): string => {
  const rateValue = royaltyRate * 100;
  const formattedRate = rateValue % 1 === 0 ? Math.round(rateValue) : rateValue.toFixed(1);

  let result = `${title} (${formattedRate}%`;

  if (minimumRoyaltyPerUnit && minimumRoyaltyPerUnit > 0) {
    result += `, $${minimumRoyaltyPerUnit.toFixed(2)} MRU`;
  }

  result += ')';

  return result;
};

import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import { Filters } from '../../shared';
import User from '../../shared/User';

export class DesignFilters extends Filters {
  queue: number;
  insignia: number;
  notVendorId: number | null;
  reviewers: User[] = [];
  untagged: boolean;
  sentToLicensor: boolean;
  decisionBy: User[] = [];
  userType: string;

  constructor(match: any, location?: string, userType?: string) {
    super();
    this.defaultFilters.page = 96;
    this.perPage = 96;
    if (match.params['licensorId']) {
      this.licensorId = Number(match.params['licensorId']);
    }

    if (match.params['vendorId']) {
      this.vendorId = Number(match.params['vendorId']);
    }
    if (userType) {
      this.userType = userType;
      if (userType === 'client') {
        this.defaultFilters.status = 3;
        this.status = 3;
      } else {
        this.defaultFilters.status = 0;
        this.status = 0;
      }

    } else {
      this.defaultFilters.status = 0;
      this.status = 0;
    }

    if (location) {
      this.setQueryParamsFromLocation(location);
    }
  }

  setQueryParamsFromLocation(location: string) {
    super.setQueryParamsFromLocation(location);
    const queryValues = queryString.parse(location);
    this.queue = queryValues.queue ? Number(queryValues.queue) : this.queue;
    this.insignia = queryValues.insignia ? Number(queryValues.insignia) : this.insignia;
    this.licensorId = queryValues.licensor ? Number(queryValues.licensor) : this.licensorId;
    this.vendorId = queryValues.vendor ? Number(queryValues.vendor) : this.vendorId;
    this.notVendorId = queryValues.not_vendor_id ? Number(queryValues.not_vendor_id) : this.notVendorId;
    this.reviewers = queryValues.reviewer ? [new User({ id: Number(queryValues.reviewer), first_name: 'Loading...', last_name: '' })] : [];
    this.untagged = queryValues.untagged ? Number(queryValues.untagged) ? true : false : false;
    this.sentToLicensor = queryValues.sent_to_licensor ? Number(queryValues.sent_to_licensor) ? true : false : false;
    this.decisionBy = queryValues.decision_by ? [new User({ id: Number(queryValues.decision_by), first_name: 'Loading...', last_name: '' })] : [];
  }

  generateQueryParamString(filters?: any) {
    let queryParams = {
      keyword: this.search.length ? this.search : null,
      page: this.page > 1 ? this.page : null,
      limit: this.perPage !== 96 ? this.perPage : null,
      start_date: this.startDate.length ?  this.startDate : null,
      end_date: this.endDate.length ?  this.endDate : null,
      status: Number(this.status) !== Number(this.defaultFilters.status) ? this.status : null,
      queue: this.queue ? this.queue : null,
      insignia: this.insignia ? this.insignia : null,
      not_vendor_id: this.notVendorId ? this.notVendorId : null,
      market: this.market ? this.market : null,
      reviewer: this.reviewers.length ? this.reviewers.map(r => r.id).join(',') : null,
      untagged: this.untagged ? 1 : null,
      sent_to_licensor: this.sentToLicensor ? 1 : null,
      decision_by: this.decisionBy.length ? this.decisionBy.map(r => r.id).join(',') : null,
      vendor: this.vendorId ? this.vendorId : null,
    };
    if (filters) {
      queryParams = {
        ... queryParams,
        ... filters,
      };
    }
    const cleanParams = omitBy(queryParams, isNil);
    const qs = queryString.stringify(cleanParams);
    return qs;
  }

  generateAPIParams() {
    const params = {
      keyword: this.search.length ? this.search : null,
      per_page: this.perPage,
      page: this.page,
      start_date: this.startDate.length ? this.startDate : null,
      end_date: this.endDate.length ? this.endDate : null,
      phase_group: this.status ? this.status : null,
      client_id: this.licensorId ? this.licensorId : null, // maybe change to licensor_id on endpoint to match others
      vendor_id: this.vendorId ? this.vendorId : null,
      expedited_only: this.queue === 1 ? true : null,
      insignia_id: this.insignia ? this.insignia : null,
      not_vendor_id: this.notVendorId ? this.notVendorId : null,
      reviewer_id: this.reviewers.length ? this.reviewers[0].id : null,
      market: this.market ? this.market : null,
      untagged: this.untagged ?  1 : null,
      sent_to_licensor: this.sentToLicensor ?  1 : null,
      decision_by: this.decisionBy.length ? this.decisionBy[0].id : null,
    };
    const cleanParams = omitBy(params, isNil);
    return cleanParams;
  }

  setFilters(filters: any) {
    super.setFilters(filters);
    const newFilters = {
      queue: this.queue,
      insignia: this.insignia,
      reviewers: this.reviewers,
      untagged: this.untagged,
      sentToLicensor: this.sentToLicensor,
      decisionBy: this.decisionBy,
      ...filters,
    };
    this.queue = newFilters.queue;
    this.insignia = newFilters.insignia;
    this.reviewers = newFilters.reviewers;
    this.untagged  = newFilters.untagged;
    this.sentToLicensor  = newFilters.sentToLicensor;
    this.decisionBy  = newFilters.decisionBy;
  }

  get filters() {
    return {
      search : this.search,
      perPage: this.perPage,
      page: this.page,
      sortBy: this.sortBy,
      status: this.status,
      startDate: this.startDate,
      endDate: this.endDate,
      market: this.market,
      userId: this.userId,
      queue: this.queue,
      insignia: this.insignia,
      reviewers: this.reviewers,
      untagged: this.untagged,
      sentToLicensor: this.sentToLicensor,
      decisionBy: this.decisionBy,
    };
  }

  get isFiltered() {
    return this.generateQueryParamString().length > 0;
  }
}

import Axios from 'axios';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router';
import { AccountProfile } from '../../shared';
import { GatewayModal, ModalType } from '../shared';

export const AccountStatusPanel = () => {
  const params = useParams();
  const [profile, setProfile] = React.useState<AccountProfile | null>(null);
  const [modalShown, setModalShown] = React.useState(false);

  React.useEffect(() => {
    if (params['accountId']) {
      getAccountInfo();
    }

  },              []);

  const getAccountInfo = async () => {
    const a = await Axios.get(`/api/accounts/${params['accountId']}`);
    console.log(a);
    setProfile(AccountProfile.fromApi(a.data.data));
  };

  const updateAccountInfo = async (values: any) => {
    const data = new FormData();
    data.append('status', values.status);
    data.append('flag', values.flag);
    data.append('flagged_at', values.start);
    data.append('flagged_message', values.description);

    await Axios.post(`/vendors/${params['accountId']}/account/edit`, data);
    await getAccountInfo();
    setModalShown(false);

  };

  if (!profile) {
    return null;
  }

  return (
    <div style={{ marginBottom: 30 }} className="panel panel-portal">
      <div className="panel-body">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <div style={{ marginBottom: 0 }} className="account-profile-flags">
              <span
                className={`label label-${profile.statusColor}`}
              >
                {profile.status.display}
              </span>
              {profile.flag && profile.flag.id === 2 ? <span className="label label-danger">30 Day Notice</span> : null}
            </div>
            {profile.flag ? (
              <div style={{ marginTop: 10 }}>
                <div className="text-danger">{profile.flag.message}</div>
                <div>{profile.thirtyDayNotice()}</div>
              </div>

            ) : null}
          </div>
          <button onClick={() => setModalShown(true)} className="btn btn-default">Update</button>
        </div>

      </div>
      <GatewayModal
        shown={modalShown}
        onClose={() => setModalShown(false)}
        type={ModalType.Primary}
        title="Account Flag"
      >
        <Formik
          initialValues={{
            status: profile.status.id,
            flag: profile.flag ? 2 : 0,
            description: profile.flag ? profile.flag.message : '',
            start: profile.flag && profile.flag.at ? profile.flag.at.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          }}
          onSubmit={updateAccountInfo}
        >
          {(formProps: FormikProps<any>) => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label>Account Status</label>
                  <Field name="status" className="form-control" component="select">
                    <option value="1">Potential</option>
                    <option value="2">Current</option>
                    <option value="3">Cancelled</option>
                    <option value="4">Registered</option>
                    <option value="5">Illegal</option>
                    <option value="6">Out of Business</option>
                    <option value="7">Non-Producing</option>
                    <option value="8">One Time</option>
                    <option value="11">Non-Issue</option>
                    <option value="12">Retailer</option>
                  </Field>
                </div>
                <div className="form-group">
                  <label>Account Flag</label>
                  <Field name="flag" className="form-control" component="select">
                    <option value="0">Good Standing</option>
                    <option value="2">30 Day Notice</option>
                  </Field>
                </div>
                <div className="form-group">
                  <label>Flag Start Date</label>
                  <input
                    value={formProps.values.start}
                    name="start"
                    id="start"
                    type="date"
                    className="form-control"
                    onChange={e => formProps.setFieldValue('start', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <Field component="textarea" name="description" className="form-control" />
                </div>

              </Modal.Body>
              <Modal.Footer>
                <button onClick={() => setModalShown(false)} type="button" className="btn btn-default pull-left">
                  Cancel
              </button>
                <button className="btn btn-primary pull-right">Submit</button>
              </Modal.Footer>

            </Form>
          )}

        </Formik>

      </GatewayModal>
    </div>
  );

};

import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { EnforcementDashboardPlatformSummary } from './enforcement-dashboard-shared';

interface IProps {
  platform: EnforcementDashboardPlatformSummary;
}

export const EnforcementPlatformSellerDetails = (props: IProps) => {
  const { platform } = props;

  const toFormat = (value: string | number) => (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator={true}
    />
  );

  return (
    <div className="panel panel-portal enforcement-dashboard-platform">
      <div className="platform-header">
        <Link to={`enforce/content?platform=${platform.platform.id}`} className="logo-plat">
          <div style={{ backgroundColor: platform.platform.color ? platform.platform.color : '#266588' }} className="platform-logo">
            <i className={platform.platform.icon} />
          </div>
          <h4 style={{ color: '#333' }}>{platform.seller ? platform.seller.platformUsername : platform.platform.name}</h4>

        </Link>
      </div>

      {platform.recent.length ? <div className="recent-items">
        {platform.recent.map(r => (
          <div key={r.id} style={{ backgroundImage: `url(${r.correctImage('sm')})` }} className="recent-item-img-container">
          </div>
        ))}
      </div> : <div className="no-recent-products">
          <span className="text-muted"><strong>No Recent Products</strong></span>
        </div>}

      <div className="detail-rows">
        {platform.seller ? null : <div><span>Sellers</span><strong>{toFormat(platform.sellers)}</strong></div>}
        <div><span>Listings</span><strong>{toFormat(platform.listings)}</strong></div>
        <div className="text-primary"><span>Needs review</span><strong><Link to={`enforce/content?platform=${platform.platform.id}&status=1`}>{toFormat(platform.review)}</Link></strong></div>
        <div><span>Pending Removal</span><strong>{toFormat(platform.pending)}</strong></div>
        <div><span>Removed</span><strong>{toFormat(platform.removed)}</strong></div>
      </div>
    </div>

  );

};

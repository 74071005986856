import * as React from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { UserContext } from '../../contexts';
import AngularWrapper from '../AngularWrapper/AngularWrapper';
import { VendorDetailPage } from './VendorDetailPage';

interface RouteParams {
  vendorId: string;
  licensorId?: string;
}

export const VendorRouteHandler: React.FC<any> = (props) => {
  const user = useContext(UserContext);
  const params = useParams<RouteParams>();

  if (user.type === 'admin') {
    return <AngularWrapper {...props} />;
  }

  return <VendorDetailPage {...props} />;
};

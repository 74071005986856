import { Form, Formik } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import { createNoteThread, CreateThreadRequest } from '../../api';
import { UserContext } from '../../contexts';
import User from '../../shared/User';
import { NoteForm, NoteFormValidation, NoteThreadForm, ThreadFormValidation } from './forms';

export const NewNoteThreadPage = () => {
  const params = useParams();
  const history = useHistory();
  const user = React.useContext(UserContext);
  const fakeApi = {
    id: Number(user.id),
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: '',
    image: user.image,
    type: user.type || '',
  };
  const currentUser = User.fromApi(fakeApi);
  const iv = {
    subject: '',
    categories: [],
    code: '',
    pinned: false,
    flagged: false,
    note: '',
    users: [currentUser],
    accountUsers: [],
    contactMethod: null,
    attachments: [],
    noteDate: moment().format('YYYY-MM-DD'),
  };

  const createThread = async (v: any) => {
    const users = v.users.concat(v.accountUsers);
    const data: CreateThreadRequest = {
      accountId: params['accountId'],
      subject: v.subject,
      noteCode: v.code,
      isPinned: v.pinned,
      noteWarn: v.flagged,
      noteBody: v.note,
      noteMethodId: v.contactMethod ? v.contactMethod.id : undefined,
      userIds: users.length ? users.map((u: User) => u.id) : undefined,
      noteAttachments: v.attachments.length ? v.attachments : undefined,
      categoryIds: v.categories.map((c: any) => c.id),
      noteDate: v.noteDate !== moment().format('YYYY-MM-DD') ? v.noteDate : undefined,
    };
    const t = await createNoteThread(data);
    const createThreadEvent = new CustomEvent('note-list-reload', {
      bubbles: true,
      detail: { thread: t.id },
    });
    document.body.dispatchEvent(createThreadEvent);
    history.push(`${t.id}`);
  };

  const validation = Yup.object().concat(NoteFormValidation).concat(ThreadFormValidation);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
      <div style={{ minHeight: 34, marginBottom: 15 }}>

      </div>

      <div style={{ maxHeight: '100%', overflowY: 'scroll', height: '100%' }} className="panel panel-portal hide-scrollbar">
        <div className="panel-body">
          <Formik validationSchema={validation} initialValues={iv} onSubmit={createThread} >
            {formProps => (
              <Form>

                <h4><strong>New Note Thread</strong></h4>
                <br />
                <NoteThreadForm {...formProps} />
                <br />
                <NoteForm {...formProps} />
                <button className="btn btn-primary btn-block">Create Note Thread</button>

              </Form>
            )}
          </Formik>

        </div>
      </div>
    </div>
  );

};

import { faDownload, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { isEmpty, isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { LaravelPaginationDetails, ProductCategory, Vendor } from '../../shared';
import { addHttp, formatRoyaltyRate, removeHttpWww } from '../../utils';
import { FullContent } from '../ContentFrame';
import { FilterBar, LoadingSpinner, PaginationFooter, SortableHeader } from '../shared';
import { ProductCategoryTypeahead } from '../shared';
import { GatewayModal, ModalType } from '../shared';

interface RouteParams {
  licensorId?: string;
}

export const ClientVendorList = (): JSX.Element => {
  const user = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const { licensorId } = useParams<RouteParams>();
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalVendors, setTotalVendors] = useState<number>(0);
  const [graphTotal, setGraphTotal] = useState<number>(0);
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = React.useState<LaravelPaginationDetails>({ total: 0, total_pages: 0, current_page: 1, count: 0, per_page: 24 });

  useEffect(() => {
    getVendorList();
    const qp = queryString.parse(location.search);
    setSearch(qp.search ||  '');
  },        [location.search]);

  const id = licensorId || (user && user.account ? user.account.id : undefined);

  const getVendorList = async () => {
    setLoading(true);

    const filters = queryString.parse(location.search);

    const search = filters.search ? `&search=${encodeURIComponent(filters.search)}` : '';
    const page = filters.page || 1;
    const cityParam = filters.city ? `&city=${encodeURIComponent(filters.city)}` : '';
    const stateParam = filters.state ? `&state=${encodeURIComponent(filters.state)}` : '';

    const categoryParam = filters.category_id
      ? `&category_id=${encodeURIComponent(filters.category_id)}`
      : '';

    const scheduleParam = filters.royalty_schedule_id ? `&royalty_schedule_id=${encodeURIComponent(filters.royalty_schedule_id)}` : '';

    const response = await axios.get(`/api/licensors/${id}/licensed-vendors?page=${page}${search}${cityParam}${stateParam}${categoryParam}${scheduleParam}`);
    setVendors(response.data.data.map((v: any) => Vendor.fromApi(v)));
    setLoading(false);
    setTotalVendors(response.data.meta.pagination.total);
    setGraphTotal(response.data.meta.pagination.total);
    setPagination(response.data.meta.pagination);
      // setTotalPages(response.data.meta.pagination.total_pages);
      // setHasNext(filters.page < response.data.meta.pagination.total_pages);

  };
  const handleVendorClick = (vendorId: number) => {
    let url;
    if (user && (user.type === 'admin')) {
      url = `/clients/${id}/vendors/${vendorId}`;
    } else {
      url = `/vendors/${vendorId}`;
    }

    history.push(url);
  };

  const postSearch = (s : string) => {
    const params = queryString.parse(location.search);
    Object.assign(params, { search: s, page: null });
    const cleaned = omitBy(params, isEmpty);
    const qs = queryString.stringify(cleaned);
    history.push(`${location.pathname}?${qs}`);
  };

  const setPage = (p: number) => {
    const params = queryString.parse(location.search);
    Object.assign(params, { page: p });
    const cleaned = omitBy(params, isNil);
    const qs = queryString.stringify(cleaned);
    history.push(`${location.pathname}?${qs}`);
  };
  const vendorRows = vendors.map((vendor) => {
    return (
      <tr key={vendor.id}>
        <td onClick={() => handleVendorClick(vendor.id)} style={{ cursor: 'pointer' }}>
          <div className="load-account" style={{ zIndex: 2 }} >
            <img className="pull-left hidden-sm hidden-xs" src={vendor.image.getSize('th')} alt={`${vendor.shortName} logo`} />
            <strong>{vendor.shortName}</strong>
          </div>
        </td>
        <td className="text-muted">
          {vendor.city}, {vendor.state}
        </td>
        <td><a target="_blank" href={addHttp(vendor.website)}>{removeHttpWww(vendor.website)}</a></td>
        <td>{vendor.owner ? `${vendor.owner.first_name} ${vendor.owner.last_name}` : ''}</td>
        <td>{vendor.owner ? vendor.owner.email : ''}</td>
        <td>{vendor.owner && vendor.owner.phone ? vendor.owner.phone : vendor.phone}</td>
      </tr>
    );
  });

  let exportLink;
  if (licensorId) {
    exportLink = `/clients/${licensorId}/licenses/csv`;
  } else {
    exportLink = '/licenses/csv';
  }

  const header = (
    <div className="panel-body">
      { totalVendors > 0 ? (
        <h2 style={{ marginBottom: 0 }} className="text-primary text-center">{ graphTotal }</h2>
      ) : null}
      <h3 style={{ margin: 0 }} className="text-center">Licensed Vendors</h3>
      <p className="text-muted text-center">View and export your current licensee list.</p>
    </div>
  );

  if (loading) {
    return (
      <FullContent breadcrumbs={[{ name: 'Vendors' }]}>
        <div className="panel panel-portal">
          {header}
          <div className="panel-body">
            <LoadingSpinner />
          </div>
        </div>
      </FullContent>
    );
  }

  if (!vendors.length && !loading) {
    return (
      <FullContent breadcrumbs={[{ name: 'Vendors' }]}>
        <div className="panel panel-portal">
          {header}
          <div className="panel-body">
            <h4 style={{ paddingBottom: 20 }} className="text-center strong">
              We'll show your licensee list once you sign and activate your first license agreement.
            </h4>
          </div>
        </div>
      </FullContent>
    );
  }

  return (
    <FullContent breadcrumbs={[{ name: 'Vendors' }]}>
      <div>
        <div className="panel panel-portal">
          {header}
          <div className="panel-body">
            <div
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <div style={{ width: '70%', display: 'flex', alignItems: 'center' }}>
                <div style={{ flexGrow: 1 }}>
                  <FilterBar
                    useSearch={true}
                    usePerPage={false}
                    useQuarter={false}
                    updateFilters={(values, submit) => {
                      submit ? postSearch(search) : setSearch(values.search);
                    }}
                    // updateFilters={filter}
                    search={search}
                    // search={filters.search}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  bsStyle="default"
                  bsSize="sm"
                  onClick={() => setShowFiltersModal(true)}
                  style={{ marginRight: '10px' }}
                >
                  <FontAwesomeIcon icon={faFilter} /> Filters
                </Button>

                <a
                  target="_blank"
                  href={(() => {
                    const params = queryString.parse(location.search);
                    Object.assign(params, { export: 'true' });
                    const cleaned = omitBy(params, isEmpty);
                    const qs = queryString.stringify(cleaned);
                    return `${exportLink}?${qs}`;
                  })()}
                  className="btn btn-default btn-sm"
                >
                  <FontAwesomeIcon className="text-primary" icon={faDownload} /> Download Licensee List
                </a>
              </div>
            </div>

          </div>
          <div className="panel-body">
            {loading ? (<LoadingSpinner />) :
              vendors.length ?
                (
                  <div className="table-responsive">
                    <table className="table table-portal">
                      <thead>
                      <tr>
                        <SortableHeader
                          title="Vendor"
                          sortDir="desc"
                          sortKey="vendor"
                          sortSelectFn={() => {}}
                          disableSort={true}
                        />
                        <SortableHeader
                          title="Location"
                          sortDir="desc"
                          sortKey="vendor"
                          sortSelectFn={() => {}}
                          disableSort={true}
                        />
                        <SortableHeader
                          title="Website"
                          sortDir="desc"
                          sortKey="vendor"
                          sortSelectFn={() => {}}
                          disableSort={true}
                        />
                        <SortableHeader
                          title="Contact"
                          sortDir="desc"
                          sortKey="contact"
                          sortSelectFn={() => {}}
                          disableSort={true}
                        />
                        <SortableHeader
                          title="Email"
                          sortDir="desc"
                          sortKey="email"
                          sortSelectFn={() => {}}
                          disableSort={true}
                        />
                        <SortableHeader
                          title="Phone"
                          sortDir="desc"
                          sortKey="phone"
                          sortSelectFn={() => {}}
                          disableSort={true}
                        />
                      </tr>
                      </thead>
                      <tbody>
                      {vendorRows}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>
                    <h4 className="text-center text-muted">No Matching Vendors Found.</h4>
                  </div>
                )
            }
          </div>
        </div>

        <PaginationFooter
          currentPage={pagination.current_page}
          totalResults={totalVendors}
          setPage={setPage}
          hasNext={ pagination.current_page < pagination.total_pages }
          totalPages={pagination.total_pages}
          showFirstLast={true}
        />
      </div>
      <VendorListFiltersModal shown={showFiltersModal} onClose={() => setShowFiltersModal(false)} />
    </FullContent>
  );
};

const VendorListFiltersModal = (props: { shown: boolean, onClose: () => void}) => {
  const [states, setStates] = useState<{ value: string, label: string }[]>([]);
  const [royaltySchedules, setRoyaltySchedules] = useState<any[]>([]);
  const { licensorId } = useParams<RouteParams>();
  const user = useContext(UserContext);
  const id = licensorId || (user && user.account ? user.account.id : undefined);
  const form = useForm<{ city: string, state: string, category: [], royalty_schedule: number; }>({ defaultValues: { category: [] } });
  const { shown, onClose } = props;
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    fetchStates();
    fetchRoyaltySchedules();
  },        []);

  useEffect(() => {
    if (shown) {
      const qp = queryString.parse(location.search);
      form.setValue('state', qp.state || '');
      form.setValue('city', qp.city || '');
      form.setValue('royalty_schedule', qp.royalty_schedule_id || '');
      form.setValue('category', qp.category_id ? [new ProductCategory({ id:Number(qp.category_id), name: 'Loading' })] : []);
    }
  },        [shown]);

  const fetchStates = async () => {
    const response = await axios.get('/api/states');
    const statesData = response.data.map((stateItem: any) => ({
      value: stateItem.abbr,
      label: stateItem.name,

    }));
    setStates(statesData);
    const qp = queryString.parse(location.search);
    form.setValue('state', qp.state || '');
  };

  const fetchRoyaltySchedules = async () => {

    const response = await axios.get(`/api/clients/${id}/royalty-schedules/active`);
    setRoyaltySchedules(response.data);
    const qp = queryString.parse(location.search);
    form.setValue('royalty_schedule', qp.royalty_schedule_id || '');
  };

  const submit = async (values: { city: string, state: string, category: ProductCategory[], royalty_schedule: number; }) => {
    const params = queryString.parse(location.search);
    Object.assign(params, { page: null, city: values.city, state: values.state, category_id: values.category.length > 0 ? values.category[0].id : null, royalty_schedule_id: values.royalty_schedule });
    const cleaned = omitBy(params, isEmpty);
    const qs = queryString.stringify(cleaned);
    history.push(`${location.pathname}?${qs}`);
    onClose();
  };
  return <GatewayModal
    shown={shown}
    onClose={onClose}
    type={ModalType.Primary}
    title="Filter Vendors"
  >
    <form onSubmit={form.handleSubmit(submit)}>
      <Modal.Body>
        <FormGroup>
          <label>Royalty Schedule</label>
          <div className="form-control-container">
            <select
              className="form-control"
              {...form.register('royalty_schedule')}
              >
              <option value="">Select Royalty Schedule</option>
              {royaltySchedules
                .filter(schedule => schedule.royalty_rate > 0)
                .map(schedule => (
                  <option
                    key={schedule.id}
                    value={schedule.id}
                  >
                    {formatRoyaltyRate(
                      schedule.title,
                      schedule.royalty_rate,
                      schedule.minimum_royalty_per_unit,
                      schedule.unit_definition,
                    )}
                  </option>
                ))}
            </select>
          </div>
        </FormGroup>

        <FormGroup>
          <label>Product Category</label>
          <ProductCategoryTypeahead
            selected={form.watch('category')}
            onChange={(selected: ProductCategory[]) => form.setValue('category', selected)}
            multiple={false}
          />
        </FormGroup>

        <FormGroup>
          <label>City</label>
          <input
            className="form-control"
            type="text"
            {...form.register('city')}
            placeholder="City"
          />
        </FormGroup>

        <FormGroup>
          <label>State</label>
          <div className="form-control-container">
            <select
              className="form-control"
              {...form.register('state')}
            >
              <option value="">Select State</option>
              {states.map(stateOption => (
                <option
                  key={stateOption.value}
                  value={stateOption.value}
                >
                  {stateOption.label}
                </option>
              ))}
            </select>
          </div>
        </FormGroup>
        <button className="btn btn-link btn-block" onClick={() => {
          form.reset();
          form.handleSubmit(submit);
        }}>Reset Filters</button>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button bsStyle="primary" type="submit">
            Filter Results
          </Button>
        </div>
      </Modal.Footer>
    </form>

  </GatewayModal>;
};

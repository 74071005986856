import Axios from 'axios';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { EnforcementTemplate } from '../../../shared/EnforcementTemplate';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { DateCell, LoadingSpinner, UrlTypeTooltip } from '../../shared';

export const EnforcementTemplateIndexPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [templates, setTemplates] = React.useState<EnforcementTemplate[]>([]);

  React.useEffect(() => {
    getTemplates();
  },              []);

  const getTemplates = async () => {
    setLoading(true);
    const t = await Axios.get('/api/enforcement-templates');
    setTemplates(t.data.data.map((d: any) => EnforcementTemplate.fromApi(d)));
    setLoading(false);

  };

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Templates')} />

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3><strong>Templates</strong></h3>
        <div>
          <Link className="btn btn-primary" to="templates/new">
            Add Template
          </Link>

        </div>
      </div>

      {loading ? <LoadingSpinner /> : <div style={{ marginTop: 15 }} className="panel panel-portal">
        <div className="table-responsive">
          <table className="table table-portal">
            <thead>
              <tr>
                <th>Name</th>
                <th style={{ width: 100 }}>Platform</th>
                <th style={{ width: 150 }}>Template Type</th>
                <th style={{ width: 90 }}>Updated</th>
              </tr>
            </thead>
            <tbody>
              {templates.map(t => (
                <tr key={t.id}>
                  <td><Link to={`templates/${t.id}`}>{t.name}</Link> </td>
                  <td>{t.platform ? <UrlTypeTooltip type={t.platform} /> : null}</td>
                  <td>
                    {t.dmcaType === 0 && 'General template'}
                    {t.dmcaType === 1 && 'Seller DMCA'}
                    {t.dmcaType === 2 && 'Licensor DMCA'}
                  </td>
                  <td><DateCell  date={t.updatedAt ? t.updatedAt.toDate() : (new Date())} /></td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>}

    </FullContent>
  );
};

import {
  faBadgeDollar,
  faBuilding,
  faChartLine,
  faChartPie,
  faFileChartLine,
  faGlobeAmericas,
  faStoreAlt,
  faTh,
  faTrademark,
} from '@fortawesome/pro-solid-svg-icons';
import * as React from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { Route } from 'react-router-dom';
import { NavItemSection, SubNavigation } from '../Navigation';
import { CategoriesPerformancePage } from './CategoriesPerformancePage';
import { ChannelsPerformancePage } from './ChannelsPerformancePage';
import { FiscalQuarterDetailPage } from './FiscalQuarterDetailPage';
import { FiscalQuartersPage } from './FiscalQuartersPage';
import { FiscalYearsBenchmarkPage } from './FiscalYearsBenchmarkPage';
import { FiscalYearsPage } from './FiscalYearsPage';
import { GeolocationPage } from './GeolocationPage';
import { LicenseesPerformancePage } from './LicenseesPerformancePage';
import { PerformanceDashboard } from './PerformanceDashboard';
import { PerformanceDesignInsigniaPage } from './PerformanceDesignInsigniaPage';
import { PerformanceDesignTopSellingPage } from './PerformanceDesignTopSellingPage';
import { RetailersPerformancePage } from './RetailersPerformancePage';
import { VendorPaymentPerformancePage } from './VendorPaymentPerformancePage';

export const PerformanceContainer = () => {
  const match = useRouteMatch();

  if (!match) {
    return (<Redirect to="/" />);
  }

  const licensor = match.params['licensorId'] ? `/clients/${match.params['licensorId']}` : '';

  const routes = [
    <Route exact={true} path={`${match.path}/payments/fiscal-years`} component={FiscalYearsPage} />,
    <Route exact={true} path={`${match.path}/payments/fiscal-years/benchmark-report`} component={FiscalYearsBenchmarkPage} />,
    <Route exact={true} path={`${match.path}/payments/fiscal-quarters`} component={FiscalQuartersPage} />,
    <Route exact={true} path={`${match.path}/payments/fiscal-quarters/:year/:quarter`} component={FiscalQuarterDetailPage} />,
    <Route exact={true} path={`${match.path}/payments/licensees`} component={VendorPaymentPerformancePage} />,
    // <Route exact={true} path={`${match.path}/explorer`} component={PerformanceExplorerPage} />,
    <Route exact={true} path={`${match.path}/geolocation`} component={GeolocationPage} />,
    <Route exact={true} path={`${match.path}/licensees`} component={LicenseesPerformancePage} />,
    <Route exact={true} path={`${match.path}/retailers`} component={RetailersPerformancePage} />,
    <Route exact={true} path={`${match.path}/categories`} component={CategoriesPerformancePage} />,
    <Route exact={true} path={`${match.path}/channels`} component={ChannelsPerformancePage} />,
    <Route exact={true} path={`${match.path}/designs/insignia`} component={PerformanceDesignInsigniaPage} />,
    <Route exact={true} path={`${match.path}/designs/top-selling`} component={PerformanceDesignTopSellingPage} />,
    <Route exact={true} path={match.path} component={PerformanceDashboard} />,
  ];

  const navLinks: NavItemSection[] = [
    {
      title: '',
      links: [
        {
          isBaseRoute: true,
          name: 'Dashboard',
          path: `${licensor}/performance`,
          icon: faTh,
        },
      ],
    },
    {
      title: 'Payments',
      links: [
        {
          isBaseRoute: false,
          name: 'Fiscal Years',
          path: `${licensor}/performance/payments/fiscal-years`,
          icon: faChartLine,
        },
        {
          isBaseRoute: false,
          name: 'Fiscal Quarters',
          path: `${licensor}/performance/payments/fiscal-quarters`,
          icon: faFileChartLine,
        },
        {
          isBaseRoute: false,
          name: 'Licensees',
          path: `${licensor}/performance/payments/licensees`,
          icon: faBuilding,
        },
      ],
    },
    {
      title: 'Sales Data',
      links: [
        // {
        //   name: 'Explorer',
        //   path: `${licensor}/performance/explorer`,
        //   icon: faSearch,
        // },
        {
          isBaseRoute: false,
          name: 'Categories',
          path: `${licensor}/performance/categories`,
          icon: faChartPie,
        },
        {
          isBaseRoute: false,
          name: 'Channels',
          path: `${licensor}/performance/channels`,
          icon: faFileChartLine,
        },
        {
          isBaseRoute: false,
          name: 'Geolocation',
          path: `${licensor}/performance/geolocation`,
          icon: faGlobeAmericas,
        },
        {
          isBaseRoute: false,
          name: 'Retailers',
          path: `${licensor}/performance/retailers`,
          icon: faStoreAlt,
        },
      ],
    },
    {
      title: 'Designs',
      links: [
        {
          isBaseRoute: false,
          name: 'Insignia',
          path: `${licensor}/performance/designs/insignia`,
          icon: faTrademark,
        },
        {
          isBaseRoute: false,
          name: 'Top Selling',
          path: `${licensor}/performance/designs/top-selling`,
          icon: faBadgeDollar,
        },
      ],
    },

  ];

  return (
    <SubNavigation navTitle="Performance" navLinks={navLinks} routes={routes} />

  );
};

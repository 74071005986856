import Axios, { CancelTokenSource } from 'axios';
import { Formik, FormikProps } from 'formik';
import * as queryString from 'query-string';
import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { getMarketplaceProduct, getMarketplaceSettings, updateMarketplaceProduct } from '../../../api';
import { UserContext } from '../../../contexts';
import { AffinityClient, BrandCategoryType, BrandMarkAsset, MarketplaceProduct, MarketplaceSettings, MarketplaceTag } from '../../../shared';
import { ContentWithSidebar } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { IProductFormValues, ProductForm, ProductFormValidation } from './ProductForm';
import { ProductSidebar } from './ProductSidebar';

interface IState {
  product: MarketplaceProduct;
  nextProductId: number;
  settings: MarketplaceSettings;
  loading: boolean;
  saving: boolean;
  organization: AffinityClient;
  designRules: { approved: string, nonApproved: string};
  brandAssets: SortedBrandAssets[];
  error: string;
}

interface SortedBrandAssets {
  insignia: string;
  assets: BrandMarkAsset[];
}

export class ProductPage extends React.Component <RouteComponentProps<any>, IState> {

  private _getProductSource: CancelTokenSource;
  private _updateProduct: CancelTokenSource;

  constructor(props: any) {
    super(props);
    const product = new MarketplaceProduct();
    product.id = this.props.match.params['id'];
    this.state = {
      product,
      nextProductId: 0,
      settings: new MarketplaceSettings(),
      loading: true,
      saving: false,
      organization: new AffinityClient(),
      designRules: { approved: '', nonApproved: '' },
      brandAssets: [],
      error: '',
    };
    this.getProduct = this.getProduct.bind(this);
    this.setPublished = this.setPublished.bind(this);
    this.setPendingReview = this.setPendingReview.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.review = this.review.bind(this);
    this.archive = this.archive.bind(this);
    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.updateTags = this.updateTags.bind(this);
    this.getDesignRules = this.getDesignRules.bind(this);
    this.getBrandAssets = this.getBrandAssets.bind(this);
    this.orgChange = this.orgChange.bind(this);
    this.toggleStayArchived = this.toggleStayArchived.bind(this);
    this.setInternalRating = this.setInternalRating.bind(this);

  }

  componentDidMount() {
    this.getProduct();

  }

  componentWillReceiveProps(props: any) {
    if (props.match.params['id'] !== this.props.match.params['id']) {
      this.getProduct();
    }
  }
  componentDidUpdate(prevProps: any, prevState: IState) {
    if (prevProps.match.params['id'] !== this.props.match.params['id']) {
      this.getProduct();
    }
  }

  updateProduct(values: IProductFormValues) {
    const product = this.state.product;
    product.setValuesFromForm(values, true);
    this.createUpdateCancelToken();
    this.setState({ saving: true });
    updateMarketplaceProduct(product.id, product.generateFormData(false), this._updateProduct)
      .catch((error) => {
        if (error.response && error.response.status >= 400 && error.response.status < 500) {
          this.setState({ saving: false, error: error.response.data.message });
        } else {
          this.setState({ saving: false, error: 'There was an error. Please try again.' });
        }
        throw error;
      })
      .then(product => this.setState({ product, saving: false }));

  }

  createUpdateCancelToken() {
    if (this._updateProduct) {
      this._updateProduct.cancel('Cancelled updateProduct due to new XHR');
    }
    this._updateProduct = Axios.CancelToken.source();
  }

  getProduct() {
    if (this._getProductSource) {
      this._getProductSource.cancel('Cancelled getProduct() XHR due to new request');
    }
    this._getProductSource = Axios.CancelToken.source();
    this.setState({ loading: true, error: '' });
    const indexParams = queryString.parse(this.props.location.search);
    const params = {
      include_next: true,
      keyword: indexParams.keyword && indexParams.keyword.length ? indexParams.keyword : null,
      page: indexParams.page > 1 ? indexParams.page : 1,
      is_archived: indexParams.is_archived ? indexParams.is_archived : null,
      is_approved: indexParams.is_published ? Number(indexParams.is_published) : null,
      is_pending_review: 1,
      vendor_id: indexParams.vendor_id ? indexParams.vendor_id : null,
      licensor_id: indexParams.licensor_id ? indexParams.licensor_id : null,
      order_by: indexParams.order_by ? indexParams.order_by : null,
      market: indexParams.market ? indexParams.market : null,
    };

    getMarketplaceProduct(this.props.match.params['id'], params, this._getProductSource)
      .then((productResponse) => {
        const product = MarketplaceProduct.fromApi(productResponse.data.data);
        this.setState({ product, nextProductId: productResponse.data.data.next_pending_product });
        getMarketplaceSettings(product.vendor.id, Axios.CancelToken.source())
          .then((settingsResponse) => {
            const settings = MarketplaceSettings.fromApi(settingsResponse.data.data);
            this.setState({ settings, loading: false });
          });
        this.getBrandAssets(product.organization);
        this.getDesignRules(product.organization);
      });
  }

  getBrandAssets(org: AffinityClient) {
    Axios.get(`/api/brand-assets?limit=-1&sort_by=sort_index&licensor_id=${org.id}`)
      .then((response) => {
        const assets: BrandMarkAsset[] = response.data.data.map((brand: any) => {
          return new BrandMarkAsset(brand);
        }).filter((asset: BrandMarkAsset) => asset.category.type === BrandCategoryType.IMAGE);
        const noInsignia: SortedBrandAssets = { insignia: 'Other', assets: [] };
        const tempAssets: SortedBrandAssets[] = [];
        const sortedAssets: SortedBrandAssets[] = assets.reduce(
          (prev, current) => {
            if (current.insignia) {
              const title = current.insignia.title;
              const index = prev.findIndex(asset => asset.insignia === title);
              if (index !== -1) {
                prev[index].assets.push(current);
              } else  {
                prev.push({ insignia: title, assets: [current] });
              }
            } else {
              noInsignia.assets.push(current);
            }

            return prev;
          },
          tempAssets);
        sortedAssets.push(noInsignia);

        this.setState({ brandAssets: sortedAssets });
      });
  }

  getDesignRules(org: AffinityClient) {
    Axios.get(`/api/designRules?client_id=${org.id}`)
      .then((response) => {
        const approved = response.data.data.find((rule: any) => rule.type === 'approved');
        const nonApproved = response.data.data.find((rule: any) => rule.type === 'nonapproved');
        const designRules = { approved: '', nonApproved: '' };

        if (approved) {
          designRules.approved = approved.text;
        }
        if (nonApproved) {
          designRules.nonApproved = nonApproved.text;
        }
        this.setState({ designRules });
      });
  }

  orgChange(org: AffinityClient) {
    this.getDesignRules(org);
    this.getBrandAssets(org);
  }

  review(status: 'approve' | 'reject', message?: string) {
    const isApproved = status === 'approve' ? true : false;
    this.createUpdateCancelToken();
    const formData = new FormData();
    formData.append('is_approved', isApproved ? '1' : '0');
    formData.append('review_message', message ? message : '');
    updateMarketplaceProduct(
      this.state.product.id,
      formData,
      this._updateProduct,
      )
      .catch((error) => {
        this.setState({ error: 'There was an error reviewing the item.' });
        throw error;
      })
      .then((product) => {
        if (this.state.nextProductId !== null) {
          this.props.history.push(`/marketplace/products/${this.state.nextProductId}${this.props.location.search}`);
        } else {
          this.props.history.push('/marketplace/products');
        }
      });

  }

  async archive() {
    this.createUpdateCancelToken();
    const formData = new FormData();
    formData.append('is_archived', !this.state.product.isArchived ? '1' : '0');
    const res = await fetch(`/api/marketplace/products/${this.state.product.id}/toggle-archive`, { method: 'POST', body: formData })
      .catch((error) => {
        this.setState({ error: 'There was an error archiving the item.' });
        throw error;
      });

    if (res) {
      const data = await res.json();
      this.setState({ error: '', product: MarketplaceProduct.fromApi(data.data) });
    }
  }

  setPublished(isPublished: boolean) {
    const updateProduct = this.state.product;
    this.state.product.isApproved = isPublished;
    this.setState({ product: updateProduct });
    const formData = new FormData();
    formData.append('is_approved', isPublished ? '1' : '0');
    this.createUpdateCancelToken();
    updateMarketplaceProduct(this.state.product.id, formData, this._updateProduct)
      .catch((error) => {
        this.setState({ error: 'There was an error updating the item.' });
        throw error;
      })
      .then(product => this.setState({ product, error: '' }));
  }

  setPendingReview(isPendingReview: boolean) {
    const updateProduct = this.state.product;
    this.state.product.isPendingReview = isPendingReview;
    this.setState({ product: updateProduct });
    this.createUpdateCancelToken();
    const formData = new FormData();
    formData.append('is_pending_review', isPendingReview ? '1' : '0');
    updateMarketplaceProduct(this.state.product.id, formData, this._updateProduct)
      .catch((error) => {
        this.setState({ error: 'There was an error updating the item.' });
        throw error;
      })
      .then(product => this.setState({ product, error: '' }));
  }

  addTag(tag: MarketplaceTag) {
    const product = this.state.product;
    product.addTag(tag);
    this.updateTags(product);
  }

  removeTag(tag: MarketplaceTag) {
    const product = this.state.product;
    product.removeTag(tag);
    this.updateTags(product);
  }

  updateTags(product: MarketplaceProduct) {
    this.setState({ product });
    this.createUpdateCancelToken();
    const formData = product.generateFormData(false);
    updateMarketplaceProduct(product.id, formData, this._updateProduct)
      .catch((error) => {
        this.setState({ error: 'There was an error updating tags.' });
        throw error;
      })
      .then(product => this.setState({ product, error: '' }));
  }

  async toggleStayArchived() {
    const r = await Axios.post(`/api/marketplace/products/${this.state.product.id}/toggle-stay-archived`);
    this.setState({ product: MarketplaceProduct.fromApi(r.data.data) });
  }

  async setInternalRating(rating: number) {
    const product = this.state.product;
    product.internalRating = rating;
    this.setState({ product });
    const r = await Axios.post(`/api/marketplace/products/${this.state.product.id}/set-internal-rating`, { rating });
  }

  render() {

    const product = this.state.product;
    let initialValues;
    if (this.state.loading) {
      initialValues = {
        organization: new AffinityClient(),
        category: { id: 0, name: '', type: '' },
        insignia: null,
        name: '',
        description: '',
        vendorProductId: '',
        externalURL: '',
        minimumQuantity: 0,
        defaultMinimumQuantity: false,
        price: '',
        salePrice: '',
        isOnSale: false,
        shippingFirst: '',
        shippingAdditional: '',
        fulfillmentDuration: '',
        fulfillmentTerm: 1,
        defaultShippingPrice: false,
        shippingPolicy: '',
        returnsPolicy: '',
        defaultShippingPolicy: false,
        defaultReturnPolicy: false,
        defaultFulfillment: false,
        options: [],
        primaryImage: null,
        secondaryImages: [],
        customization: false,
      };
    } else {
      initialValues = {
        organization: product.id ? product.organization : new AffinityClient(),
        category: product.id ? product.category : { id: 0, name: '', type: '' },
        insignia: product.insignia ? product.insignia : null,
        name: product.name,
        description: product.description,
        vendorProductId: product.vendorProductId,
        externalURL: product.productWebAddress,
        minimumQuantity: product.minimumQuantity,
        defaultMinimumQuantity: product.defaultMinimumQuantity,
        price: product.regularPrice,
        salePrice: product.salePrice,
        isOnSale: product.isOnSale,
        shippingFirst: product.shippingPriceFirst,
        shippingAdditional: product.shippingPriceAdditional,
        fulfillmentDuration: product.processingDurationQuantity,
        fulfillmentTerm: product.processingDurationTermId,
        defaultShippingPrice: product.defaultShippingPrice,
        shippingPolicy: product.marketplaceDeliveryText,
        returnsPolicy: product.marketplaceReturnsText,
        defaultShippingPolicy: product.defaultShippingPolicy,
        defaultReturnPolicy: product.defaultReturnPolicy,
        defaultFulfillment: product.defaultFulfillment,
        options: product.options,
        primaryImage: null,
        secondaryImages: product.secondaryImages,
        customization: product.customization,
      };
    }
    const sidebar = <ProductSidebar
      addTag={this.addTag}
      removeTag={this.removeTag}
      review={this.review}
      product={product}
      nextProduct={this.state.nextProductId}
      nextProductQueryString={this.props.location.search}
      archive={this.archive}
      toggleStayArchived={this.toggleStayArchived}
      setPublished={this.setPublished}
      setPendingReview={this.setPendingReview}
      isGallery={false}
      error={this.state.error}
      setInternalRating={this.setInternalRating}
    />;
    const sorted = this.state.brandAssets.map((sort, index) => {
      const assets = sort.assets.map((asset, i) => {
        let url;
        if (asset.previewFile) {
          url = asset.previewFile.url;
        } else {
          url = '';
        }
        const primary = asset.isPrimary ? <div className="label label-primary">Primary</div> : null;
        return (
          <div key={i} className="brand-asset">
            <a href={url} target="_blank" className="brand-image-link">
              <img style={{ maxHeight: '100%' }} src={url} alt="" className="img-responsive" />
            </a>
            {primary}

          </div>
        );
      });
      return (
        <div key={index}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 5,
              borderTop: '1px solid #eee',
              borderBottom: '1px solid #eee',
              alignItems: 'center',
            }}
          >
            <span><strong>{sort.insignia}</strong></span>
          </div>
          <div className="brand-image-container">
            {assets}

          </div>
        </div>
      );
    });
    const page =  this.state.loading ? (<LoadingSpinner />) : (

    <div className="content-frame-padding">
      <Formik
        validationSchema={ProductFormValidation}
        initialValues={initialValues}
        onSubmit={this.updateProduct}
      >

        {(formikProps: FormikProps<IProductFormValues>) =>
          (

            <form onSubmit={formikProps.handleSubmit}>
              <ContentWithSidebar
                main={(
                  <div className="panel panel-portal">
                    <div className="panel-body">
                      {this.state.saving ?
                        <LoadingSpinner />
                        : (
                          <UserContext.Consumer>
                            {(user) => {
                              const form = (<ProductForm
                                orgChange={this.orgChange}
                                settings={this.state.settings}
                                formikProps={formikProps}
                                product={product}
                              />);

                              let page;
                              if (user.type === 'admin') {
                                page = (
                                  <Tabs id="product-tabs">
                                    <Tab style={{ paddingTop: 30 }} eventKey={1} title="Product">
                                      {form}
                                    </Tab>
                                    <Tab style={{ paddingTop: 30 }} eventKey={2} title="Licensor Information">
                                      <h2>{formikProps.values.organization.shortName}</h2>
                                      <br />

                                      <div className="row">
                                        <div className="col-md-6">
                                          <h3>Approved</h3>
                                          <div dangerouslySetInnerHTML={{ __html: this.state.designRules.approved }}></div>
                                        </div>
                                        <div className="col-md-6">
                                          <h3>Non-Approved</h3>
                                          <div
                                            dangerouslySetInnerHTML={{ __html: this.state.designRules.nonApproved }}
                                          >
                                          </div>
                                        </div>
                                      </div>

                                    </Tab>
                                    <Tab style={{ paddingTop: 30 }} eventKey={3} title="Licensor Insignia">
                                      <h2>{formikProps.values.organization.shortName}</h2>
                                      <div>
                                        {sorted}
                                      </div>

                                    </Tab>

                                  </Tabs>
                                );

                              } else {
                                page = form;
                              }
                              return page;

                            }}
                          </UserContext.Consumer>

                        )
                      }
                    </div>

                  </div>
                )}
                sidebar={sidebar}
              />

            </form>
          )
        }
      </Formik>
    </div>

    );
    return page;

  }

}

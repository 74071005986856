import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AccountWebUrlType } from '../../../shared';
import { EnforcementTemplateQuill } from './EnforcementTemplateQuill';

export type EnforcmentTemplateFormType = {
  name: string,
  description: string,
  content: string,
  platform: number,
  dmcaType: number,
  subject: string,
};

export const EnforcementTemplateForm = (props: UseFormReturn<EnforcmentTemplateFormType>) => {
  const [types, setTypes] = React.useState<AccountWebUrlType[]>([]);
  const { register, setValue, watch } = props;

  React.useEffect(() => {
    getUrlTypes();

  },              []);

  const getUrlTypes = async () => {
    const res = await fetch('/api/account-web-url-types');
    const data = await res.json();
    setTypes(data.data.map((type: any) => AccountWebUrlType.fromApi(type)));
  };

  return (
    <div>
      <div className="form-group">
        <label>Name</label>
        <input {... register('name')}className="form-control" />
      </div>
      <div className="form-group">
        <label>Subject</label>
        <input {... register('subject')} className="form-control" />
      </div>
      <div className="form-group">
        <label>Description</label>
        <input {...register('description')} className="form-control" />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: 25 }} >
        <div style={{ width: '50%' }} className="form-group">
          <label>Platform</label>
          <select value={watch('platform')} onChange={e => setValue('platform', e.target.value)} className="form-control">
            <option value="" disabled selected>Select Platform</option>
            {types.map(t => <option key={t.id} value={Number(t.id)}>{t.name}</option>)}
          </select>
        </div>
        <div className="form-group">
          <label>Template Type</label>
          <select {...register('dmcaType')} className="form-control">
            <option value="" disabled selected>Select Template Type</option>
            <option value="0">General template</option>
            <option value="1">Seller DMCA</option>
            <option value="2">Licensor DMCA</option>
          </select>
        </div>

      </div>

      <label>Template</label>
      <EnforcementTemplateQuill value={watch('content')} onChange={e => setValue('content', e)} />

    </div>
  );
};
